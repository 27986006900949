import { SVGProps } from 'react';
const SvgCrown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={27}
    height={19}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='m24.213 16.15-.487 2.114a.95.95 0 0 1-.927.736H3.8a.95.95 0 0 1-.926-.736l-.487-2.114h21.826ZM26.576 5.914l-1.924 8.336H1.948L.024 5.914a.951.951 0 0 1 1.398-1.038L7.3 8.236 12.509.425A.953.953 0 0 1 13.265 0a.94.94 0 0 1 .784.367l6.108 7.852 4.965-3.31a.952.952 0 0 1 1.454 1.004Z'
      fill='#fff'
    />
  </svg>
);
export default SvgCrown;
