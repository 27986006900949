import React from 'react';

import { Link } from 'react-router-dom';

import basket from '../assets/images/basket.svg';
import justEat from '../assets/images/JustEat.svg';
import deliveroo from '../assets/images/Deliveroo.svg';
import LeftClose from '../assets/images/icon-navigation-chevron_left_24px.svg';

const FoodOrder = (): React.JSX.Element => {
  return (
    <div className='bg-[#f4f4f4]'>
      <div className='bg-blue p-4 text-white'>
        <div className='relative flex flex-row items-center'>
          <div>
            <Link to='/home' className='absolute top-[50%] translate-y-[-50%]'>
              <img src={LeftClose} alt='close' className='w-10' />
            </Link>
          </div>
          <div className='flex w-full flex-col items-center justify-center'>
            <img src={basket} alt='basket' />
            <div className='text-xl font-bold'>InSession - Meals</div>
          </div>
        </div>
      </div>
      <div className='bg-white p-5'>
        <div className=' flex items-center justify-between'>
          <div className='w-[60%] text-base font-semibold'>
            Restaurants that deliver around your location:
          </div>
          <img src={justEat} alt='jusEat' className='h-[60px]' />
        </div>
        <div className='mt-4 flex flex-col gap-8'>
          <div>
            <div className='mb-3'>
              <img
                className='h-40 w-full rounded-[20px] object-cover'
                src='https://s3-eu-west-2.amazonaws.com/swimbooker-fishery-details/users/background/user__10000433.jpeg'
                alt='hotel'
              />
            </div>
            <div className='flex items-center justify-between'>
              <div>
                <div className='text-base font-semibold'>
                  Burger King (Goudhurst)
                </div>
                <div className='text-sm'>£0 Delivery Fee - 10-20 mins</div>
              </div>
              <div className='rounded-full bg-[#EDEDED] p-2 text-sm'>4.7</div>
            </div>
          </div>
          <div>
            <div className='mb-3'>
              <img
                className='h-40 w-full rounded-[20px] object-cover'
                src='https://s3-eu-west-2.amazonaws.com/swimbooker-fishery-details/users/background/user__10000433.jpeg'
                alt='hotel'
              />
            </div>
            <div className='flex items-center justify-between'>
              <div>
                <div className='text-base font-semibold'>
                  Burger King (Goudhurst)
                </div>
                <div className='text-sm'>£0 Delivery Fee - 10-20 mins</div>
              </div>
              <div className='rounded-full bg-[#EDEDED] p-2 text-sm'>4.7</div>
            </div>
          </div>
        </div>
      </div>
      <div className='mt-2 bg-white p-5'>
        <div className=' flex items-center justify-between'>
          <div className='w-[60%] text-base font-semibold'>
            Restaurants that deliver around your location:
          </div>
          <img src={deliveroo} alt='deliveroo' className='h-[60px]' />
        </div>
        <div className='mt-4 flex flex-col gap-8'>
          <div>
            <div className='mb-3'>
              <img
                className='h-40 w-full rounded-[20px] object-cover'
                src='https://s3-eu-west-2.amazonaws.com/swimbooker-fishery-details/users/background/user__10000433.jpeg'
                alt='hotel'
              />
            </div>
            <div className='flex items-center justify-between'>
              <div>
                <div className='text-base font-semibold'>
                  Burger King (Goudhurst)
                </div>
                <div className='text-sm'>£0 Delivery Fee - 10-20 mins</div>
              </div>
              <div className='rounded-full bg-[#EDEDED] p-2 text-sm'>4.7</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FoodOrder;
