import { Input, Select } from 'antd';
import React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { useGlobalState } from './ReportsGlobalState';

import { StepOneProps } from '../types';

function Step1({
  focus,
  date,
  time,
  setDate,
  setTime,
  fisheryDetails,
}: StepOneProps) {
  const [commonInfo, setCommonInfo] = useGlobalState('commonInfo');

  const [validating] = useGlobalState('validating');

  return (
    <React.Fragment>
      <Input
        placeholder='Fishery'
        value={fisheryDetails?.venue_name}
        disabled
        size='large'
        className='!mt-4  w-full rounded-[5px]'
      />

      <Select
        placeholder='Select Lake'
        status={validating && !commonInfo.lake ? 'error' : ''}
        value={commonInfo.lake}
        autoFocus={focus.lake}
        onChange={(value) => {
          setCommonInfo((prevState) => ({
            ...prevState,
            lake: value,
          }));
        }}
        size='large'
        className='!mt-4  w-full rounded-[5px]'
      >
        {fisheryDetails?.lakes?.map((lake) => (
          <Select.Option value={lake?.name} key={lake?.lake_id}>
            <span className='gRegular'>{lake?.name}</span>
          </Select.Option>
        ))}
      </Select>
      <Input
        placeholder='Enter Swim (optional)'
        value={commonInfo.swim}
        autoFocus={focus.swim}
        onChange={(e) => {
          setCommonInfo((prevState) => ({
            ...prevState,
            swim: e.target.value,
          }));
        }}
        size='large'
        className='!mt-4 w-full rounded-[5px]'
      />

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label='Select Date'
          disableFuture={true}
          autoFocus={focus.date}
          value={date}
          onChange={(value) => {
            if (value) {
              setDate(value);
            }
          }}
          slotProps={{
            textField: { size: 'small', error: validating && !date },
          }}
          className='!mt-4  w-full rounded-[5px] '
          format='dddd D  MMMM YYYY'
        />
      </LocalizationProvider>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker
          label='Select time'
          autoFocus={focus.time}
          slotProps={{ textField: { size: 'small' } }}
          value={time}
          onChange={(value) => {
            if (value) {
              setTime(value);
            }
          }}
          className='!mt-4  w-full rounded-[5px]'
          format='HH:mm'
        />
      </LocalizationProvider>
    </React.Fragment>
  );
}

export default Step1;
