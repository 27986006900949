import { proApiSlice } from './proApiSlice';

export interface ActivityFeedDetails {
  fishery: string;
  fishery_id: string;
  name: string;
  fish_caught_date: string;
  upload_date: string;
  fish_species: string;
  fish_weight_lb: number;
  lake: string;
  image_upload: string;
  public_id: string;
  user_image: string;
}

export const extendedApiSlice = proApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    activityFeed: builder.query<ActivityFeedDetails[], string>({
      query: (fisheryId) => ({
        url: 'user/activity-feed',
        method: 'GET',
        useDefaultErrorHandler: true,
        params: { id: fisheryId },
      }),
      transformResponse: (response: {
        status: string;
        result: ActivityFeedDetails[];
      }) => response?.result ?? response,
    }),
  }),
});

export const { useActivityFeedQuery } = extendedApiSlice;
