import { SVGProps } from 'react';

const ChatSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='28.211'
    height='28.185'
    viewBox='0 0 28.211 28.185'
  >
    <path
      style={{ fill: 'currentColor' }}
      id='so-chat-circle'
      d='M17.1,31.2A14,14,0,0,0,24.625,29L31.206,31.2l-2.194-6.581A14.092,14.092,0,1,0,17.1,31.2ZM10.837,11.611H23.372a1.567,1.567,0,1,1,0,3.134H10.837a1.567,1.567,0,1,1,0-3.134Zm0,7.834H17.1a1.567,1.567,0,1,1,0,3.134H10.837a1.567,1.567,0,1,1,0-3.134Z'
      transform='translate(-2.995 -3.012)'
      fill='#2772af'
    />
  </svg>
);
export default ChatSVG;
