import { Avatar } from 'antd';
import userPlaceholder from '../assets/media/placeholder_user.png';
interface UserData {
  years_angling?: number;
  personal_best?: {
    species: string;
    weight: number;
    unit: string;
  };
}
interface AvatarCardHeaderProps {
  image?: {
    src: string;
    alt: string;
  };
  avatarSize?: number;
  title?: string;
  titleSize?: number;
  postTitle: React.ReactNode;
  postTitleSize?: number;
}
export const getUserSummaryLine = (data: UserData): React.ReactNode => (
  <span>
    {data?.years_angling && (
      <>
        <span className='font-semibold'>Years Angling:</span>{' '}
        <span>{data.years_angling} </span>
      </>
    )}
    {data?.personal_best && (
      <>
        <span className='font-semibold'>PB:</span>{' '}
        <span>
          {data.personal_best.species} {data.personal_best.weight}&nbsp;
          {data.personal_best.unit}
        </span>
      </>
    )}
  </span>
);

/**
 * A header with an avatar (profile image), title and subtitle
 * See AvatarSliderCard for prop definitions
 */
export function AvatarCardHeader({
  image,
  avatarSize = 50,
  title,
  titleSize = 18,
  postTitle,
  postTitleSize = 10,
}: AvatarCardHeaderProps): React.JSX.Element {
  return (
    <div className=' mb-5 flex gap-x-5 gap-y-0.5'>
      <Avatar
        size={avatarSize}
        src={
          <img
            src={image?.src || userPlaceholder}
            alt={image?.alt}
            loading='lazy'
          />
        }
        className=' aspect-square shrink-0 '
      />
      <div className=' flex flex-col gap-[inherit]'>
        <p className={`text-[${titleSize}px] mb-0.5 font-semibold`}>
          {title
            ? title.length > 20
              ? title.slice(0, 20) + '...'
              : title
            : ''}
        </p>
        {postTitle && (
          <p className={`text-[${postTitleSize}px]  mb-[5px] text-[#000000d9]`}>
            {postTitle}
          </p>
        )}
      </div>
    </div>
  );
}

/**
 * A card to be used in BaseSlider with an avatar-style (round) image (not full-width image)
 * @param href {History.LocationDescriptor<H.LocationState> | ((location: History.Location<History.LocationState>) => History.LocationDescriptor<History.LocationState>)} the url to link to
 * @param image {{src: string|Image, alt: string}}
 * @param avatarSize {number} (option) size of the avatar image in px
 * @param title {ReactNode} The main title
 * @param titleSize font size in pixels for the title (corresponds to class names in library.css)
 * @param postTitle {ReactNode | null} (optional) A subtitle to be displayed below the main title
 * @param postTitleSize font size in pixels for the postTitle (corresponds to class names in library.css)
 * @param children {ReactNode} The main content of the card
 * @param extra {ReactNode | null} (optional) An extra string to be displayed at the bottom of the card
 * @returns {JSX.Element}
 */
interface AvatarSliderCardProps {
  href?: string;
  image?: {
    src: string;
    alt: string;
  };
  avatarSize?: number;
  title?: string;
  titleSize?: number;
  postTitle: React.ReactNode;
  postTitleSize?: number;
  extra?: JSX.Element | undefined;
  children?: React.ReactNode;
}
export default function AvatarSliderCard({
  href,
  image,
  avatarSize = 50,
  title,
  titleSize = 18,
  postTitle = undefined,
  postTitleSize = 10,
  extra = undefined,
  children,
}: AvatarSliderCardProps): JSX.Element {
  return (
    <div className='slider-card avatar m-0 h-full overflow-hidden rounded-[inherit] bg-[#fafafa] p-5'>
      <a href={href}>
        <div>
          <AvatarCardHeader
            {...{
              image,
              avatarSize,
              title,
              titleSize,
              postTitle,
              postTitleSize,
            }}
          />
          {children}
        </div>
        {extra && (
          <p className=' mt-[15px] w-full text-left font-semibold text-[#000000d9]'>
            {extra}
          </p>
        )}
      </a>
    </div>
  );
}
