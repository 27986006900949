import { useRef } from 'react';
import placeholder from './assets/media/Image_Coming_Soon_Placeholder.jpg';

import './assets/css/placeholder-image.css';

interface PlaceholderImageProps {
  src?: string;
  alt: string;
  className?: string;
}
export function PlaceholderImage({
  src,
  alt,
  className = '',

  ...rest
}: PlaceholderImageProps): JSX.Element {
  const ref = useRef<HTMLImageElement | null>(null);

  return (
    <img
      ref={ref}
      className={className}
      src={src || placeholder}
      alt={alt}
      loading='lazy'
      onError={() => {
        if (!ref?.current) {
          return;
        }
        ref.current.src = placeholder;
      }}
      {...rest}
    />
  );
}

/**
 * Mostly used for aspect ratio within slider cards
 * */
export function SliderCardImage({
  src,
  alt,
  ...rest
}: PlaceholderImageProps): JSX.Element {
  return (
    <PlaceholderImage
      src={src}
      alt={alt}
      className='slider-image relative mx-auto my-0 aspect-[7/5] min-h-[150px] w-full bg-[#464646] object-cover'
      {...rest}
    />
  );
}
