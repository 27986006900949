import { SVGProps } from 'react';
const SvgHandshake = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={32}
    height={32}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M11.182 24.423c-.015.152-.002.31.053.468.136.38.454.69.83.806.35.11.698.037.804-.078l.73-.783c.21-.226.264-.587.14-.937-.305-.585-.715-.796-1.239-.89l-1.318 1.414ZM10.206 20.506 8.93 21.875c-.26.282-.334.715-.189 1.13a1.5 1.5 0 0 0 .959.93c.415.124.832.027 1.093-.25l1.181-1.267c.261-.282.333-.713.189-1.128a1.499 1.499 0 0 0-.96-.93c-.348-.11-.756-.115-.997.146ZM7.676 18.255 6.5 19.52c-.26.28-.333.714-.19 1.131.154.437.52.794.96.928.414.126.835.027.966-.112l1.308-1.403c.261-.282.334-.715.189-1.13a1.495 1.495 0 0 0-.96-.928c-.403-.131-.85-.014-1.096.249ZM5.372 15.762l-1.305 1.404c-.261.281-.334.712-.19 1.125.153.443.522.798.96.932.415.123.835.027.965-.113l1.31-1.404v-.001c.26-.278.333-.71.188-1.124a1.486 1.486 0 0 0-.959-.93c-.335-.116-.741-.136-.969.11ZM22.322 7.388c-.844.472-2.018.557-3.161.64-.865.058-1.757.12-2.398.358L13.198 9.7a.317.317 0 0 1-.074.019c-.172.027-.331.05-.474.07-.868.122-.968.16-1.068.416-.091.238.009.58.255.871.202.239.99.985 2.61.601l2.03-.268c1.136-.146 2.047.595 2.775 1.193l5.459 4.367.004-.002c1.08-.452 2.204-1.04 3.16-2.19l-5.553-7.39Z'
      fill='#fff'
    />
    <path
      d='M14.811 7.123c-.323.03-.694.07-1.083.111-2.152.23-3.345.32-3.952.208l-5.585 7.27c.205.194.376.396.513.615.467-.478 1.172-.649 1.86-.44.673.202 1.236.749 1.472 1.427.1.287.13.581.105.863.276-.038.567-.022.853.065.673.202 1.238.75 1.471 1.428.102.285.134.58.11.861a1.95 1.95 0 0 1 .853.067c.67.203 1.233.75 1.469 1.424.148.428.153.868.033 1.263.069.013.14.03.21.052a2.123 2.123 0 0 1 1.332 1.29c.21.591.12 1.211-.225 1.656l.99.793c.243.192.597.213.926.053.358-.173.628-.526.708-.92a1.052 1.052 0 0 0-.015-.472l-1.257-1.006a.405.405 0 0 1-.067-.559.386.386 0 0 1 .548-.068l1.511 1.21c.15.12.58.153.974-.033a1.51 1.51 0 0 0 .815-1.061c.083-.43-.05-.848-.347-1.087l-1.367-1.094a.401.401 0 0 1-.066-.558.381.381 0 0 1 .546-.068l1.987 1.589c.299.237.728.27 1.124.085.411-.196.724-.604.813-1.064.084-.43-.05-.847-.35-1.088l-1.987-1.587a.406.406 0 0 1-.067-.562.387.387 0 0 1 .549-.066s3.018 2.413 3.02 2.413c.15.119.578.152.97-.036.415-.194.727-.601.817-1.06.082-.431-.052-.85-.35-1.087l-5.907-4.727c-.678-.556-1.378-1.132-2.19-1.022l-1.99.26c-1.34.321-2.605.003-3.337-.862-.44-.52-.59-1.165-.393-1.683.278-.719.81-.793 1.688-.917.131-.017.272-.037.428-.062l3.527-1.301c.23-.085.484-.144.746-.197-.907-.35-1.564-.372-2.433-.316ZM7.145 5.745c-.643-.049-1.23.436-2.074 1.535l-2.16 2.814c-.78 1.014-1.073 1.645-1.009 2.18.052.44.358.87.661 1.114l1.025.818 5.562-7.24-.72-.576c-.495-.395-.9-.615-1.285-.645ZM24.982 5.737c-.385.025-.794.24-1.293.63l-.728.564 5.486 7.299.709-.552c.613-.475.926-.9.983-1.34.07-.533-.212-1.17-.981-2.197L27.033 7.3c-.827-1.112-1.407-1.605-2.05-1.564Z'
      fill='#fff'
    />
  </svg>
);
export default SvgHandshake;
