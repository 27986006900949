import { SVGProps } from 'react';
const SvgMoneyTransfer = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 4 64 64'
    width={28}
    height={28}
    {...props}
  >
    <g fill='#fff'>
      <path d='M32 39c-6.609.206-6.607 9.795 0 10 6.609-.206 6.607-9.795 0-10Z' />
      <path d='M51.13 34H12.87A4.01 4.01 0 0 1 10 36.87v14.26A4.01 4.01 0 0 1 12.87 54h38.26A4.01 4.01 0 0 1 54 51.13V36.87A4.01 4.01 0 0 1 51.13 34ZM22.54 48H14a1 1 0 0 1 0-2h8.54a1 1 0 0 1 0 2Zm0-6H14a1 1 0 0 1 0-2h8.54a1 1 0 0 1 0 2ZM32 51a7.008 7.008 0 0 1-7-7c.328-9.265 13.673-9.262 14 0a7.008 7.008 0 0 1-7 7Zm18-3h-8.54a1 1 0 0 1 0-2H50a1 1 0 0 1 0 2Zm0-6h-8.54a1 1 0 0 1 0-2H50a1 1 0 0 1 0 2Z' />
      <path d='M57 28H7a3.009 3.009 0 0 0-3 3v26a3.009 3.009 0 0 0 3 3h50a3.009 3.009 0 0 0 3-3V31a3.009 3.009 0 0 0-3-3Zm-1 24a1.003 1.003 0 0 1-1 1 2.006 2.006 0 0 0-2 2 1.003 1.003 0 0 1-1 1H12a1.003 1.003 0 0 1-1-1 2.006 2.006 0 0 0-2-2 1.003 1.003 0 0 1-1-1V36a1.003 1.003 0 0 1 1-1 2.006 2.006 0 0 0 2-2 1.003 1.003 0 0 1 1-1h40a1.003 1.003 0 0 1 1 1 2.006 2.006 0 0 0 2 2 1.003 1.003 0 0 1 1 1ZM13.75 16H16v8a1.003 1.003 0 0 0 1 1h6a1.003 1.003 0 0 0 1-1v-8h2.25a1.008 1.008 0 0 0 .85-1.53l-6.25-10a1.048 1.048 0 0 0-1.7 0l-6.25 10a1.008 1.008 0 0 0 .85 1.53ZM43.15 24.53a1.004 1.004 0 0 0 1.7 0l6.25-10a1.008 1.008 0 0 0-.85-1.53H48V5a1.003 1.003 0 0 0-1-1h-6a1.003 1.003 0 0 0-1 1v8h-2.25a1.008 1.008 0 0 0-.85 1.53Z' />
    </g>
  </svg>
);
export default SvgMoneyTransfer;
