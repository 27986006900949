import React from 'react';
import './assets/css/App.css';
import Home from '../pages/Home';
import SignInPage from '../pages/UserAuth/SignIn';
import WaitRoomPage from '../pages/WaitRoom';
import FoodOrder from '../pages/FoodOrder';
import SessionNotesResults from '../pages/SessionNotes';
import MockAuthPage from '../pages/UserAuth/AuthMock';
import { App as AntApp, ConfigProvider } from 'antd';
import { ThemeConfig } from 'antd/es/config-provider/context';
import { HelmetProvider } from 'react-helmet-async';
import { Navigate, RouterProvider } from 'react-router-dom';
import { createAppRouter, SecurityLevel } from './utils/secureRoutes';
import { useUserIdentification } from '../utils/hooks';
import {
  MockAuthFlagWrapper,
  SBPlusFlagWrapper,
} from '../common/components/FeatureFlagProtection';
import {
  ACCOUNT_CREATION_ROUTE,
  LOGIN_ROUTE,
  SUBSCRIPTION_SIGN_UP_ROUTE,
  WAITING_ROOM_ROUTE,
} from './appConstants';
import CreateAccountPage from '../pages/UserAuth/CreateAccount';
import { routes as subscriptionSignUpRoutes } from '../pages/SubscriptionSignUp';

function App(): React.JSX.Element {
  useUserIdentification();

  const pageRouter = createAppRouter([
    {
      path: '/join-waitlist',
      element: <Navigate to={SUBSCRIPTION_SIGN_UP_ROUTE} />,
      security: {
        level: SecurityLevel.Public,
      },
    },
    {
      path: ACCOUNT_CREATION_ROUTE,
      element: <CreateAccountPage />,
      security: {
        level: SecurityLevel.NotLoggedIn,
        redirect: SUBSCRIPTION_SIGN_UP_ROUTE,
      },
    },
    {
      path: LOGIN_ROUTE,
      element: <SignInPage />,
      security: {
        level: SecurityLevel.NotLoggedIn,
        redirect: '/',
      },
    },
    ...subscriptionSignUpRoutes,
    {
      path: '/mock-auth',
      element: (
        <MockAuthFlagWrapper>
          <MockAuthPage />
        </MockAuthFlagWrapper>
      ),
      security: {
        level: SecurityLevel.LoggedIn,
      },
    },
    {
      path: WAITING_ROOM_ROUTE,
      element: (
        <SBPlusFlagWrapper>
          <WaitRoomPage />
        </SBPlusFlagWrapper>
      ),
      security: {
        level: SecurityLevel.NoSession,
        redirect: '/',
      },
    },
    {
      path: '/order-food',
      element: (
        <SBPlusFlagWrapper>
          <FoodOrder />
        </SBPlusFlagWrapper>
      ),
      security: {
        level: SecurityLevel.InSession,
        redirect: WAITING_ROOM_ROUTE,
      },
    },
    {
      path: '/session-notes',
      element: (
        <SBPlusFlagWrapper>
          <SessionNotesResults />
        </SBPlusFlagWrapper>
      ),
      security: {
        level: SecurityLevel.InSession,
        redirect: WAITING_ROOM_ROUTE,
      },
    },
    {
      path: '/',
      element: (
        <SBPlusFlagWrapper>
          <Home />
        </SBPlusFlagWrapper>
      ),
      security: {
        level: SecurityLevel.InSession,
        redirect: WAITING_ROOM_ROUTE,
      },
    },
  ]);

  const antdConfig: ThemeConfig = {
    token: {
      colorText: '#000000',
      colorPrimary: '#2772af',
      fontFamily: 'gilroy',
    },
    components: {
      Button: {
        borderRadius: 10000 /* Large number to create fully-rounded edges*/,
      },
    },
  };

  return (
    <HelmetProvider>
      <ConfigProvider theme={antdConfig}>
        <AntApp>
          <RouterProvider router={pageRouter} />
        </AntApp>
      </ConfigProvider>
    </HelmetProvider>
  );
}

export default App;
