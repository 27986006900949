import { SVGProps } from 'react';
const SvgCashback = (props: SVGProps<SVGSVGElement>) => (
  <svg
    height={28}
    width={28}
    viewBox='0 0 512 595'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g fill='#fff'>
      <path d='M87 318.196v61.608c22.712 5.568 40.628 23.484 46.195 46.196h245.609c5.567-22.712 23.483-40.628 46.195-46.196v-61.608c-22.712-5.568-40.628-23.484-46.195-46.196H133.195c-5.567 22.712-23.483 40.628-46.195 46.196zM209 345c0-25.916 21.084-47 47-47s47 21.084 47 47v8c0 25.916-21.084 47-47 47s-47-21.084-47-47z' />
      <path d='M256 370c9.374 0 17-7.626 17-17v-8c0-9.374-7.626-17-17-17s-17 7.626-17 17v8c0 9.374 7.626 17 17 17z' />
      <path d='M497 186H392.277c-10.627 22.19-26.514 41.4-46.041 56H392c8.284 0 15 6.716 15 15 0 18.196 14.804 33 33 33 8.284 0 15 6.716 15 15v88c0 8.284-6.716 15-15 15-18.196 0-33 14.804-33 33 0 8.284-6.716 15-15 15H120c-8.284 0-15-6.716-15-15 0-18.196-14.804-33-33-33-8.284 0-15-6.716-15-15v-88c0-8.284 6.716-15 15-15 18.196 0 33-14.804 33-33 0-8.284 6.716-15 15-15h45.763c-19.527-14.6-35.414-33.81-46.041-56H15c-8.284 0-15 6.716-15 15v296c0 8.284 6.716 15 15 15h482c8.284 0 15-6.716 15-15V201c0-8.284-6.716-15-15-15z' />
      <path d='M256 242c66.719 0 121-54.28 121-121S322.719 0 256 0 135 54.28 135 121s54.281 121 121 121zM203.394 73.394l24-24c5.857-5.858 15.355-5.858 21.213 0 5.352 5.351 5.798 13.73 1.373 19.606H274c30.327 0 55 24.673 55 55s-24.673 55-55 55h-60c-8.284 0-15-6.716-15-15s6.716-15 15-15h60c13.785 0 25-11.215 25-25s-11.215-25-25-25h-24.021c4.425 5.876 3.979 14.256-1.373 19.606-5.857 5.858-15.355 5.859-21.213 0l-24-24c-5.858-5.857-5.858-15.355.001-21.212z' />
    </g>
  </svg>
);
export default SvgCashback;
