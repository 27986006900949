import { Row, Col, Button, Input } from 'antd';
import React from 'react';
import LeftChevron from '../assets/LeftChevron';
import EditBookingIcon from '../assets/images/EditBooking.png';
import Checked from '../assets/images/checked.svg';

import {
  SessionNotes,
  useUpdateSessionNoteMutation,
} from '../../../store/apiSlices/SessionNotesSlice';
import dayjs from 'dayjs';

export default function SessionNoteDetails({
  setIsEditingNotes,
  noteDetails,
}: {
  setIsEditingNotes: React.Dispatch<React.SetStateAction<boolean>>;
  noteDetails: SessionNotes;
}) {
  const [isEditingTitle, setIsEditingTitle] = React.useState(false);
  const [isEditingDescription, setIsEditingDescription] = React.useState(false);

  const [title, setTitle] = React.useState(noteDetails?.title);
  const [description, setDescription] = React.useState(noteDetails?.detail);

  const [updateNotes] = useUpdateSessionNoteMutation();

  const handleUpdateNotes = () => {
    updateNotes({
      ...noteDetails,
      note_id: noteDetails.id,
      title,
      detail: description,
    })
      .unwrap()
      .then(() => {
        setIsEditingTitle(false);
        setIsEditingDescription(false);
      });
  };

  return (
    <div className='p-4'>
      <div>
        <Button
          className='profile_layout mb-[15px] flex flex-col items-center justify-center p-3'
          shape='circle'
          icon={<LeftChevron />}
          size='large'
          onClick={() => {
            setIsEditingNotes(false);
          }}
        />
        <div className='session_detail_box_shadow mb-4 rounded-[10px] bg-white p-4'>
          <Row className='flex items-center justify-between'>
            <Col>
              <Row className='ml-1.5 flex w-full items-center justify-between'>
                <Col className=' mg-right-5 flex flex-col items-center justify-center rounded-[5px]   bg-[#e0e0e0]  px-[10px] py-[5px] '>
                  <div className='text-[9px] font-medium text-[#292828]'>
                    {dayjs(noteDetails?.note_date).format('ddd').toUpperCase()}{' '}
                  </div>
                  <div className='font-bold'>
                    {dayjs(noteDetails?.note_date).format('D')}
                  </div>
                </Col>
                <Col className='font-bold  '>
                  {isEditingTitle ? (
                    <Input.TextArea
                      value={title}
                      onChange={({ target: { value } }) => setTitle(value)}
                    />
                  ) : (
                    <div className='gMedium'>{title}</div>
                  )}
                  <div className='text-[12px] font-medium text-[#8B8B8B]'>
                    {dayjs(noteDetails?.note_date).format('HH:mm')}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col>
              {isEditingTitle ? (
                <img
                  alt='checked-icon-for-title'
                  className='manage-bookings-icon cursor-pointer'
                  src={Checked}
                  width={22}
                  onClick={handleUpdateNotes}
                />
              ) : (
                <img
                  alt='editbookingicon'
                  className='manage-bookings-icon cursor-pointer'
                  src={EditBookingIcon}
                  width={22}
                  onClick={() => setIsEditingTitle(true)}
                />
              )}
            </Col>
          </Row>
        </div>
        <div className='session_detail_box_shadow mb-4 rounded-[10px] bg-white p-4'>
          <Row className='mb-2.5 flex items-center justify-between'>
            <Col className='font-bold'>Note Details:</Col>
            <Col>
              {isEditingDescription ? (
                <img
                  alt='checked-icon'
                  className='manage-bookings-icon cursor-pointer'
                  src={Checked}
                  width={22}
                  onClick={handleUpdateNotes}
                />
              ) : (
                <img
                  alt='editbookingicon'
                  className='manage-bookings-icon cursor-pointer'
                  src={EditBookingIcon}
                  width={22}
                  onClick={() => setIsEditingDescription(true)}
                />
              )}
            </Col>
          </Row>
          {isEditingDescription ? (
            <Input.TextArea
              value={description}
              onChange={({ target: { value } }) => setDescription(value)}
            />
          ) : (
            <div className='font-medium text-[535353]'>{description}</div>
          )}
        </div>
        {noteDetails?.image_upload > '' ? (
          <div className='session_detail_box_shadow mb-[10px] overflow-hidden rounded-[10px]'>
            <img
              className='h-[13rem] w-full object-cover'
              src={noteDetails.image_upload}
              alt='note'
            />
          </div>
        ) : null}
        <Row gutter={[10, 10]}>
          <Col span={12}>
            <div className='txt-center session_detail_box_shadow h-full  rounded-[10px]  bg-white p-3 font-medium'>
              {' '}
              <span className='mr-1.5 font-bold'>Lake: </span>{' '}
              {noteDetails?.lake}
            </div>
          </Col>
          <Col span={12}>
            <div className='txt-center session_detail_box_shadow h-full rounded-[10px]  bg-white p-3 font-medium'>
              {' '}
              <span className='mr-1.5 font-bold'>Swim: </span>{' '}
              {noteDetails.swim}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
