import { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios from 'axios';
import { defaultErrorNotification } from '../../utils/messaging';

export const axiosBaseQuery =
  ({ baseURL = '' }: { baseURL?: string }): BaseQueryFn =>
  async (
    { url, method, data, params, headers = {}, useDefaultErrorHandler = true },
    { dispatch }
  ) => {
    const {
      Accept = 'application/json',
      'Content-Type': ContentType = 'application/json',
      ...otherHeaders
    } = headers;
    try {
      const result = await axios({
        url: baseURL + url,
        method,
        data,
        params,
        headers: {
          Accept: Accept,
          'Content-Type': ContentType,
          ...otherHeaders,
        },
        withCredentials: true,
      });
      if (result.status >= 200 && result.status <= 299) {
        return { data: result.data?.result ?? result.data };
      } else {
        return {
          error: {
            status: data.status,
            data: data,
          },
        };
      }
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (useDefaultErrorHandler) {
          defaultErrorNotification(err);
        }
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
      if (useDefaultErrorHandler) {
        defaultErrorNotification(err);
      }
      return {
        error: {
          status: 500,
          data: JSON.stringify(err),
        },
      };
    }
  };
