import { SVGProps } from 'react';
const SvgGiftCard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    clipRule='evenodd'
    fillRule='evenodd'
    width={28}
    height={28}
    strokeLinejoin='round'
    strokeMiterlimit={2}
    viewBox='-2 0 26 24'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fill='#fff'
      d='M22.75 11.25h-8.12a2.7 2.7 0 0 1-1.085 1.4c-.501.336-1.279.512-2.045.571l1.498 1.499a.749.749 0 1 1-1.061 1.06l-1.655-1.655v5.625H20c.729 0 1.429-.29 1.945-.805A2.755 2.755 0 0 0 22.75 17zm-18.38 0H1.25V17c0 .729.29 1.429.805 1.945A2.755 2.755 0 0 0 4 19.75h4.782v-5.689L7.063 15.78a.75.75 0 0 1-1.061-1.06L7.5 13.221c-.766-.059-1.544-.235-2.045-.571a2.7 2.7 0 0 1-1.085-1.4zm1.378-.798a.705.705 0 0 1 .136-.362c.321-.439 1.008-.449 1.524-.103.322.215.565.655.768 1.084.11.232.205.465.283.678a9.645 9.645 0 0 1-.817-.021c-.501-.037-1.025-.105-1.354-.325-.317-.212-.521-.532-.54-.843l.002-.06-.002-.048zm7.504.108c-.019.311-.223.631-.54.843-.329.22-.853.288-1.354.325-.286.02-.567.025-.817.021.078-.213.173-.446.283-.678.203-.429.446-.869.768-1.084.516-.346 1.203-.336 1.524.103a.705.705 0 0 1 .136.362.77.77 0 0 0 0 .108zm-2.97-6.31v4.911c.151-.166.31-.31.476-.421 1.216-.813 2.811-.57 3.568.462.125.172.222.356.292.548h8.132V7c0-.729-.29-1.429-.805-1.945A2.755 2.755 0 0 0 20 4.25zm-1.5 0H4c-.729 0-1.429.29-1.945.805A2.755 2.755 0 0 0 1.25 7v2.75h3.132c.07-.192.167-.376.292-.548.757-1.032 2.352-1.275 3.568-.462.19.127.371.297.54.495z'
    />
  </svg>
);
export default SvgGiftCard;
