import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { grantCookiesConsent } from '../../features/cookieConsent';
import GiveawayContent from '../../features/registration/components/GiveawayContent';
import {
  useIsFisheryAdminLoggedInQuery,
  useLogoutMutation,
} from '../../features/users/usersSlice';

export default function SubscriptionGiveawaySignUpPage(): React.JSX.Element {
  const [logout] = useLogoutMutation();
  const { data: isLoggedInAsAdmin } = useIsFisheryAdminLoggedInQuery();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    grantCookiesConsent();
  }, []);

  useEffect(() => {
    if (isLoggedInAsAdmin) {
      logout(); // Prevents fishery admin accounts from being associated with subscriptions
    }
  }, [isLoggedInAsAdmin, logout]);

  return (
    <>
      <Helmet>
        <title>swimbooker+ | Subscribe Today!</title>
      </Helmet>
      <div className='grid h-full min-h-screen w-full place-content-center bg-gradient-to-b from-blue to-[#1B4C74] px-12 py-4 text-white '>
        <div className='mx-auto my-auto w-full min-w-0 max-w-sm text-center'>
          <GiveawayContent />
        </div>
      </div>
    </>
  );
}
