import { SVGProps } from 'react';

const LeftCircleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={30} height={30} {...props}>
    <g clipPath='url(#a)'>
      <path
        fill='currentColor'
        d='M15 0C6.729 0 0 6.729 0 15s6.729 15 15 15 15-6.729 15-15S23.271 0 15 0Zm3.384 20.366A1.248 1.248 0 0 1 17.5 22.5c-.32 0-.64-.122-.884-.366l-6.25-6.25a1.249 1.249 0 0 1 0-1.768l6.25-6.25a1.249 1.249 0 0 1 1.768 0 1.248 1.248 0 0 1 0 1.768L13.017 15l5.367 5.366Z'
      />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='#fff' d='M0 0h30v30H0z' />
      </clipPath>
    </defs>
  </svg>
);

export default LeftCircleIcon;
