import { useMemo } from 'react';

import { coreApiSlice } from '../../store/apiSlices/coreApiSlice';
import { proApiSlice } from '../../store/apiSlices/proApiSlice';
import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { notification } from '../../utils/messaging';
import { AxiosError } from 'axios';

export interface AnglingUser {
  first_name: string;
  last_name: string;
  user_image: string;
  email: string;
  public_id: number;
  contact?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  postcode?: string;
  verified: boolean;
  years_angling?: string;
  is_public: boolean;
}

export interface FisheryAdminUser {
  email: string;
  first_name: string;
  last_name: string;
  id: string;
  fishery_id: string;
  role: string;
}

export interface AnglingUserIds {
  public_id?: number | null;
  user_id: string;
}

interface CreateAnglerAccountRequest {
  email: string;
  password: string;
  first_name: string;
  last_name: string;
  is_news_subscribed?: boolean;
}

const isUserLoggedInSelector = createSelector(
  (result: { data?: AnglingUser }) => result.data,
  (user) => Boolean(user)
);
export const useIsLoggedInQuery = () => {
  return useGetUserQuery(undefined, {
    selectFromResult: (result) => ({
      ...result,
      data: isUserLoggedInSelector(result),
    }),
  });
};

const isFisheryAdminLoggedInSelector = createSelector(
  (result: { data?: FisheryAdminUser }) => result.data,
  (user) => Boolean(user)
);
export const useIsFisheryAdminLoggedInQuery = () => {
  return useGetFisheryAdminUserQuery(undefined, {
    selectFromResult: (result) => ({
      ...result,
      data: isFisheryAdminLoggedInSelector(result),
    }),
  });
};

/**
 * Used to trigger the useUserIdentification hook without using useGetUserQuery since this will cause an infinite loop
 * of re-fetching if the user is not logged in (due to the secureRoutes auth also subscribing to the same endpoint in
 * App.tsx)
 */
const identifyUser = createAction<boolean>('user/identify');
export const userIdentificationReducer = createReducer(false, (builder) => {
  builder.addCase(identifyUser, (state, action) => action.payload);
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const loginErrorHandler = (err: AxiosError | any) => {
  if (
    err?.status === 401 ||
    (err?.status === 422 && err?.data.detail === 'Signature has expired')
  ) {
    notification.error({
      description: 'Incorrect Details. Please try again.',
    });
  } else if (err?.data?.detail) {
    notification.error({
      description: err?.data?.detail,
    });
  } else {
    notification.error({
      description: 'Something went wrong!',
    });
  }
};

export const extendedCoreApiSlice = coreApiSlice
  .enhanceEndpoints({ addTagTypes: ['User', 'FisheryUser'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getUser: builder.query<AnglingUser, void>({
        query: () => ({
          url: 'user',
          method: 'GET',
          useDefaultErrorHandler: false,
        }),
        transformResponse: (response: {
          status: string;
          result: AnglingUser;
        }) => response?.result ?? response,
        providesTags: ['User'],
        async onQueryStarted(id, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
            dispatch(identifyUser(Boolean(data)));
          } catch (e) {
            dispatch(identifyUser(false));
          }
        },
      }),
      getFisheryAdminUser: builder.query<FisheryAdminUser, void>({
        query: () => ({
          url: `fishery/admin-user`,
          method: 'GET',
          useDefaultErrorHandler: false,
        }),
        providesTags: ['FisheryUser'],
      }),
      getUserId: builder.query<AnglingUserIds, void>({
        query: () => ({
          url: 'user/id',
          method: 'GET',
          useDefaultErrorHandler: false,
        }),
        transformResponse: (response: {
          status: string;
          result: AnglingUserIds;
        }) => response?.result ?? response,
        providesTags: ['User'],
      }),
      login: builder.mutation<
        void,
        {
          username: string;
          password: string;
        }
      >({
        query: ({ username, password }) => {
          const formData = new FormData();
          formData.append('username', username.trim());
          formData.append('password', password);
          return {
            url: 'login',
            method: 'POST',
            data: formData,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            useDefaultErrorHandler: false,
          };
        },
        invalidatesTags: ['User'],
      }),
      logout: builder.mutation<unknown, void>({
        queryFn: async (_, api, extraOptions, executeBaseQuery) => {
          await executeBaseQuery({
            url: 'angler/refresh-revoke',
            method: 'get',
          });
          return executeBaseQuery({
            url: 'angler/access-revoke',
            method: 'get',
          });
        },
        invalidatesTags: ['User', 'FisheryUser'],
      }),
      newAnglerSignup: builder.mutation<void, CreateAnglerAccountRequest>({
        query: (request) => ({
          url: 'signup',
          method: 'POST',
          data: request,
        }),
        invalidatesTags: ['User'],
      }),
    }),
  });

export interface NewSessionRequest {
  booking_id?: string;
  fishery_id: string;
  fishery_name?: string;
  start_ts: string;
  end_ts: string;
}

export interface Session {
  id: string;
  fishery_id: string;
  start_ts: string;
  end_ts: string;
  fishery_name: string;
}

export const useIsPreRegisteredQuery = () => {
  const isPreRegisteredSelector = createSelector(
    (result: { data?: string | null }) => result.data,
    (subLevel) => subLevel === 'PreRegistered'
  );
  return useGetUserSubscriptionLevelQuery(true, {
    selectFromResult: (result) => ({
      ...result,
      data: isPreRegisteredSelector(result),
    }),
  });
};

export const extendedProApiSlice = proApiSlice
  .enhanceEndpoints({ addTagTypes: ['Subscription', 'Session'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getUserSubscriptionLevel: builder.query<string | null, void | boolean>({
        query: (return_pre_registered = false) => ({
          url: 'user/subscription-level',
          method: 'GET',
          useDefaultErrorHandler: false,
        }),
        transformResponse: (
          response: string,
          meta,
          return_pre_registered = false
        ) => {
          if (response === 'PreRegistered') {
            return return_pre_registered ? response : null;
          }
          return response === 'NoSubscription' ? null : response;
        },
        providesTags: ['Subscription'],
      }),
      setUserSubscriptionLevel: builder.mutation<void, string>({
        query: (level) => ({
          url: 'user/subscription-level',
          method: 'PUT',
          params: { level },
        }),
        invalidatesTags: ['Subscription'],
      }),
      getCurrentSession: builder.query<Session, void>({
        query: () => ({
          url: 'user/current-session',
          method: 'GET',
          useDefaultErrorHandler: false,
        }),
        providesTags: ['Session'],
      }),
      getNextSessionStart: builder.query<string, void>({
        query: () => ({
          url: 'user/next-session-start',
          method: 'GET',
        }),
        providesTags: ['Session'],
      }),
      newSession: builder.mutation<void, NewSessionRequest>({
        query: (request) => ({
          url: 'sessions/create',
          method: 'POST',
          data: request,
        }),
      }),
    }),
  });

export const {
  useGetUserQuery,
  useGetFisheryAdminUserQuery,
  useGetUserIdQuery,
  useLazyGetUserQuery,
  useLoginMutation,
  useLogoutMutation,
  useNewAnglerSignupMutation,
} = extendedCoreApiSlice;

export const {
  useGetUserSubscriptionLevelQuery,
  useGetCurrentSessionQuery,
  useGetNextSessionStartQuery,
  useSetUserSubscriptionLevelMutation,
  useNewSessionMutation,
} = extendedProApiSlice;

export const useGetCurrentFisheryId = () => {
  const currentSessionFisheryIDSelector = useMemo(
    () =>
      createSelector(
        (result: { data?: Session }) => result.data,
        (current_session) => current_session?.fishery_id
      ),
    []
  );

  return useGetCurrentSessionQuery(undefined, {
    selectFromResult: (result) => ({
      ...result,
      data: currentSessionFisheryIDSelector(result),
    }),
  });
};
