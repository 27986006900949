import hourglass from '../assets/media/hourglass.svg';
import '../assets/css/subscription-loading.css';

function SubscriptionLoading({
  hideMessage = false,
}: {
  hideMessage?: boolean;
}) {
  return (
    <div className='text-grey-100'>
      {!hideMessage && (
        <>
          <h1 className='mb-7 text-xl font-semibold '>
            We&apos;re Processing Your Membership...
          </h1>
          <p className='mb-9 text-lg '>
            Please don&apos;t close or leave this page whilst we finish
            registering your subscription.
          </p>
        </>
      )}
      <img
        src={hourglass}
        alt='loading'
        className='hourglass-flip mx-auto w-16'
      />
    </div>
  );
}

export default SubscriptionLoading;
