import React from 'react';
import FoodOrder from '../features/foodOrder/components/FoodOrderSection';
import DesktopLayout from '../common/components/DesktopLayout';

export default function FoodDeliveryResults(): React.JSX.Element {
  return (
    <DesktopLayout>
      <FoodOrder />
    </DesktopLayout>
  );
}
