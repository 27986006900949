import { useState } from 'react';
import { Link } from 'react-router-dom';
import LeftClose from '../../foodOrder/assets/images/icon-navigation-chevron_left_24px.svg';
import SessionNoteIcon from '../assets/images/notes.svg';

import {
  useSessionNotesQuery,
  SessionNotes,
} from '../../../store/apiSlices/SessionNotesSlice';
import { useGetCurrentSessionQuery } from '../../users/usersSlice';

import SessionNote from './SessionNote';
import SessionNoteDetails from './SessionNoteDetails';

const SessionNotesList = () => {
  const { data: userSessionDetails } = useGetCurrentSessionQuery();
  const { data: sessionNotesData } = useSessionNotesQuery(
    userSessionDetails?.id || ''
  );

  const [isEditingNotes, setIsEditingNotes] = useState(false);
  const [noteDetailsToEdit, setNoteDetailsToEdit] =
    useState<null | SessionNotes>(null);

  return (
    <div className=''>
      <div className='bg-blue p-4 text-white'>
        <div className='relative flex flex-row items-center'>
          <div>
            <Link to='/home' className='absolute top-[50%] translate-y-[-50%]'>
              <img src={LeftClose} alt='close' className='w-10' />
            </Link>
          </div>
          <div className='flex w-full flex-col items-center justify-center'>
            <div className='text-xl font-bold'>Session Notes</div>
          </div>
        </div>
      </div>
      {!isEditingNotes ? (
        <div>
          <div className='p-4'>
            <div className='session_detail_box_shadow mb-2.5 rounded-[10px] bg-white p-2.5'>
              <div className='my-1.5 flex items-center text-[22px] font-semibold'>
                <img src={SessionNoteIcon} className='mr-4' alt='notes icon' />
                Your Notes
              </div>
            </div>
            {sessionNotesData?.map((sessionNote) => (
              <SessionNote
                noteDetails={sessionNote}
                key={sessionNote.id}
                setIsEditingNotes={setIsEditingNotes}
                setNoteDetailsToEdit={setNoteDetailsToEdit}
              />
            ))}
          </div>
        </div>
      ) : (
        <SessionNoteDetails
          noteDetails={noteDetailsToEdit as SessionNotes}
          setIsEditingNotes={setIsEditingNotes}
        />
      )}
    </div>
  );
};

export default SessionNotesList;
