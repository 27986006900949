import FisheryReview from '../assets/media/White_Fishy_Icon.png';
import '../assets/review_score_visualisation.css';

/**
 * A visualisation of a fishery review score / 5
 */
interface ReviewScoreVisualisationProps {
  className?: string;
  score: number;
  small?: boolean;
  onClick?: (value: number) => void;
  interactive?: boolean;
}
export default function ReviewScoreVisualisation({
  className = '',
  score,
  small = false,
  onClick = (value) => {},
  interactive = false,
}: ReviewScoreVisualisationProps) {
  const Star = ({
    active = false,
    value,
  }: {
    value: number;
    active?: boolean;
  }) => (
    <div
      className={
        className +
        ' review_score_visualisation__star ' +
        (small ? 'small ' : '') +
        (active ? 'active ' : 'inactive ')
      }
      onClick={() => onClick(value)}
    >
      <img alt='Review Icon' src={FisheryReview} />
    </div>
  );

  const rows = [];
  for (let i = 0; i < score; i++) {
    const starValue = i + 1;
    rows.push(<Star key={`Star ${starValue}`} active value={starValue} />);
  }
  for (let i = 0; i < 5 - score; i++) {
    const starValue = i + score + 1;
    rows.push(<Star key={`Star ${starValue}`} value={starValue} />);
  }

  return (
    <div
      className={
        'review_score_visualisation ' + (interactive ? 'interactive ' : '')
      }
    >
      {rows}
    </div>
  );
}
