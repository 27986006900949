import React, { ReactNode } from 'react';
import { Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import '../assets/css/feature-table.css';
import blueCheck from '../assets/media/blueCheck.svg';
import DotIcon from '../assets/media/Dot';

enum FeatureAvailability {
  NONE,
  PARTIAL,
  FULL,
}

const renderAvailability = (
  value: FeatureAvailability | ReactNode
): ReactNode => {
  switch (value) {
    case FeatureAvailability.FULL:
      return (
        <img className='mx-auto' src={blueCheck} alt='checkmark' width={24} />
      );
    case FeatureAvailability.PARTIAL:
      return <DotIcon className='mx-auto' />;
    case FeatureAvailability.NONE:
      return (
        <div
          className='mx-auto h-[2px] max-w-[20px] bg-grey-300'
          role='img'
          aria-label='dash icon'
        />
      );
    default:
      return <p className='mx-auto text-base'>{value}</p>;
  }
};

interface Feature {
  name: ReactNode;
  description?: ReactNode;
  free: FeatureAvailability | ReactNode;
  plus: FeatureAvailability | ReactNode;
}

export default function FeatureTable(): React.JSX.Element {
  const columns: ColumnsType<Feature> = [
    {
      title: 'What you get',
      dataIndex: 'name',
      render: (feature, record) =>
        record?.description ? (
          <Tooltip
            placement='right'
            title={record.description}
            trigger='hover'
            color='#DCE1E5'
            overlayClassName='text-grey-800 font-medium'
            overlayInnerStyle={{ color: 'inherit' }}
            className='cursor-pointer underline'
          >
            {feature}
          </Tooltip>
        ) : (
          feature
        ),
    },
    {
      title: 'swimbooker Free Account',
      dataIndex: 'free',
      className: 'text-center',
      render: renderAvailability,
    },
    {
      title: 'swimbooker+ Membership',
      dataIndex: 'plus',
      className: 'text-center',
      render: renderAvailability,
    },
  ];

  const features: Feature[] = [
    {
      name: 'Access 100+ Venues',
      free: FeatureAvailability.FULL,
      plus: FeatureAvailability.FULL,
    },
    {
      name: 'No Booking Fees',
      free: FeatureAvailability.FULL,
      plus: FeatureAvailability.FULL,
    },
    {
      name: 'Insurance Cover',
      free: FeatureAvailability.NONE,
      plus: FeatureAvailability.FULL,
      description:
        'swimbooker+ insurance cover is backed by Aviva and claims are handled by our partners at Marsh Sports.',
    },
    {
      name: 'Exclusive Perks',
      free: FeatureAvailability.NONE,
      plus: FeatureAvailability.FULL,
      description:
        'Access industry first, exclusive member perks from the most popular brands and suppliers in the industry.',
    },
    {
      name: 'Fishing Discounts',
      free: FeatureAvailability.NONE,
      plus: FeatureAvailability.FULL,
      description:
        'Receive significant discount when booking across our network of fishery and lake partners',
    },
    {
      name: 'Weekly Giveaways',
      free: FeatureAvailability.NONE,
      plus: FeatureAvailability.FULL,
      description:
        'Gain access to over 52 giveaways per year with an average prize pot of over £250.00',
    },
    {
      name: 'SB+ Shop',
      free: FeatureAvailability.NONE,
      plus: FeatureAvailability.FULL,
      description:
        'Your one-stop-shop; unlock swimbooker merchandise and incredible deals from across the industry',
    },
    {
      name: 'Cashback on fishing & tackle',
      free: FeatureAvailability.NONE,
      plus: FeatureAvailability.FULL,
      description:
        'Plus members earn credit back in their SB wallet for every purchase made via our platform',
    },
    {
      name: 'InSession Pro Tool',
      free: FeatureAvailability.NONE,
      plus: FeatureAvailability.FULL,
      description:
        'A handy bankside assistant; allowing you to keep notes, track your captures, find local food and so much more!',
    },
  ];

  return (
    <Table
      className='plan-features-table mx-auto max-w-md'
      columns={columns}
      dataSource={features}
      pagination={false}
    />
  );
}
