const SvgLeftChevron = ({ ...props }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='6 6 12 12'
    style={{
      transform: 'scale(-1, 1)',
    }}
    {...props}
  >
    <path
      style={{
        fill: 'none',
      }}
      d='M0 0h24v24H0z'
    />
    <path d='M10.008 6 8.6 7.41 13.174 12 8.6 16.59 10.008 18 16 12Z' />
  </svg>
);
export default SvgLeftChevron;
