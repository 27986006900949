import { Button, Form, Input, Spin } from 'antd';
import { loginErrorHandler, useLoginMutation } from '../usersSlice';
import { useNavigate } from 'react-router-dom';

interface LoginFormProps {
  redirectUrl?: string | null;
  className?: string;
}

export default function LoginForm({
  redirectUrl = '/',
  className = '',
}: LoginFormProps) {
  const [login, { isLoading }] = useLoginMutation();
  const navigate = useNavigate();

  return (
    <div className={className}>
      <Form
        name='basic'
        className='mb-11'
        requiredMark={false}
        colon={false}
        layout='vertical'
        initialValues={{
          remember: true,
        }}
        onFinish={(formData) =>
          login(formData)
            .unwrap()
            .then(() => {
              if (redirectUrl) {
                navigate(redirectUrl, { replace: true });
              }
            })
            .catch(loginErrorHandler)
        }
      >
        <Form.Item
          name='username'
          className='mb-4'
          label={
            <span className='-mb-1 text-lg font-medium text-white'>Email:</span>
          }
          rules={[
            {
              required: true,
              message: 'Please input your username!',
            },
          ]}
        >
          <Input placeholder='Enter your email' className='py-3 text-base' />
        </Form.Item>
        <Form.Item
          name='password'
          className='mb-7'
          label={
            <span className='-mb-1 text-lg font-medium text-white'>
              Password:
            </span>
          }
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
        >
          <Input.Password
            placeholder='Enter your password'
            className='py-3 text-base'
          />
        </Form.Item>

        <Form.Item>
          <Button
            type='primary'
            className='h-fit w-full rounded-md border-none bg-[#379BEC] p-3 text-lg font-semibold text-white'
            htmlType='submit'
            disabled={isLoading}
          >
            {isLoading ? <Spin /> : 'Log In'}
          </Button>
        </Form.Item>
      </Form>
      <p className='font-medium'>
        Forgot your details? Click{' '}
        <a
          href={`${process.env?.REACT_APP_MAIN_SITE}user/forgot_password`}
          className='underline'
        >
          HERE
        </a>{' '}
        to reset
      </p>
    </div>
  );
}
