import { coreApiSlice } from './coreApiSlice';

export interface NewCatchReportRequest {
  name: string;
  fishery?: string;
  lake: string | null;
  swim: string;
  fish_species: string;
  fish_weight_lb: number;
  fish_weight_oz: number;
  rig: string;
  bait: string;
  notes: string;
  personal_best: boolean;
  image_upload: string;
  fish_caught_date: string;
}

export const extendedApiSlice = coreApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createCatchReport: builder.mutation<void, NewCatchReportRequest>({
      query: (request) => ({
        url: 'fishery/catch/report',
        method: 'POST',
        data: request,
        useDefaultErrorHandler: false,
      }),
      transformErrorResponse: (
        response: { status: string | number },
        meta,
        arg
      ) => response,
    }),
  }),
});

export const { useCreateCatchReportMutation } = extendedApiSlice;
