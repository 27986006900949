export const LOGIN_ROUTE = '/login';
export const ACCOUNT_CREATION_ROUTE = '/account-creation';
export const SUBSCRIPTION_SIGN_UP_ROUTE = '/sign-up';
export const WAITING_ROOM_ROUTE = '/waiting-room';

export const PRIVACY_POLICY_URL =
  process.env?.REACT_APP_PRIVACY_URL ??
  'https://docs.google.com/viewerng/viewer?url=https://swimbooker-fishery-details.s3.eu-west-2.amazonaws.com/web_assets/swimbooker_privacy_policy.pdf';
export const TERMS_AND_CONDITIONS_URL =
  process.env?.REACT_APP_TERMS_URL ??
  'https://docs.google.com/viewerng/viewer?url=https://swimbooker-fishery-details.s3.eu-west-2.amazonaws.com/web_assets/swimbooker_terms__conditions.pdf';
