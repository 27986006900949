import { FeatureSection } from '../components/FeatureAccordion';
import FeatureIcons from './media/feature-icons';

const features: FeatureSection[] = [
  {
    key: 'insurance',
    title: 'All Your Gear. Covered By Us.',
    icon: FeatureIcons.Dark.Shield,
    items: [
      {
        name: 'Comprehensive Equipment Insurance',
        icon: FeatureIcons.Rod,
        description:
          'Get loss, theft and damage insurance for all of your fishing gear and equipment.',
      },
      {
        name: 'Personal Injury Insurance',
        icon: FeatureIcons.Bandage,
        description:
          'None of us like to think about the day disaster might strike on the bank. If it does, we’ve got you covered!',
      },
      {
        name: 'Backed By Aviva',
        icon: FeatureIcons.AvivaLogo,
        description:
          'The insurance cover included with swimbooker+ is underwritten by one of the leading global insurance providers.',
      },
    ],
  },
  {
    key: 'shop',
    title: 'Become a Bait & Tackle VIP',
    icon: FeatureIcons.Dark.Basket,
    items: [
      {
        name: 'Up to 30% Retail Discount',
        icon: FeatureIcons.Percent,
        description:
          'Get up to 30% discount on all bait, tackle & equipment via swimbooker+ industry partnerships and suppliers.',
      },
      {
        name: 'Dedicated SB Shop',
        icon: FeatureIcons.Store,
        description:
          'Using our experience building powerful online angling platforms we have crafted a tailor-made bait & tackle marketplace.',
      },
      {
        name: 'Elevating All Brands',
        icon: FeatureIcons.Package,
        description:
          'Gain access to a unique, exciting range of brand offers and discounts.',
      },
    ],
  },
  {
    key: 'giveaways',
    title: 'Next-Level Giveaways',
    icon: FeatureIcons.Dark.Gift,
    items: [
      {
        name: 'Access To Every Draw!',
        icon: FeatureIcons.NoTickets,
        description:
          'Gain access to every swimbooker+ giveaway without purchasing extra tickets!',
      },
      {
        name: 'Hosted by #TeamSB',
        icon: FeatureIcons.Fish,
        description:
          'Tune in and watch our weekly live stream to discover whether you are the lucky winner!',
      },
      {
        name: 'Special Bonus Entry',
        icon: FeatureIcons.GiftCard,
        description:
          'Boost your chances of winning each month by entering special bonus giveaways.',
      },
    ],
  },
  {
    key: 'tech',
    title: 'Level Up Your Angling - InSession™',
    icon: FeatureIcons.Dark.Hook,
    items: [
      {
        name: 'Local Angler Chat',
        icon: FeatureIcons.Chat,
        description:
          'Access a dynamic chat with other InSession anglers at your venue; great for sharing tips, getting assistance or general conversation!',
      },
      {
        name: 'Live Worldwide Activity Feed',
        icon: FeatureIcons.Like,
        description: (
          <>
            Gain access to your own live feed of other InSession anglers&apos;
            catches and activities at SB venues around the globe!
          </>
        ),
      },
      {
        name: 'Jam-Packed With Features',
        icon: FeatureIcons.FeaturesHand,
        description:
          'Take detailed session notes, find local food delivery services and find out everything you need to know about your current venue.',
      },
    ],
  },
  {
    key: 'offers',
    title: 'Industry First Perks & Discounts',
    icon: FeatureIcons.Dark.Ticket,
    items: [
      {
        name: 'Exclusive Partner Fishery Perks',
        icon: FeatureIcons.Handshake,
        description:
          'Gain access to exclusive discounts and promotions on fishing via our swimbooker™ partnered fishery network.',
      },
      {
        name: 'Exclusive Partner Supplier Perks',
        icon: FeatureIcons.Bag,
        description:
          'Gain access to exclusive industry perks via our swimbooker™ supplier network.',
      },
      {
        name: 'Constantly Growing Network',
        icon: FeatureIcons.Network,
        description:
          'We are continuously boosting this network to provide even more value over time to swimbooker+ members.',
      },
    ],
  },
  {
    key: 'cashback',
    title: 'Up to 2% Cashback on Bookings, Bait & Tackle',
    icon: FeatureIcons.Dark.Coins,
    items: [
      {
        name: 'Direct Cashback on Fishing',
        icon: FeatureIcons.MoneyTransfer,
        description:
          'Earn direct cashback to your SB wallet by booking your fishing online at UK or ROW swimbooker fisheries!',
      },
      {
        name: 'Direct Cashback on Tackle',
        icon: FeatureIcons.Cashback,
        description:
          'Boost your return by receiving cashback for purchases made via the dedicated SB shop.',
      },
      {
        name: 'Your Digital SB Wallet',
        icon: FeatureIcons.Wallet,
        description:
          'Your own digital angling wallet! Collect all cashback in one place and redeem on fishing or additional store credit!',
      },
    ],
  },
];

export default features;
