import { SVGProps } from 'react';
const SvgSignal = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={27}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M13.992 17.825a.415.415 0 0 0-.039-.11l-1.602-4.483A2.614 2.614 0 0 0 13.62 11 2.621 2.621 0 0 0 11 8.38 2.621 2.621 0 0 0 8.381 11c0 .948.511 1.771 1.268 2.232l-1.602 4.483a.417.417 0 0 0-.039.11l-2.74 7.666a.524.524 0 0 0 .865.546L11 21.17l4.867 4.868a.527.527 0 0 0 .652.073.524.524 0 0 0 .213-.62l-2.74-7.665Z'
      fill='#fff'
    />
    <path
      d='M11 0C4.934 0 0 4.934 0 11c0 3.426 1.633 6.703 4.368 8.767.229.171.557.13.733-.102a.526.526 0 0 0-.103-.735A9.987 9.987 0 0 1 1.048 11c0-5.487 4.465-9.952 9.952-9.952S20.952 5.513 20.952 11a9.988 9.988 0 0 1-3.95 7.93.525.525 0 0 0 .315.943.52.52 0 0 0 .316-.105A11.044 11.044 0 0 0 22 11c0-6.066-4.934-11-11-11Z'
      fill='#fff'
    />
    <path
      d='M15.445 15.46a.522.522 0 0 0 .739-.053A6.73 6.73 0 0 0 17.809 11 6.817 6.817 0 0 0 11 4.19 6.817 6.817 0 0 0 4.19 11a6.73 6.73 0 0 0 1.626 4.407.523.523 0 1 0 .792-.685A5.69 5.69 0 0 1 5.238 11 5.77 5.77 0 0 1 11 5.238 5.77 5.77 0 0 1 16.762 11a5.69 5.69 0 0 1-1.37 3.722.524.524 0 0 0 .053.739Z'
      fill='#fff'
    />
  </svg>
);
export default SvgSignal;
