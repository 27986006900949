import { SVGProps } from 'react';
const SvgBandage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={21}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='m7.455 11.717 4.254-4.254.83.829-4.255 4.254-.83-.829Z'
      fill='#fff'
    />
    <path
      d='M7.003 18.8 18.795 7.01a4.107 4.107 0 0 0 0-5.802 4.107 4.107 0 0 0-5.801 0L1.203 13a4.098 4.098 0 0 0 0 5.8 4.098 4.098 0 0 0 5.8 0ZM16.27 4.563a.586.586 0 1 1 .828.83.586.586 0 0 1-.828-.83ZM7.043 15.447a.586.586 0 1 1-.83-.829.586.586 0 0 1 .83.829Zm7.57-12.542a.586.586 0 1 1 .828.828.586.586 0 0 1-.829-.828Zm0 3.315a.586.586 0 1 1 .828.828.586.586 0 0 1-.829-.828Zm-1.658-1.658a.586.586 0 1 1 .829.83.586.586 0 0 1-.83-.83Zm-6.741 6.741 5.083-5.083a.586.586 0 0 1 .829 0l1.658 1.657c.228.23.228.6 0 .83L8.7 13.79a.586.586 0 0 1-.829 0l-1.657-1.658a.586.586 0 0 1 0-.829Zm-1.658 1.658a.586.586 0 1 1 .83.828.586.586 0 0 1-.83-.828Zm0 3.315a.586.586 0 1 1 .83.828.586.586 0 0 1-.83-.828ZM2.9 14.618a.586.586 0 1 1 .829.829.586.586 0 0 1-.83-.829ZM18.795 13l-2.167-2.167-5.8 5.801 2.166 2.166a4.099 4.099 0 0 0 5.801 0 4.106 4.106 0 0 0 0-5.8ZM1.203 1.2a4.099 4.099 0 0 0 0 5.8L3.37 9.176l5.8-5.801-2.165-2.176a4.107 4.107 0 0 0-5.8 0Z'
      fill='#fff'
    />
  </svg>
);
export default SvgBandage;
