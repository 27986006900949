import noImage from '../../../assets/media/no-photo.jpg';
import { useGlobalState } from './ReportsGlobalState';

import { StepThreeProps } from '../types';

function CatchReportPreview({
  focus,
  setFocus,
  date,
  time,
  dontHavePic,
}: StepThreeProps) {
  const [catchInfo] = useGlobalState('catchInfo');
  const [commonInfo] = useGlobalState('commonInfo');

  const [, setCurrent] = useGlobalState('current');
  const [fileList] = useGlobalState('fileList');
  return (
    <div className='text-base'>
      <div className='catch-report-image-display mb-5 h-[12rem] min-h-fit w-full overflow-hidden object-cover'>
        {fileList && !dontHavePic ? (
          <div className='flex flex-col items-center'>
            <img alt='catch' src={fileList} className='h-full' />
          </div>
        ) : (
          <div className='flex  flex-col items-center '>
            <img
              alt='empty'
              src={noImage}
              className='aspect-[7/3] h-full object-cover'
            />
          </div>
        )}
      </div>
      <p className='mb-5 font-semibold'>
        Please confirm the details of your catch report:
      </p>

      <div className='mb-2.5'>
        <span className=' font-semibold '>Fish Caught:</span>
        <div className=' flex justify-between'>
          <span className=''>{`${catchInfo.species} ${catchInfo?.fish_weight_lb}lbs ${catchInfo?.fish_weight_oz}oz`}</span>
          <div
            className='center cursor-pointer text-base font-semibold underline '
            onClick={() => {
              setFocus((prevState) => ({
                ...prevState,
                bait: false,
                rig: false,
                fishery: false,
                lake: false,
                swim: false,
                date: false,
                time: false,
                species: true,
              }));
              setCurrent(1);
            }}
          >
            Edit
          </div>
        </div>
      </div>
      <div className='mb-2.5'>
        <span className=' font-semibold '>Using:</span>
        <div className='flex justify-between'>
          <span className=''>{catchInfo?.bait}</span>
          <span
            className='flex cursor-pointer flex-col items-center text-base font-semibold underline '
            onClick={() => {
              setFocus((prevState) => ({
                ...prevState,
                bait: true,
                rig: false,
                fishery: false,
                lake: false,
                swim: false,
                date: false,
                time: false,
                species: false,
              }));
              setCurrent(1);
            }}
          >
            Edit
          </span>
        </div>
        <div className='flex justify-between'>
          <span className=''>{catchInfo?.rig}</span>
          <span
            className='flex cursor-pointer flex-col items-center text-base font-semibold underline '
            onClick={() => {
              setFocus((prevState) => ({
                ...prevState,
                bait: true,
                rig: false,
                fishery: false,
                lake: false,
                swim: false,
                date: false,
                time: false,
                species: false,
              }));
              setCurrent(1);
            }}
          >
            Edit
          </span>
        </div>
      </div>
      <div className='mb-2.5'>
        <span className=' font-semibold '>Swim:</span>
        <div className='flex justify-between'>
          <span className=''>{`${commonInfo?.fisheryName}, ${commonInfo?.lake}, ${commonInfo?.swim}`}</span>
          <span
            className='flex cursor-pointer flex-col items-center text-base font-semibold underline '
            onClick={() => {
              setFocus((prevState) => ({
                ...prevState,
                bait: false,
                rig: false,
                fishery: true,
                lake: true,
                swim: true,
                date: false,
                time: false,
                species: false,
              }));
              setCurrent(0);
            }}
          >
            Edit
          </span>
        </div>
      </div>
      <div className='mb-2.5'>
        <span className=' font-semibold '>On:</span>
        <div className='flex justify-between'>
          <span className=''>{`${date?.format(
            'dddd Do MMMM YYYY'
          )} ${time?.format('HH:mm')}`}</span>
          <span
            className='flex cursor-pointer flex-col items-center text-base font-semibold underline '
            onClick={() => {
              setFocus((prevState) => ({
                ...prevState,
                bait: false,
                rig: true,
                fishery: false,
                lake: false,
                swim: false,
                date: true,
                time: true,
                species: false,
              }));
              setCurrent(0);
            }}
          >
            Edit
          </span>
        </div>
      </div>
    </div>
  );
}

export default CatchReportPreview;
