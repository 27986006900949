import { Button, Drawer } from 'antd';
import React, { useEffect, useRef } from 'react';
import { usePreventScroll } from '../../../utils/hooks';
import getScrollBarSize from 'rc-util/lib/getScrollBarSize';

import './assets/floating-footer.css';
import { CloseOutlined } from '@ant-design/icons';

export interface FloatingFooterProps
  extends Omit<React.HTMLProps<HTMLDivElement>, 'size'> {
  footerRadius?: boolean;
  maxWidth?: string | number;
  drawerOnTop?: boolean;
  isDrawerOpen?: boolean;
  hasCloseBar?: boolean;
  isActivityDrawer?: boolean;
  drawerClassName?: string;
  drawerContainerClassName?: string;
  hasCloseIcon?: boolean;
  limitHeight?: boolean;
  noShadow?: boolean;
  onDrawerClose?: () => void;
  drawerContents?: React.JSX.Element | null;
}

function FloatingFooter({
  footerRadius = false,
  drawerOnTop = false,
  hasCloseBar = false,
  isActivityDrawer = false,
  hasCloseIcon = false,
  limitHeight = false,
  noShadow = false,
  drawerContents = undefined,
  maxWidth = 'unset',
  isDrawerOpen = false,
  onDrawerClose = () => {},
  className = '',
  drawerClassName = '',
  drawerContainerClassName = '',
  children,
}: FloatingFooterProps): React.JSX.Element {
  const ref = useRef<HTMLDivElement>(null);
  // Prevent background app scroll when drawer is open
  usePreventScroll('FisheryProfileFooter', isDrawerOpen);

  // Ensures Drawer is centered horizontally because AntD uses rc-utils.getScrollBarSize to prevent background layout shift
  useEffect(() => {
    if (!isDrawerOpen || !ref?.current) {
      return;
    }
    const floatingElements: HTMLElement[] = [
      ...Array.prototype.slice.call(
        ref?.current?.getElementsByClassName(
          'floating-footer__drawer-container'
        )
      ),
      ...Array.prototype.slice.call(
        ref?.current?.getElementsByClassName('floating-footer__wrapper')
      ),
    ];
    const scrollBarWidth = getScrollBarSize();
    floatingElements.forEach((el) => {
      el.style.maxWidth = `calc(100vw - ${scrollBarWidth}px)`;
    });
  }, [isDrawerOpen]);

  return (
    <div
      ref={ref}
      className={`floating-footer__container ${
        !drawerOnTop ? 'floating-footer__container--offset' : ''
      }`}
    >
      {!isActivityDrawer && (
        <div className='floating-footer__wrapper'>
          <div
            className={`floating-footer  mx-auto ${
              footerRadius ? 'floating-footer--top-radius' : ''
            } ${
              noShadow ? 'floating-footer--no-shadow' : ''
            } border-4 border-neutral-650 bg-neutral-900 p-6 ${className}`}
            style={{ maxWidth }}
          >
            {children}
          </div>
        </div>
      )}

      <div
        className={
          `floating-footer__drawer-container ${drawerContainerClassName} ` +
          (hasCloseBar
            ? 'floating-footer__drawer-container--with_close_bar '
            : '') +
          (limitHeight
            ? 'floating-footer__drawer-container--limit_height '
            : '')
        }
      >
        <Drawer
          height='fit-content'
          className={`floating-footer__drawer text-white ${drawerClassName}`}
          contentWrapperStyle={{ maxWidth }}
          placement='bottom'
          open={isDrawerOpen}
          maskClosable
          onClose={onDrawerClose}
          getContainer={false}
        >
          <div className='flex flex-col'>
            {hasCloseIcon && (
              <Button
                type='text'
                className='ml-auto text-white'
                icon={<CloseOutlined />}
                onClick={onDrawerClose}
              />
            )}
            {hasCloseBar && (
              <div className='w-full pb-4 pt-6' onClick={onDrawerClose}>
                <div className='mx-auto h-0 w-12 rounded-sm border-[3px] border-neutral-650' />
              </div>
            )}
            {drawerContents}
          </div>
        </Drawer>
      </div>
    </div>
  );
}

export default FloatingFooter;
