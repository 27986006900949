import { proApiSlice } from '../../store/apiSlices/proApiSlice';

export interface RegistrationRequest {
  first_name: string;
  last_name: string;
  email: string;
}

export interface SubscriptionPrice {
  id: string;
  cost: number;
  interval: 'month' | 'year' | 'week' | 'day';
}

export interface SubscriptionPrices {
  Plus: Array<SubscriptionPrice>;
  Pro: Array<SubscriptionPrice>;
  trial_length?: number;
}

export interface NewSubscriptionCheckoutRequest {
  subscription_start_date?: string | null;
  customer_email: string | null;
  price_id: string;
  success_url: string;
  cancel_url: string;
  request_trial?: boolean;
  is_competition_winner?: boolean;
}

export interface VerifyRegistrationRequest {
  checkout_session_id: string;
  user_id: string;
}

export interface CheckoutSessionUserDetails {
  user_id: string | null;
  email: string;
  name: string;
}

// Message from Brevo API to filter for
export const alreadyExistsMessage = 'Already on the list';

export const extendedApiSlice = proApiSlice
  .enhanceEndpoints({ addTagTypes: ['Subscription'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      registration: builder.mutation<void, RegistrationRequest>({
        query: (request) => ({
          url: 'register/waitlist',
          method: 'POST',
          data: request,
          useDefaultErrorHandler: false,
        }),
      }),
      getPrices: builder.query<SubscriptionPrices, void>({
        query: () => ({
          url: 'register/prices',
          method: 'GET',
        }),
      }),
      createNewSubscriptionCheckout: builder.mutation<
        string | undefined,
        NewSubscriptionCheckoutRequest
      >({
        query: (request) => ({
          url: 'register/new-subscription-checkout-session',
          method: 'POST',
          data: request,
        }),
        transformResponse: (response: { redirect_url: string } | undefined) =>
          response?.redirect_url,
      }),
      confirmRegistration: builder.mutation<void, VerifyRegistrationRequest>({
        query: (request) => ({
          url: 'register/confirm_web_subscription',
          data: request,
          method: 'PUT',
          useDefaultErrorHandler: false,
        }),
        invalidatesTags: ['Subscription'],
      }),
      getCheckoutCustomerDetails: builder.query<
        CheckoutSessionUserDetails,
        string
      >({
        query: (checkout_session_id) => ({
          url: 'register/web_checkout_customer',
          params: { checkout_session_id },
          method: 'GET',
          useDefaultErrorHandler: false,
        }),
        transformResponse: (response: {
          status: string;
          result: CheckoutSessionUserDetails;
        }) => response?.result ?? response,
      }),
      triggerWebhooks: builder.mutation<void, void>({
        query: () => ({
          url: 'webhooks/trigger_pending',
          method: 'POST',
          useDefaultErrorHandler: false,
        }),
      }),
    }),
  });

export const {
  useRegistrationMutation,
  useGetPricesQuery,
  useCreateNewSubscriptionCheckoutMutation,
  useConfirmRegistrationMutation,
  useLazyGetCheckoutCustomerDetailsQuery,
  useGetCheckoutCustomerDetailsQuery,
  useTriggerWebhooksMutation,
} = extendedApiSlice;
