import React, { useEffect } from 'react';
import { LoginForm } from '../../features/users';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import LeftCircleIcon from '../../assets/icons/LeftCircle';
import fish from '../../assets/icons/fish.svg';
import { ACCOUNT_CREATION_ROUTE } from '../../app/appConstants';

export default function SignInPage(): React.JSX.Element {
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get('redirect') ?? '/';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  return (
    <div className='flex h-full min-h-screen w-full flex-col bg-gradient-to-b from-blue to-[#1B4C74] p-7 text-white'>
      <div className='relative mx-auto my-6 flex h-full max-h-[37rem] w-full max-w-sm flex-grow flex-col justify-between gap-4 text-center'>
        <div className='relative flex w-full justify-center text-center'>
          <div
            className='absolute bottom-0 left-0 top-0 grid cursor-pointer place-content-center '
            onClick={() => navigate(-1)}
          >
            <LeftCircleIcon className='text-white' />
          </div>
          <img src={fish} alt='swimbooker Logo' className='w-20' />
        </div>
        <div className='flex flex-col gap-2'>
          <div className='mb-7'>
            <h1 className='mb-10 mt-8 text-2xl font-semibold'>
              Great! Log-in or Create a Free Account to Continue
            </h1>
            <LoginForm redirectUrl={redirectUrl} className='text-center' />
          </div>
          <div className='mb-4 text-lg font-semibold'>
            <p>Don&apos;t have a swimbooker account?</p>
            <Link to={ACCOUNT_CREATION_ROUTE} className='uppercase underline'>
              Sign Up Here
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
