import { SVGProps } from 'react';

const MapPinSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='12.64'
    height='25.28'
    viewBox='0 0 12.64 25.28'
  >
    <path
      id='map-pin'
      d='M18.64,6.32a6.32,6.32,0,1,0-7.373,6.225V25.28h2.107V12.545A6.324,6.324,0,0,0,18.64,6.32Z'
      transform='translate(-6)'
      fill='#5ea8e5'
    />
  </svg>
);
export default MapPinSVG;
