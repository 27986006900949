import { SVGProps } from 'react';
const SvgPackage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g clipPath='url(#package_svg__a)' fill='#fff'>
      <path d='M15 4.325V4.32l4.491 2.012L24 4.64 12 .14 0 4.64l12 4.5 4.491-1.685L12 5.605V5.6l3-1.275ZM0 6.14v13.5l11.25 4.22v-13.5L0 6.14Zm6 13.55-3-1.125v-1.6l3 1.124v1.6ZM19.5 7.828v3.757l-3 1.125V8.953l-3.75 1.407v13.5L24 19.64V6.14l-4.5 1.688Z' />
    </g>
    <defs>
      <clipPath id='package_svg__a'>
        <path fill='#fff' d='M0 0h24v24H0z' />
      </clipPath>
    </defs>
  </svg>
);
export default SvgPackage;
