import { SVGProps } from 'react';
const SvgCoinsDark = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={23}
    height={21}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M7.916 6.72c4.102 0 7.167-1.622 7.167-3.072S12.018.577 7.916.577C3.815.577.75 2.198.75 3.648c0 1.45 3.065 3.071 7.166 3.071ZM7.916 19.005c.599 0 1.175-.035 1.723-.099a8.198 8.198 0 0 1-1.13-1.96c-.197.007-.394.011-.593.011-2.343 0-4.563-.464-6.25-1.307a8.114 8.114 0 0 1-.916-.533v.816c0 1.45 3.065 3.072 7.166 3.072ZM7.916 14.91l.063-.001a8.246 8.246 0 0 1 0-2.048l-.063.001c-2.343 0-4.563-.464-6.249-1.307a8.125 8.125 0 0 1-.917-.534v.817c0 1.45 3.065 3.072 7.166 3.072ZM7.916 10.814c.203 0 .404-.004.602-.012.318-.78.752-1.5 1.281-2.138-.613.067-1.243.103-1.883.103-2.343 0-4.563-.464-6.249-1.308a8.12 8.12 0 0 1-.917-.533v.817c0 1.45 3.065 3.071 7.166 3.071ZM10.988 10.491a6.112 6.112 0 0 0-.962 4.27c.1.703.32 1.368.64 1.974a6.18 6.18 0 0 0 1.301 1.686 6.12 6.12 0 0 0 4.14 1.608 6.15 6.15 0 0 0 6.143-6.144 6.15 6.15 0 0 0-7.171-6.057 6.155 6.155 0 0 0-4.09 2.663Z'
      fill='#292929'
    />
  </svg>
);
export default SvgCoinsDark;
