import { SVGProps } from 'react';
const SvgTicketDark = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={17}
    height={17}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M17 9.154 7.846 0H0v7.846L9.154 17 17 9.154ZM6.538 6.538a1.85 1.85 0 1 1-2.615-2.615 1.85 1.85 0 0 1 2.615 2.615Z'
      fill='#292929'
    />
  </svg>
);
export default SvgTicketDark;
