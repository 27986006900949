import { ReactNode } from 'react';
import { Button, Layout } from 'antd';
import {
  CloseOutlined,
  HeartFilled,
  HeartOutlined,
  ShareAltOutlined,
} from '@ant-design/icons';

import LeftChevron from '../../assets/media/icon-navigation-chevron_left_24px.svg';

const { Header, Content } = Layout;
interface BackButtonLayoutProps {
  children: ReactNode;
  className?: string;
  onBack?: () => void;
  showFavouriteButton?: boolean;
  onFavourite?: () => void;
  isFavourite?: boolean;
  showShareButton?: boolean;
  onShare?: () => void;
  overlapHeader?: boolean;
  useCrossIcon?: boolean;
}
export function BackButtonLayout({
  children,
  className = '',
  onBack = undefined,
  showFavouriteButton = false,
  onFavourite = undefined,
  isFavourite = false,
  showShareButton = false,
  onShare = undefined,
  overlapHeader = false,
  useCrossIcon = false,
}: BackButtonLayoutProps) {
  return (
    <Layout className={'profile_layout !bg-white ' + className}>
      <Header
        className={
          'profile_layout__header   px-6  py-4 !pt-7 md:px-10 ' +
          (overlapHeader ? 'absolute' : '')
        }
      >
        <Button
          className='profile_layout__header__back_btn bg-white'
          shape='circle'
          icon={
            useCrossIcon ? (
              <CloseOutlined />
            ) : (
              <img src={LeftChevron} className='text-base' alt='back button' />
            )
          }
          size='large'
          onClick={() => onBack?.()}
        />
        <div className='profile_layout__header__buttons'>
          {showShareButton && onShare && (
            <Button
              shape='circle'
              icon={<ShareAltOutlined />}
              size='large'
              onClick={onShare}
            />
          )}
          {showFavouriteButton && onFavourite && (
            <Button
              shape='circle'
              icon={
                isFavourite ? (
                  <HeartFilled className='sb-color-red' />
                ) : (
                  <HeartOutlined />
                )
              }
              size='large'
              onClick={onFavourite}
            />
          )}
        </div>
      </Header>
      <Content
        className={
          'profile_layout__content ' +
          (overlapHeader ? 'profile_layout__content--overlap-header' : '')
        }
      >
        {children}
      </Content>
    </Layout>
  );
}
