import { coreApiSlice } from '../../store/apiSlices/coreApiSlice';

export interface FisherySearchResult {
  id: string;
  name: string;
}

export const extendedCoreApiSlice = coreApiSlice
  .enhanceEndpoints({ addTagTypes: ['Fishery'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getBookableFisheries: builder.query<FisherySearchResult[], void>({
        query: () => ({
          url: 'search/term?query=england&ba=true',
          method: 'GET',
        }),
        transformResponse: (response: { hits: FisherySearchResult[] }) =>
          response?.hits ?? response,
        providesTags: (result, error) =>
          !error && result
            ? [
                ...result.map(({ id }) => ({ type: 'Fishery' as const, id })),
                { type: 'Fishery', id: 'LIST' },
              ]
            : [{ type: 'Fishery', id: 'LIST' }],
      }),
    }),
  });

export const { useGetBookableFisheriesQuery } = extendedCoreApiSlice;
