import './text-switch.css';
import React, { useEffect, useState } from 'react';
import {
  SwitchChangeEventHandler,
  SwitchClickEventHandler,
} from 'antd/es/switch';

export interface TextSwitchProps {
  className?: string;
  checked?: boolean;
  defaultChecked?: boolean;
  onChange?: SwitchChangeEventHandler;
  onClick?: SwitchClickEventHandler;
  checkedChildren: React.ReactNode;
  unCheckedChildren: React.ReactNode;
}

export default function TextSwitch({
  checkedChildren,
  unCheckedChildren,
  className = '',
  defaultChecked = false,
  checked,
  onChange,
  onClick,
}: TextSwitchProps): React.JSX.Element {
  const [localChecked, setLocalChecked] = useState<boolean>(
    Boolean(defaultChecked)
  );

  useEffect(() => {
    setLocalChecked(Boolean(checked));
  }, [checked]);

  const handleSwitchClick = (e: React.MouseEvent<HTMLButtonElement>) =>
    setLocalChecked((prev) => {
      if (onChange) {
        onChange(!prev, e);
      }
      if (onClick) {
        onClick(!prev, e);
      }
      return !prev;
    });

  return (
    <button
      className={
        'disable-webkit-tap text-switch relative grid w-fit cursor-pointer grid-cols-2 rounded-full border-[1px] border-grey-200 bg-grey-100 px-2 py-2 text-xs font-semibold leading-none text-grey-700 ' +
        className
      }
      onClick={handleSwitchClick}
    >
      <div
        className={
          'absolute w-1/2 rounded-full bg-blue-500 px-2 py-2 text-grey-100 ' +
          (localChecked ? 'text-switch--checked' : 'text-switch--unchecked')
        }
      >
        <div className='text-switch__handle--checked'>{checkedChildren}</div>
        <div className='text-switch__handle--unchecked'>
          {unCheckedChildren}
        </div>
      </div>
      <div className='w-full overflow-hidden '>
        <div className='w-full justify-end px-5'>{unCheckedChildren}</div>
      </div>
      <div className='w-full overflow-hidden '>
        <div className=' w-full justify-start px-5'>{checkedChildren}</div>
      </div>
    </button>
  );
}
