import { useEffect, useState } from 'react';

export const useCountdown = (
  timeInSeconds: number,
  countdownInterval = 1000
) => {
  const [countDown, setCountDown] = useState<number>(timeInSeconds);

  useEffect(() => {
    setCountDown(timeInSeconds);
  }, [timeInSeconds]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown((prev: number) => (prev > 0 ? prev - 1 : 0));
    }, countdownInterval);
    return () => clearInterval(interval);
  }, [countdownInterval]);

  return getCountdownReturnValues(countDown);
};

const getCountdownReturnValues = (timeRemaining: number) => {
  const days = Math.max(Math.floor(timeRemaining / (60 * 60 * 24)), 0);
  const hours = Math.max(
    Math.floor((timeRemaining % (60 * 60 * 24)) / (60 * 60)),
    0
  );
  const minutes = Math.max(Math.floor((timeRemaining % (60 * 60)) / 60), 0);
  const seconds = Math.max(Math.floor(timeRemaining % 60), 0);

  return [days, hours, minutes, seconds];
};
