import {
  CookieConsent,
  Cookies,
  getCookieConsentValue,
} from 'react-cookie-consent';
import React, { useEffect, useState } from 'react';
import { usePreventScroll } from '../../utils/hooks';
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import shieldImage from './assets/media/shieldGreen.png';
import './assets/css/cookieConsent.css';
import { PRIVACY_POLICY_URL } from '../../app/appConstants';

interface CookieConsentBannerProps {
  onClose?: () => void;
}

export const grantCookiesConsent = () => {
  window?.grantGTagConsent && window.grantGTagConsent();
  window?.grantFBPixelConsent && window.grantFBPixelConsent();
  window?.loadHeap && window.loadHeap();
  window?.loadHotjar && window.loadHotjar();
};

function CookieConsentBanner({
  onClose = () => {},
}: CookieConsentBannerProps): React.JSX.Element {
  const cookieExpiry = 182; /* ~6 months */

  /**
   * From https://github.com/Mastermindzh/react-cookie-consent/blob/master/src/CookieConsent.tsx
   */
  const setConsentCookie = (cookieValue: boolean) => {
    const cookieSecurity = window.location
      ? window.location.protocol === 'https:'
      : true;
    const cookieOptions = {
      expires: cookieExpiry,
      sameSite: 'lax',
      secure: cookieSecurity,
    };
    Cookies.set('CookieConsent', cookieValue, cookieOptions);
  };
  const decline = () => {
    setConsentCookie(false);
  };

  const onCookieSet = () => {
    setIsOpen(false);
    onClose();
  };

  // Only check once on page load (not on resize) for performance reasons
  const isDesktop = Boolean(window?.innerWidth > 576);
  const showOverlay = !isDesktop;
  const [isOpen, setIsOpen] = useState(!getCookieConsentValue());
  const cookieDomain = process.env?.REACT_APP_COOKIE_DOMAIN;

  // Prevent background app scroll when drawer is open
  usePreventScroll('CookieConsentBanner', isOpen && showOverlay);

  /**
   * Grant cookie permissions if consent is already given on page load
   */
  useEffect(() => {
    if (getCookieConsentValue()) {
      grantCookiesConsent();
    }
  }, []);

  return (
    <CookieConsent
      extraCookieOptions={{
        ...(cookieDomain ? { domain: cookieDomain } : {}),
      }}
      location='bottom'
      visible={isOpen ? 'byCookieValue' : 'hidden'}
      buttonText='Accept All'
      declineButtonText='Only Use Essential Cookies'
      enableDeclineButton
      flipButtons
      style={{
        background: '#F5F5F5',
        fontSize: '16px',
        fontWeight: 500,
        fontFamily: '"gilroy", sans-serif',
        color: 'rgb(0, 0, 0, 0.85)',
      }}
      buttonClasses='cookie-accept-btn cookie-btn w-full !rounded-lg !bg-blue !px-2 !py-4 text-base !text-white hover:!bg-green-dark'
      buttonWrapperClasses='cookie-btn-wrapper'
      declineButtonClasses='cookie-decline-btn cookie-btn rounded-lg !bg-transparent !p-2 text-base !text-black underline hover:!text-blue'
      containerClasses='CookieConsent'
      overlayStyle={{
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 11001,
      }}
      onAccept={() => {
        grantCookiesConsent();
        onCookieSet();
      }}
      onDecline={() => {
        onCookieSet();
      }}
      onOverlayClick={() => {
        decline();
        onCookieSet();
      }}
      expires={cookieExpiry}
      overlay={showOverlay}
    >
      <div className='cookie_consent__badge'>
        <img src={shieldImage} alt='shield icon' />
      </div>
      <div className='cookie_consent__close'>
        <Button
          icon={<CloseOutlined className='!text-xs' />}
          className='grid place-content-center'
          shape='circle'
          type='default'
          size='small'
          onClick={() => {
            decline();
            onCookieSet();
          }}
        />
      </div>
      <div className='cookie_consent__content'>
        <h2 className='cookie_consent__title mb-3 text-2xl font-semibold sm:text-lg'>
          We Respect Your Privacy.
        </h2>
        <span className='cookie_consent__text text-lg font-normal sm:text-base'>
          We{' '}
          <a href={PRIVACY_POLICY_URL} className='text-sky-500 underline'>
            use cookies
          </a>{' '}
          to operate this website and provide the best possible user experience.
        </span>
      </div>
    </CookieConsent>
  );
}

export default CookieConsentBanner;
