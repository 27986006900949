import { SVGProps } from 'react';

const PowerSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='19.18'
    height='27.4'
    viewBox='0 0 19.18 27.4'
  >
    <path
      style={{ fill: 'currentColor' }}
      id='so-power'
      d='M14.59,12.96h9.59L9.11,29.4l4.11-12.33H5L15.96,2Z'
      transform='translate(-5 -2)'
      fill='#2772af'
    />
  </svg>
);
export default PowerSVG;
