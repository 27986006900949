import React from 'react';
import { useFlags } from 'flagsmith/react';
import { FeatureFlagProtectionWrapperProps } from '../index';

export default function SBPlusFlagWrapper({
  children,
}: FeatureFlagProtectionWrapperProps): React.JSX.Element | null {
  const flags = useFlags(['sb_pro']);

  return flags.sb_pro?.enabled ? (
    children
  ) : (
    <div className='font-large p-5 text-center'>
      <h1 className='mb-4 mt-5 w-full text-center text-5xl font-bold'>
        <span className='text-blue'>In:</span>Session
      </h1>
      <div className='flex flex-row items-center justify-center gap-3 rounded-md bg-neutral-800 py-6 text-lg text-white'>
        Coming Soon!
      </div>
    </div>
  );
}
