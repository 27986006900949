import { SVGProps } from 'react';
const SvgStore = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={25}
    height={25}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M21.902 6.36a3.229 3.229 0 0 1-2.463 4.008 3.158 3.158 0 0 1-3.71-2.845l-.044-.52a3.156 3.156 0 0 1-3.142 3.414h-.086a3.157 3.157 0 0 1-3.145-3.418l-.032.39a3.251 3.251 0 0 1-2.948 3.022 3.158 3.158 0 0 1-3.268-3.915l.906-3.624a1.042 1.042 0 0 1 1.01-.789h15.04a1.041 1.041 0 0 1 1.01.789l.872 3.487Zm-3.026 6.14a5.228 5.228 0 0 0 1.957-.384v9.759a1.041 1.041 0 0 1-1.041 1.042H5.208a1.041 1.041 0 0 1-1.041-1.042v-9.76a5.12 5.12 0 0 0 5.111-.71 5.344 5.344 0 0 0 3.266 1.095 5.236 5.236 0 0 0 3.178-1.096 5.184 5.184 0 0 0 3.154 1.096Zm-4.293 5.208a2.083 2.083 0 1 0-4.166 0v3.125h4.166v-3.125Z'
      fill='#fff'
    />
  </svg>
);
export default SvgStore;
