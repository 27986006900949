import { coreApiSlice } from './coreApiSlice';

export interface Reviews {
  profile_image?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  comment?: string | null;
  years_angling?: number;
  personal_best?: {
    species: string;
    weight: number;
    unit: string;
  };
}

export interface FisheryDetails {
  address: {
    city: string;
    line1: string;
    line2: string;
    postcode: string;
    location: {
      lat: number;
      lng: number;
    };
  };
  rules: string;
  venue_name: string;
  media: {
    virtual_experience: string | null;
    sb_diaries: string | null;
    sb_flythrough: string | null;
  };
  what3words: {
    words: string;
  };
  contact: {
    primary_contact: string;
  };
  rating: {
    score: number;
    total_reviews: number;
  };
  public_id: number;
  images: {
    sitemap?: string;
    primary: string;
  };
  lakes: {
    lake_id: string;
    name: string;
  }[];
  fishery_id: string;
  comments: Reviews[];
}

export const extendedApiSlice = coreApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fisheryDetails: builder.query<FisheryDetails, string | undefined>({
      query: (fisheryId) => ({
        url: 'fishery',
        method: 'GET',
        useDefaultErrorHandler: false,
        params: { id: fisheryId },
      }),
      transformResponse: (response: {
        status: string;
        result: FisheryDetails;
      }) => response?.result ?? response,
    }),
  }),
});

export const { useFisheryDetailsQuery } = extendedApiSlice;
