import { Space } from 'antd';
import ReviewScoreVisualisation from './ReviewScoreVisualisation';

import '../assets/reviews_header.css';

/**
 * Full width banner for a fishery with score visualisation on the left and number of reviews on the right
 */
interface ReviewsHeaderProps {
  score?: number;
  numReviews?: number;
}
export default function ReviewsHeader({
  score = 0,
  numReviews = 0,
}: ReviewsHeaderProps) {
  return (
    <div className='reviews-header mb-[15px]'>
      <Space direction='horizontal'>
        <ReviewScoreVisualisation score={score} />
        <span className='text-lg font-bold'>{score}/5</span>
      </Space>
      <span className=' text-[21px] text-blue'>
        {numReviews} {`review${numReviews !== 1 ? 's' : ''}`}
      </span>
    </div>
  );
}
