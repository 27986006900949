import React, { useEffect } from 'react';
import LeftCircleIcon from '../../assets/icons/LeftCircle';
import fish from '../../assets/icons/fish.svg';
import { CreateAccountForm } from '../../features/users';
import { useNavigate } from 'react-router-dom';

export default function CreateAccountPage(): React.JSX.Element {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='h-full min-h-screen w-full bg-gradient-to-b from-blue to-[#1B4C74] p-7 text-white'>
      <div className='relative mx-auto my-6 max-w-sm text-center'>
        <div className='relative flex w-full justify-center text-center'>
          <div
            className='absolute bottom-0 left-0 top-0 grid cursor-pointer place-content-center '
            onClick={() => navigate(-1)}
          >
            <LeftCircleIcon className='text-white' />
          </div>
          <img src={fish} alt='swimbooker Logo' className='w-20' />
        </div>
        <div className='my-10'>
          <CreateAccountForm />
        </div>
      </div>
    </div>
  );
}
