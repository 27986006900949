import React, { useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import noActivity from '../assets/images/no-activity-feed.svg';
import { Button } from 'antd';
import { FloatingFooter } from '../../../common/components';
import Refresh from '../assets/icons/RefreshSVG';
import marker from '../assets/images/marker.svg';
import dayjs from 'dayjs';

import noImage from '../../../assets/media/no-photo.jpg';
import PlaceholderUser from '../assets/images/placeholder_user.png';

import '../assets/css/activity-feed.css';

import { useGetCurrentFisheryId } from '../../users/usersSlice';
import { useActivityFeedQuery } from '../../../store/apiSlices/ActivityFeedSlice';

export const ActivityFeed = (): React.JSX.Element => {
  const [activityOpen, setActivityOpen] = useState(false);
  const { data: fisheryId } = useGetCurrentFisheryId();

  const { data: activityFeed, refetch } = useActivityFeedQuery(fisheryId || '');

  const drawerContent = (
    <>
      <div
        className={`rounded-t-2xl bg-white p-4 ${activityOpen && 'mb-2 pb-6'}`}
      >
        <div
          className='flex cursor-pointer justify-center'
          onClick={() => {
            setActivityOpen(true);
          }}
        >
          <div className='w-full'>
            {!activityOpen && (
              <div className='mb-4 flex justify-center'>
                <div className='h-[3px] w-[90px] bg-[#707070] ' />
              </div>
            )}
            <div className='relative border-b border-solid border-[#d9d9d9] pb-3'>
              <div className='flex justify-between text-xl font-semibold'>
                <div>
                  <span className='font-montserrat font-black'>
                    <span className='text-blue'>In</span>
                    <span>Session </span>
                  </span>
                  Activity Feed
                </div>
                {activityOpen && (
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setActivityOpen(false);
                    }}
                  >
                    <CloseOutlined />
                  </div>
                )}
              </div>
              <div className='mb-4'>
                See what other
                <span className='font-montserrat font-black'>
                  {' '}
                  InSession
                </span>{' '}
                anglers are getting up to!
              </div>
              {activityOpen && (
                <Button className='absolute bottom-0 left-1/2 translate-x-[-50%] translate-y-[50%] transform bg-blue font-semibold !text-white'>
                  <div className='flex items-center' onClick={refetch}>
                    <Refresh className='mr-2' /> Get Latest Posts
                  </div>
                </Button>
              )}
            </div>
          </div>
        </div>
        {!activityFeed?.length && activityOpen && (
          <div className='mt-4 flex flex-col items-center px-20 '>
            <img src={noActivity} alt='no-activity' className='mb-2 mt-6' />
            <div className='mb-5 text-center font-semibold'>
              Looks a bit quiet in here…
            </div>
            <div className='mb-4 text-center font-light'>
              Tap below to refresh the feed for up-to-date results!
            </div>
            <Button
              className='rounded-[10px] !border-[#91AD8B] bg-[#91AD8B] px-6 font-semibold !text-white'
              onClick={refetch}
            >
              Refresh Feed
            </Button>
          </div>
        )}
      </div>
      {activityOpen &&
        activityFeed?.length &&
        activityFeed?.map((activity, index) => (
          <div className='mt-1 bg-white p-4' key={index}>
            <div className='mb-4 flex items-center'>
              <div className='mr-3'>
                <img
                  className='aspect-square w-[52px] rounded-full'
                  src={activity.user_image || PlaceholderUser}
                  alt='profile-pic'
                />
              </div>
              <div className='w-full'>
                <div className='text-base'>
                  {activity?.fish_caught_date ? (
                    <>
                      <span className='font-semibold'>{activity?.name}</span>{' '}
                      <span>caught a fish!</span>
                    </>
                  ) : (
                    <span className='font-semibold'>
                      {activity?.name} is now{' '}
                      <span className='font-montserrat font-black'>
                        <span className='text-blue'>In</span>
                        <span>Session </span>
                      </span>
                    </span>
                  )}
                </div>
                <div className='flex justify-between'>
                  <div className='flex items-center'>
                    <img
                      src={marker}
                      alt='marker'
                      className='mr-2'
                      width={12}
                    />
                    {activity?.fishery}
                  </div>
                  <div>
                    {activity?.fish_caught_date
                      ? dayjs(activity.fish_caught_date).fromNow()
                      : dayjs(activity.upload_date).fromNow()}
                  </div>
                </div>
              </div>
            </div>
            {activity?.fish_caught_date && (
              <div className='mb-3 text-base font-medium'>
                {`${activity.fish_weight_lb} ${activity.fish_species} | ${activity.lake} - ${activity.fishery}`}
              </div>
            )}
            <div className='mb-4' />
            {activity.fish_caught_date && (
              <img
                src={
                  activity.image_upload > '' ? activity.image_upload : noImage
                }
                alt=''
                className='mb-4 rounded-2xl'
              />
            )}
            <div
              className='flex cursor-pointer justify-end text-xs underline'
              onClick={() =>
                window.open(
                  `${process.env.REACT_APP_CORE_SITE_URL}/fishery/${activity.fishery_id}`,
                  '_blank'
                )
              }
            >
              See Fishery Profile
            </div>
          </div>
        ))}
    </>
  );
  return (
    <>
      <div
        onClick={() => setActivityOpen(true)}
        className='home-page-section-container relative flex cursor-pointer items-center justify-evenly'
      >
        <div className='relative'>
          <div className='flex justify-between text-xl font-semibold'>
            <div>
              <span className='font-montserrat font-black'>
                <span className='text-blue'>In</span>
                <span>Session </span>
              </span>
              Activity Feed
            </div>
          </div>
          <div className=''>
            See what other
            <span className='font-montserrat font-black'> InSession</span>{' '}
            anglers are getting up to!
          </div>
        </div>
      </div>
      <FloatingFooter
        maxWidth='44rem'
        footerRadius
        drawerOnTop
        className='flex w-[99vw] flex-col !border-0 !bg-neutral-100 !p-0 !shadow-none'
        drawerClassName='!bg-neutral-100 !text-black !p-0'
        drawerContainerClassName='activityfeed-drawer'
        limitHeight
        isActivityDrawer
        drawerContents={drawerContent}
        isDrawerOpen={activityOpen}
        onDrawerClose={() => setActivityOpen(false)}
      >
        {!activityOpen && drawerContent}
      </FloatingFooter>
    </>
  );
};
