import React, { useEffect, useMemo } from 'react';
import fish from '../../assets/icons/fish.svg';
import { CreateAccountForm } from '../../features/users';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGetCheckoutCustomerDetailsQuery } from '../../features/registration/registrationSlice';
import { Spin } from 'antd';
import { SUBSCRIPTION_SIGN_UP_ROUTE } from '../../app/appConstants';
import { useIsLoggedInQuery } from '../../features/users/usersSlice';
import { splitFullName } from '../../utils/user-utils';

export default function SubscriptionNewUserPage(): React.JSX.Element {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const subscriptionLevel = searchParams.get('plan') ?? 'Plus';
  const sessionId = searchParams.get('sessionId') ?? '';
  const { data: isLoggedIn, isFetching: loadingLoggedIn } =
    useIsLoggedInQuery();

  const { data: userData, isFetching: loading } =
    useGetCheckoutCustomerDetailsQuery(sessionId, { skip: !sessionId });

  const [forename, surname] = useMemo(
    () => splitFullName(userData?.name),
    [userData?.name]
  );

  useEffect(() => {
    if (loading || !sessionId) {
      return;
    }
    if (userData?.user_id) {
      // User already has an account -- go to success page
      navigate(
        `${SUBSCRIPTION_SIGN_UP_ROUTE}/success?plan=${encodeURIComponent(
          subscriptionLevel
        )}&sessionId=${encodeURIComponent(
          sessionId
        )}&userId=${encodeURIComponent(userData?.user_id)}`
      );
    }
  });

  useEffect(() => {
    if (loading || loadingLoggedIn || !sessionId || !isLoggedIn) {
      return;
    }
    navigate(
      `${SUBSCRIPTION_SIGN_UP_ROUTE}/success?plan=${encodeURIComponent(
        subscriptionLevel
      )}&sessionId=${encodeURIComponent(sessionId)}`
    );
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='h-full min-h-screen w-full bg-gradient-to-b from-blue to-[#1B4C74] p-7 text-white'>
      <div className='relative mx-auto my-6 max-w-sm text-center'>
        <div className='relative flex w-full flex-col items-center justify-center text-center'>
          <img src={fish} alt='swimbooker Logo' className='w-20' />
          <h1 className='text-xl font-semibold'>
            Great! One final step. Please finish adding your account details to
            continue!
          </h1>
        </div>
        <div className='my-10'>
          {loading ? (
            <Spin />
          ) : (
            <CreateAccountForm
              defaultValues={{
                email: userData?.email,
                first_name: forename,
                last_name: surname,
              }}
              fixedEmail
              successRedirect={null}
            />
          )}
        </div>
      </div>
    </div>
  );
}
