import { SVGProps } from 'react';

const Refresh = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 13 13'
    {...props}
  >
    <g fill='currentColor' data-name='Group 11'>
      <path
        d='M12.526.685a.518.518 0 0 0-.743 0L10.712 1.75A6.4 6.4 0 0 0 8.687.458a6.231 6.231 0 0 0-2.345-.461 6.1 6.1 0 0 0-3.964 1.378A6.44 6.44 0 0 0 .149 4.962v.058a.268.268 0 0 0 .264.264h1.643a.248.248 0 0 0 .248-.19 9.982 9.982 0 0 1 .438-.966 4.211 4.211 0 0 1 3.6-2.015 4.1 4.1 0 0 1 2.882 1.131l-1.14 1.139a.528.528 0 0 0 .372.9h3.7a.536.536 0 0 0 .528-.528V1.057a.507.507 0 0 0-.158-.372Z'
        data-name='Path 3'
      />
      <path
        d='M12.212 7.397h-1.586a.247.247 0 0 0-.248.19 9.99 9.99 0 0 1-.438.966 4.211 4.211 0 0 1-3.6 2.015 4.121 4.121 0 0 1-1.544-.3 4.262 4.262 0 0 1-1.329-.842L4.6 8.297a.529.529 0 0 0-.372-.9h-3.7a.508.508 0 0 0-.372.157.508.508 0 0 0-.156.373v3.7a.508.508 0 0 0 .157.372.509.509 0 0 0 .372.157.509.509 0 0 0 .371-.159l1.065-1.065a6.3 6.3 0 0 0 2.011 1.3 6.183 6.183 0 0 0 2.333.454 6.053 6.053 0 0 0 3.947-1.375 6.417 6.417 0 0 0 2.213-3.588.142.142 0 0 0 .008-.058.268.268 0 0 0-.265-.264Z'
        data-name='Path 4'
      />
    </g>
  </svg>
);
export default Refresh;
