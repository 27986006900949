import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../utils';

export const coreApiSlice = createApi({
  baseQuery: axiosBaseQuery({
    baseURL: `${process.env.REACT_APP_CORE_URL}`,
  }),
  reducerPath: 'coreApi',
  tagTypes: [],
  endpoints: () => ({}),
});
