import React from 'react';

import './rounded-tag.css';

export interface RoundedTagProps
  extends Omit<React.HTMLProps<HTMLDivElement>, 'size'> {
  icon?: React.ReactNode;
  size?: 'small' | 'medium' | 'large';
  colourName?: string;
}

function RoundedTag({
  icon = undefined,
  size = 'medium',
  children,
  className = '',
  ...htmlProps
}: RoundedTagProps): React.JSX.Element {
  let sizeStyle: string;
  switch (size) {
    case 'small':
      sizeStyle = 'text-sm pb-1.5 pl-3 pr-3 pt-1.5 ';
      break;
    case 'medium':
      sizeStyle = 'text-sm pb-2 pl-4 pr-4 pt-2 ';
      break;
    case 'large':
      sizeStyle = 'text-base pb-2 pl-5 pr-5 pt-2 ';
  }

  return (
    <div
      className={`rounded-tag flex w-fit flex-row items-center gap-1 rounded-full ${sizeStyle} ${className}`}
      {...htmlProps}
    >
      {icon ? <span className='-ml-0.5 mr-1'>{icon}</span> : undefined}
      {children}
    </div>
  );
}
export default RoundedTag;
