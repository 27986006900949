import { AppRoute, SecurityLevel } from '../../app/utils/secureRoutes';
import { SUBSCRIPTION_SIGN_UP_ROUTE } from '../../app/appConstants';
import SubscriptionFeaturesPage from './SubscriptionFeatures';
import SubscriptionSuccessPage from './SubscriptionSuccess';
import { Navigate } from 'react-router-dom';
import SubscriptionPreSuccessPage from './SubscriptionPreSuccess';
import SubscriptionNewUserPage from './SubscriptionNewUser';
import SubscriptionGiveawaySignUpPage from './SubscriptionGiveawaySignUp';

export const routes: AppRoute[] = [
  {
    path: `${SUBSCRIPTION_SIGN_UP_ROUTE}`,
    element: <SubscriptionFeaturesPage />,
    security: {
      level: SecurityLevel.NotLoggedInOrNoSubscription,
    },
  },
  {
    path: `${SUBSCRIPTION_SIGN_UP_ROUTE}/giveaway-winner`,
    element: <SubscriptionGiveawaySignUpPage />,
    security: {
      level: SecurityLevel.NotLoggedInOrNoSubscription,
    },
  },
  {
    path: `${SUBSCRIPTION_SIGN_UP_ROUTE}/pre-success`,
    element: <SubscriptionPreSuccessPage />,
    security: {
      level: SecurityLevel.NotLoggedInOrNoSubscription,
    },
  },
  {
    path: `${SUBSCRIPTION_SIGN_UP_ROUTE}/new-user`,
    element: <SubscriptionNewUserPage />,
    security: {
      level:
        SecurityLevel.NotLoggedInOrNoSubscription /* Prevents redirect upon account creation and login */,
    },
  },
  {
    path: `${SUBSCRIPTION_SIGN_UP_ROUTE}/success`,
    element: <SubscriptionSuccessPage />,
    security: {
      level: SecurityLevel.Public,
    },
  },
  {
    path: `${SUBSCRIPTION_SIGN_UP_ROUTE}/:other?`,
    element: <Navigate to={`${SUBSCRIPTION_SIGN_UP_ROUTE}`} replace />,
    security: {
      level: SecurityLevel.Public,
    },
  },
];
