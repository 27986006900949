import { SVGProps } from 'react';
const SvgHookDark = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={22}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M12.855 0a2.581 2.581 0 0 0-2.578 2.578c0 1.12.719 2.076 1.72 2.43v10.418A4.86 4.86 0 0 1 7.14 20.28a4.86 4.86 0 0 1-4.856-4.855h3.008L.566 10.699v4.727A6.582 6.582 0 0 0 7.141 22a6.582 6.582 0 0 0 6.574-6.574V5.008c1-.354 1.719-1.31 1.719-2.43A2.581 2.581 0 0 0 12.855 0Zm0 3.438a.86.86 0 0 1 0-1.72.86.86 0 0 1 0 1.72Z'
      fill='#292929'
    />
  </svg>
);
export default SvgHookDark;
