import { SVGProps } from 'react';
const SvgBag = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={26}
    height={26}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g clipPath='url(#bag_svg__a)'>
      <path
        d='M22.281 8.301a.929.929 0 0 0-.928-.872h-3.715V4.643a4.643 4.643 0 1 0-9.285 0v2.786H4.638c-.492-.001-.899.381-.928.872l-.817 14.755A2.786 2.786 0 0 0 5.678 26h14.644a2.786 2.786 0 0 0 2.786-2.944L22.28 8.301ZM10.21 4.643a2.786 2.786 0 1 1 5.571 0v2.786H10.21V4.643Z'
        fill='#fff'
      />
    </g>
    <defs>
      <clipPath id='bag_svg__a'>
        <path fill='#fff' d='M0 0h26v26H0z' />
      </clipPath>
    </defs>
  </svg>
);
export default SvgBag;
