import React, { ReactNode, useState } from 'react';
import { Drawer, Menu } from 'antd';
import Icon from '@ant-design/icons';

import { Link } from 'react-router-dom';

import MenuIcon from '../assets/images/so-menu.svg';
import CloseIcon from '../assets/images/so-close-circle.svg';
import BackIcon from '../../../assets/media/icon-navigation-chevron_left_24px.svg';
import PowerSVGIcon from '../assets/icons/PowerSVG';
import DocumentSVG from '../assets/icons/DocumentSVG';
import AddCircleSVG from '../assets/icons/AddCircleSVG';
import ChatSVG from '../assets/icons/ChatSVG';
import StoreSVG from '../assets/icons/StoreSVG';
import MapPinSVG from '../assets/icons/MapPinSVG';
import WarningSVG from '../assets/icons/WarningSVG';

import '../assets/css/side-bar.css';

const MenuDrawer = ({
  isOpen,
  setIsOpen,
  children,
}: {
  isOpen: boolean;
  children: ReactNode;
  setIsOpen: (args: boolean) => void;
}) => {
  // Prevent background app scroll when drawer is open
  // usePreventScroll('MenuDrawer', isOpen);

  return (
    <Drawer
      title={null}
      closable={false}
      placement='right'
      onClose={() => setIsOpen(false)}
      open={isOpen}
      width='min(100%, 30rem)'
      headerStyle={{ display: 'none' }}
      className='drawer-menu-mobile'
    >
      <div className='drawer-menu-mobile__header flex justify-between'>
        <div className='flex-3 p-4'>
          <img
            src={CloseIcon}
            alt='Close Icon'
            className='cursor-pointer'
            onClick={() => setIsOpen(false)}
          />
        </div>
        <div className='drawer-menu-mobile__right flex flex-7 items-center justify-around p-4 sm:flex-5'>
          <img src={MenuIcon} alt='Menu Icon' />
          <span className='text-base font-semibold'>InSession Menu</span>
        </div>
      </div>
      <div className='drawer-menu-mobile__contents mt-8'>{children}</div>
    </Drawer>
  );
};

const DefaultMenuDrawer = ({
  isOpen,
  setIsOpen,
  setReportDrawerConfigs,
  setQuickLinksOpen,
}: {
  isOpen: boolean;
  setIsOpen: (args: boolean) => void;
  setReportDrawerConfigs: React.Dispatch<
    React.SetStateAction<{ isOpen: boolean; isCatchReport: boolean }>
  >;
  setQuickLinksOpen: (args: boolean) => void;
}) => {
  return (
    <MenuDrawer isOpen={isOpen} setIsOpen={setIsOpen}>
      <Menu selectedKeys={[]}>
        <Menu.Item key='quick-links'>
          <Link
            className='flex items-center font-semibold'
            to='/'
            onClick={() => {
              setQuickLinksOpen(true);
              setIsOpen(false);
            }}
          >
            <Icon className='in-menu-icon' component={() => <PowerSVGIcon />} />
            Fishery Quicklinks
          </Link>
        </Menu.Item>
        <Menu.Item key='catch-report'>
          <Link
            className='flex items-center font-semibold'
            onClick={() => {
              setReportDrawerConfigs({ isOpen: true, isCatchReport: true });
              setIsOpen(false);
            }}
            to='/'
          >
            <Icon className='in-menu-icon' component={() => <AddCircleSVG />} />
            Add Catch Report
          </Link>
        </Menu.Item>
        <Menu.Item key='session-notes'>
          <Link
            className='flex items-center font-semibold'
            // onClick={() => {
            //   setReportDrawerConfigs({ isOpen: true, isCatchReport: false });
            //   setIsOpen(false);
            // }}
            to='/session-notes'
          >
            <Icon className='in-menu-icon' component={() => <DocumentSVG />} />
            Session Notes
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link
            className='flex items-center font-semibold'
            onClick={() => {
              setIsOpen(false);
            }}
            to='/'
          >
            <Icon className='in-menu-icon' component={() => <DocumentSVG />} />
            Leave Feedback
          </Link>
        </Menu.Item>

        <div className='menu-divider' />
        <Menu.Item className='pointer-events-none' key='coming-soon'>
          <Link className='flex items-center p-4 font-semibold' to='/'>
            Coming Soon
          </Link>
        </Menu.Item>
        <Menu.Item className='pointer-events-none' key='chat'>
          <Link className='flex items-center' to='/'>
            <Icon
              className='in-menu-icon coming-soon'
              component={() => <ChatSVG />}
            />
            <span className='coming-soon-options'>InSession Chat</span>
          </Link>
        </Menu.Item>
        <Menu.Item className='pointer-events-none' key='report-issue'>
          <Link className='flex items-center' to='/'>
            <Icon
              className='in-menu-icon coming-soon'
              component={() => <WarningSVG />}
            />
            <span className='coming-soon-options'>Report Issue To Fishery</span>
          </Link>
        </Menu.Item>
        <Menu.Item className='pointer-events-none' key='product-services'>
          <Link className='flex items-center' to='/'>
            <Icon
              className='in-menu-icon coming-soon'
              component={() => <StoreSVG />}
            />
            <span className='coming-soon-options'>
              Local Products & Services
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item className='pointer-events-none'>
          <Link className='flex items-center' to='/'>
            <Icon
              className='in-menu-icon coming-soon'
              component={() => <MapPinSVG />}
            />
            <span className='coming-soon-options'>Swim Mapping</span>
          </Link>
        </Menu.Item>
      </Menu>
    </MenuDrawer>
  );
};

const NavBar = ({
  setReportDrawerConfigs,
  quickLinksOpen,
  setQuickLinksOpen,
}: {
  setReportDrawerConfigs: React.Dispatch<
    React.SetStateAction<{ isOpen: boolean; isCatchReport: boolean }>
  >;
  quickLinksOpen: boolean;
  setQuickLinksOpen: React.Dispatch<React.SetStateAction<boolean>>;
}): React.JSX.Element => {
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);

  return (
    <>
      <div className='relative z-10 mb-8 flex justify-between'>
        {quickLinksOpen ? (
          <div className='rounded-full bg-white'>
            <img
              src={BackIcon}
              alt='Back Icon'
              className='cursor-pointer'
              onClick={() => setQuickLinksOpen(false)}
            />
          </div>
        ) : (
          <Link to={`${process.env.REACT_APP_CORE_SITE_URL}/user/profile`}>
            <img src={CloseIcon} alt='Close Icon' className='cursor-pointer' />
          </Link>
        )}

        <img
          src={MenuIcon}
          alt='menu'
          className='cursor-pointer'
          onClick={() => setIsSideBarOpen(true)}
        />
      </div>
      <DefaultMenuDrawer
        isOpen={isSideBarOpen}
        setIsOpen={setIsSideBarOpen}
        setReportDrawerConfigs={setReportDrawerConfigs}
        setQuickLinksOpen={setQuickLinksOpen}
      />
    </>
  );
};

export default NavBar;
