import { SVGProps } from 'react';

const WarningSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='27.4'
    height='27.4'
    viewBox='0 0 27.4 27.4'
  >
    <path
      style={{ fill: 'currentColor' }}
      id='so-warning-circle'
      d='M15.7,2A13.7,13.7,0,1,0,29.4,15.7,13.7,13.7,0,0,0,15.7,2ZM14.33,8.85a1.37,1.37,0,1,1,2.74,0v8.22a1.37,1.37,0,0,1-2.74,0ZM15.7,25.29a2.055,2.055,0,1,1,2.055-2.055A2.055,2.055,0,0,1,15.7,25.29Z'
      transform='translate(-2 -2)'
      fill='#5ea8e5'
    />
  </svg>
);
export default WarningSVG;
