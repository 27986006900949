import { SVGProps } from 'react';
const SvgPercent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={21}
    height={21}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g clipPath='url(#percent_svg__a)'>
      <path
        d='M4.337 10.063a4.334 4.334 0 1 0-.007-8.668 4.334 4.334 0 0 0 .007 8.668Zm-1.21-5.546a1.712 1.712 0 1 1 2.421 2.421 1.712 1.712 0 0 1-2.421-2.421Zm13.536 6.418a4.337 4.337 0 1 0 3.067 1.27 4.308 4.308 0 0 0-3.067-1.27Zm1.21 5.548a1.712 1.712 0 1 1-2.412-2.43 1.712 1.712 0 0 1 2.412 2.43Zm1.353-14.709a1.311 1.311 0 0 1 0 1.857L3.63 19.226a1.313 1.313 0 0 1-1.857-1.857L17.37 1.774a1.313 1.313 0 0 1 1.857 0Z'
        fill='#fff'
      />
    </g>
    <defs>
      <clipPath id='percent_svg__a'>
        <path fill='#fff' d='M0 0h21v21H0z' />
      </clipPath>
    </defs>
  </svg>
);
export default SvgPercent;
