import React from 'react';

import { Row } from 'antd';
import { SessionNotes } from '../../../store/apiSlices/SessionNotesSlice';

import Camera from '../assets/images/camera-icon.svg';
import Eye from '../assets/images/eye.svg';
import dayjs from 'dayjs';

const SessionNote = ({
  noteDetails,
  setIsEditingNotes,
  setNoteDetailsToEdit,
}: {
  noteDetails: SessionNotes;
  setIsEditingNotes: React.Dispatch<React.SetStateAction<boolean>>;
  setNoteDetailsToEdit: React.Dispatch<
    React.SetStateAction<null | SessionNotes>
  >;
}) => (
  <div
    className='session_detail_box_shadow mb-2.5 rounded-[10px] bg-white p-2.5'
    key={noteDetails.id}
  >
    <div className='mb-4 flex items-center justify-between'>
      <Row>
        <div className='mr-2.5 flex flex-col items-center rounded-[8px] bg-[#e0e0e0] px-[10px]  py-[5px] font-bold'>
          <div className='text-[9px] font-medium'>
            {dayjs(noteDetails?.note_date).format('ddd').toUpperCase()}
          </div>
          <div className='font-bold'>
            {dayjs(noteDetails?.note_date).format('D')}
          </div>
        </div>
        <div>
          <div className='font-bold'>{noteDetails.title}</div>
          <div className='flex items-center font-medium text-[#8B8B8B]'>
            <span className='mr-[5px]'>
              {dayjs(noteDetails?.note_date).format('HH:mm')}
            </span>
            {noteDetails?.image_upload && <img src={Camera} alt='camera' />}
          </div>
        </div>
      </Row>
      <div
        className='cursor-pointer'
        onClick={() => {
          setIsEditingNotes(true);
          setNoteDetailsToEdit(noteDetails);
        }}
      >
        <img src={Eye} alt='eye' />
      </div>
    </div>
    <div
      className='gMedium'
      style={{
        color: '#8B8B8B',
      }}
    >
      {noteDetails.detail}
    </div>
  </div>
);

export default SessionNote;
