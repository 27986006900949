export const splitFullName = (fullName: string | undefined | null) => {
  const HONORIFIC_PATTERN = /([Mm]([rR]|[rR][sS]|[xX]|[iI][sS][sS]|[sS])|Dr)/;
  if (!fullName) {
    return ['', ''];
  }
  const lastSpaceIndex = fullName.trim().lastIndexOf(' ');
  let forenames = fullName.substring(0, lastSpaceIndex).trim();
  if (
    // If forename is not just an honorific (e.g. "Mr John Smith" not just "Mr Smith") then remove the honorifc
    !new RegExp(/^\s*/.source + HONORIFIC_PATTERN.source + /\s*$/.source).test(
      forenames
    )
  ) {
    forenames = forenames.replace(
      new RegExp(HONORIFIC_PATTERN.source + /\s/.source),
      ''
    );
  }
  forenames = forenames.trim();
  const surname = fullName.substring(lastSpaceIndex).trim();
  return forenames ? [forenames, surname] : [surname, ''];
};
