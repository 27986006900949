import { useCallback, useEffect } from 'react';
import { useFlagsmith } from 'flagsmith/react';
import { useLazyGetUserQuery } from '../features/users/usersSlice';
import { useAppSelector } from '../store';

const fullWidthAbsoluteElementIds: string[] = [];

/**
 * Prevents background page (body) scroll. E.g. when a modal/drawer is open
 * @param controllerKey a unique key used to identify which elements are currently disabling the scroll
 * @param isScrollDisabled whether scroll is currently disabled
 */
export function usePreventScroll(
  controllerKey: string,
  isScrollDisabled: boolean
): void {
  const dataPropertyName = 'scrollController';

  const getControllerKeys: () => string[] = useCallback(
    () => JSON.parse(document.body.dataset?.[dataPropertyName] || 'null') || [],
    []
  );

  /**
   * Is the element corresponding to controllerKey currently locking scroll?
   */
  const isControllingScroll = useCallback(
    () => getControllerKeys()?.includes(controllerKey),
    [controllerKey, getControllerKeys]
  );

  /**
   * Register a scroll lock associated with controllerKey
   */
  const lockScroll = useCallback(() => {
    const scrollBarWidth = Math.max(
      window.innerWidth - document.body.clientWidth,
      0
    );
    document.body.style.overflow = 'hidden';
    fullWidthAbsoluteElementIds.forEach((id) => {
      const el = document?.getElementById(id);
      if (el) {
        el.style.width = `calc(100vw - ${scrollBarWidth}px)`;
      }
    });
    document.body.style.width = `calc(100vw - ${scrollBarWidth}px)`;
    if (!isControllingScroll()) {
      document.body.dataset[dataPropertyName] = JSON.stringify(
        getControllerKeys().concat([controllerKey])
      );
    }
  }, [controllerKey, getControllerKeys, isControllingScroll]);

  /**
   * Unregister a scroll lock associated with controllerKey
   */
  const unlockScroll = useCallback(() => {
    if (isControllingScroll()) {
      document.body.dataset[dataPropertyName] = JSON.stringify(
        getControllerKeys().filter((key) => key !== controllerKey)
      );
    }
    if (!getControllerKeys()?.length) {
      // No controlling elements -- unset scroll
      document.body.style.removeProperty('overflow');
      document.body.style.width = `auto`;
      fullWidthAbsoluteElementIds.forEach((id) => {
        const el = document?.getElementById(id);
        if (el) {
          el.style.width = `100%`;
        }
      });
      // document.body.style.paddingRight = "0";
    }
  }, [controllerKey, getControllerKeys, isControllingScroll]);

  useEffect(() => {
    if (!document) {
      return;
    }
    if (isScrollDisabled) {
      lockScroll();
    } else {
      unlockScroll();
    }

    return () => document && unlockScroll();
  }, [isScrollDisabled, lockScroll, unlockScroll]);
}

export const useUserIdentification = () => {
  const flagsmith = useFlagsmith();
  const [getUserData] = useLazyGetUserQuery();
  const identificationTrigger = useAppSelector(
    (state) => state.userIdentification
  );

  const resetIdentity = useCallback(() => {
    window?.heap?.resetIdentity();
    flagsmith?.logout();
  }, [flagsmith]);

  // Update heap properties whenever angler details changes
  useEffect(() => {
    (async () => {
      if (!identificationTrigger) {
        resetIdentity();
        return;
      }
      const { data: userData } = await getUserData(undefined, true);
      if (!userData) {
        return;
      }
      if (flagsmith) {
        flagsmith.identify(userData.email, {
          app_public_id: userData.public_id,
          email: userData.email,
          first_name: userData.first_name,
          last_name: userData.last_name,
        });
      }
      if (
        !document.cookie.match(new RegExp(`(^| )CookieConsent=([^;]+)`))?.[2]
      ) {
        return;
      }
      if (window?.heap) {
        window.heap.identify(userData.email);
        window.heap.addUserProperties({
          app_public_id: userData.public_id,
          email: userData.email,
          first_name: userData.first_name,
          last_name: userData.last_name,
        });
      }
    })();
  }, [flagsmith, getUserData, identificationTrigger, resetIdentity]);

  return { resetIdentity };
};
