import { configureStore } from '@reduxjs/toolkit';
import { coreApiSlice } from './apiSlices/coreApiSlice';
import { proApiSlice } from './apiSlices/proApiSlice';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { userIdentificationReducer } from '../features/users/usersSlice';

export const setupStore = (preloadedState = {}) =>
  configureStore({
    reducer: {
      [coreApiSlice.reducerPath]: coreApiSlice.reducer,
      [proApiSlice.reducerPath]: proApiSlice.reducer,
      userIdentification: userIdentificationReducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        .concat(coreApiSlice.middleware)
        .concat(proApiSlice.middleware),
    preloadedState,
  });

export const reduxStore = setupStore();

export type RootState = ReturnType<typeof reduxStore.getState>;
export type AppDispatch = typeof reduxStore.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
