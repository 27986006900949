import React from 'react';
import AddCircle from '../assets/images/so-add-circle.svg';
import Notes from '../assets/images/so-document-ui.svg';
import Phone from '../assets/images/so-phone.svg';
import Power from '../assets/images/so-power.svg';
import Icon from '@ant-design/icons';
import { Modal } from 'antd';
import JustEat from '../assets/images/justeaticon.svg';
import Deliveroo from '../assets/images/Deliveroo-Logo-Crop.svg';
import GoogleMap from '../assets/images/googleMap.svg';
import UberEat from '../assets/images/ubereat.svg';
import Cart from '../assets/images/so-add-to-cart.svg';

import AlarmClock from '../assets/images/so-alarm.svg';
import ChatSVG from '../assets/icons/ChatSVG';
import TickIcon from '../assets/images/so-tick-circle.svg';

import { Divider } from '../../../common/components';
import SessionTimeRemaining from './SessionTimeRemaining';

const SubHeader = ({
  fisheryName,
  sessionEndsAt,
}: {
  fisheryName?: string;
  sessionEndsAt: string;
}): React.JSX.Element => (
  <div className='sub-header-container relative flex flex-col justify-between'>
    <div className='mb-10 text-white '>
      <div className='fishery-name-text-shadow text-2xl '>Welcome to </div>
      <div className='fishery-name-text-shadow text-3xl font-semibold'>
        {fisheryName}
      </div>
    </div>
    <div className='flex justify-between'>
      <div className='flex flex-col'>
        <div className='flex items-center justify-around'>
          <img src={TickIcon} alt='Tick Icon' />
          <div className='fishery-name-text-shadow text-base font-semibold text-white'>
            You are now
          </div>
        </div>
        <div className='sub-header-sub-title mt-2 font-montserrat font-black'>
          <span className='text-blue'>In</span>
          <span>Session</span>
          <span>™</span>
        </div>
      </div>
      <div className='flex flex-col'>
        <div className='flex items-center justify-around'>
          <img src={AlarmClock} alt='Time Remaining icon' />
          <div className='fishery-name-text-shadow text-base font-semibold text-white'>
            Remaining
          </div>
        </div>
        <div className='sub-header-sub-title mt-2 !w-fit font-montserrat font-black'>
          <SessionTimeRemaining sessionEndDate={sessionEndsAt} />
        </div>
      </div>
    </div>
  </div>
);
interface BetaModalProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;

  open: boolean;
}
const BetaModal = ({ open, setOpen }: BetaModalProps) => {
  return (
    <Modal
      open={open}
      className='inSession-beta-container'
      width='90%'
      centered
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      footer=''
    >
      <div>
        {' '}
        <div className='mb-[30px] text-center text-[24px] font-bold'>
          {' '}
          Welcome To
        </div>
        <div className='text-center font-montserrat text-[30px] font-black'>
          InSession
        </div>
        <div className='m-auto mb-6 w-fit rounded-[5px] bg-black px-2 text-center font-montserrat text-[20px] font-black'>
          BETA
        </div>
        <div className='m-auto mb-6 h-[3px] w-[100px] bg-white' />
        <p className='mb-8 text-center text-base font-semibold'>
          You have gained the access to Early Access test version of{' '}
          <span className='font-montserrat font-black'>InSession</span>.
        </p>
        <p className='mb-8 text-center text-base font-semibold'>
          As an early access tester your feedback is massively appreciated and
          will help shape the future of the{' '}
          <span className=' font-montserrat font-black'>InSession</span> angler
          digi-tool.
        </p>
        <p className='mb-8 text-center text-base font-semibold'>
          Click &quot;Leave Feedback&quot; in the side menu to get in touch with
          us!
        </p>
        <div
          className='m-auto mb-6 cursor-pointer rounded-[8px] bg-black p-1 text-center text-[20px] font-semibold'
          onClick={() => setOpen(false)}
        >
          Leave feedback
        </div>
      </div>
    </Modal>
  );
};

const BackgroundImage = ({ slide }: { slide: string }): React.JSX.Element => (
  <div className='absolute left-0 top-0'>
    <div className='bg-overlay absolute h-[380px] w-full' />
    <img className='background-banner' src={slide} alt='background' />
  </div>
);
interface AddCatchReportsAndSessionNotesProps {
  setReportDrawerConfigs: React.Dispatch<
    React.SetStateAction<{ isOpen: boolean; isCatchReport: boolean }>
  >;
}
const AddCatchReportsAndSessionNotes = ({
  setReportDrawerConfigs,
}: AddCatchReportsAndSessionNotesProps): React.JSX.Element => (
  <div className='add-section-container relative flex font-semibold'>
    <div
      className='flex flex-1 cursor-pointer items-center justify-center'
      onClick={() => {
        setReportDrawerConfigs({ isOpen: true, isCatchReport: true });
      }}
    >
      <img src={AddCircle} alt='Add Icons' />
      <div className='ml-4'>Add Catch Report</div>
    </div>
    <Divider />
    <div
      className='flex flex-1 cursor-pointer items-center justify-center'
      onClick={() => {
        setReportDrawerConfigs({ isOpen: true, isCatchReport: false });
      }}
    >
      <img src={Notes} alt='Notes' />
      <div className='ml-4'>Add Session Note</div>
    </div>
  </div>
);
interface SessionProps {
  setQuickLinksOpen: React.Dispatch<React.SetStateAction<boolean>>;
  fisheryPhone: string;
}
const SessionOptions = ({
  setQuickLinksOpen,
  fisheryPhone,
}: SessionProps): React.JSX.Element => {
  return (
    <div className='options-container relative flex'>
      <div
        className='flex flex-1 cursor-pointer flex-col items-center text-center'
        onClick={() => (window.location.href = `tel:${fisheryPhone}`)}
      >
        <div className='h-[29px]'>
          <img src={Phone} alt='phone' />
        </div>
        <div className='option-title mt-4 font-semibold'>Call Bailiff</div>
        <span className='option-helper-text'>Emergency No.</span>
      </div>
      <Divider />
      <div className='flex flex-1 flex-col items-center text-center opacity-50'>
        <div className='h-[29px]'>
          <Icon
            className='session-chat-coming-soon'
            component={() => <ChatSVG />}
          />
        </div>
        <div className='option-title coming-soon mt-4 flex flex-wrap justify-center font-montserrat font-semibold'>
          <span className='font-black'>
            <span className='text-blue'>In</span>
            <span>Session</span>
            <span>™</span>
          </span>
          <span>Chat</span>
        </div>
        <span className='option-helper-text coming-soon'>COMING SOON</span>
      </div>
      <Divider />
      <div
        className='flex flex-1 cursor-pointer flex-col items-center text-center'
        onClick={() => setQuickLinksOpen(true)}
      >
        <div className='h-[29px]'>
          <img src={Power} alt='Power' />
        </div>
        <div className='option-title mt-4 font-semibold'>
          Fishery Quicklinks
        </div>
        <span className='option-helper-text'>Helpful Information</span>
      </div>
    </div>
  );
};

const FoodSection = (): React.JSX.Element => {
  const isIOS = () => {
    return (
      (/iPad|iPhone|iPod/.test(navigator.userAgent) &&
        !Object.prototype.hasOwnProperty.call(window, 'MSStream')) ||
      navigator.userAgent.indexOf('Macintosh') !== -1
    );
  };

  const handleUberEatsClick = () => {
    window.location.href = 'uber-eats://';

    // If the app is not installed, redirect to the app download URL
    setTimeout(() => {
      if (isIOS()) {
        window.location.href =
          'https://apps.apple.com/us/app/uber-eats/id1058959277';
      } else {
        window.location.href =
          'https://play.google.com/store/apps/details?id=com.ubercab.eats';
      }
    }, 500);
  };

  const handleJustEatClick = () => {
    window.location.href = 'just-eat://';

    // If the app is not installed, redirect to the app download URL
    setTimeout(() => {
      if (isIOS()) {
        window.location.href =
          'https://app.adjust.com/oc511o?utm_medium=internal&campaign=homepage-uk';
      } else {
        window.location.href =
          'https://app.adjust.com/1455tm?utm_medium=internal&campaign=homepage';
      }
    }, 500);
  };

  const handleDeliverooClick = () => {
    window.location.href = 'deliveroo://';

    // If the app is not installed, redirect to the app download URL
    setTimeout(() => {
      if (isIOS()) {
        window.location.href =
          'https://deliveroo.co.uk/app/?platform=ios&home_page=true&home_page_variant=homepage_variant_a&mobile_banner=false';
      } else {
        window.location.href =
          'https://deliveroo.co.uk/app/?platform=android&home_page=true&home_page_variant=homepage_variant_a&mobile_banner=false';
      }
    }, 500);
  };
  return (
    <div className='mb-4'>
      <div className='add-section-container flex justify-center !p-2 font-semibold'>
        Find Local Food & Amenities
      </div>
      <div className='mx-4 flex flex-wrap justify-around gap-4'>
        <div className='flex cursor-pointer items-center rounded-[10px] bg-white p-4'>
          <img
            src={JustEat}
            alt='just-eat'
            width={48}
            onClick={handleJustEatClick}
          />
        </div>
        <div className='flex cursor-pointer items-center rounded-[10px] bg-white p-4'>
          <img
            src={UberEat}
            alt='uber-eat'
            width={48}
            onClick={handleUberEatsClick}
          />
        </div>
        <div className='flex cursor-pointer items-center rounded-[10px] bg-white p-4'>
          <img
            src={Deliveroo}
            alt='deliveroo'
            width={48}
            onClick={handleDeliverooClick}
          />
        </div>
        <div
          className='flex items-center rounded-[10px] bg-white p-4'
          onClick={() => {
            window.open(
              'https://www.google.com/maps/search/?api=1&query=restuarants',
              '_blank'
            );
          }}
        >
          <img src={GoogleMap} alt='googleMap' width={48} />
        </div>
      </div>
    </div>
  );
};

const PurchaseSection = (): React.JSX.Element => (
  <div className='home-page-section-container relative flex items-center justify-evenly'>
    <div className=''>
      <img src={Cart} alt='cart' className='opacity-50' />
    </div>
    <div className='flex flex-col'>
      <div className='text-base font-semibold text-[#707070]'>
        Buy Bait, Tackle & More
      </div>
      <div className='text-xs text-[#D1D1D1]'>
        Check out what the fishery has on offer
      </div>
      <div className='text-center text-xs font-semibold'>
        Feature coming soon
      </div>
    </div>
  </div>
);

export {
  SubHeader,
  AddCatchReportsAndSessionNotes,
  SessionOptions,
  BackgroundImage,
  FoodSection,
  PurchaseSection,
  BetaModal,
};
