import React from 'react';
import {
  useGetCurrentSessionQuery,
  useGetUserQuery,
  useGetUserSubscriptionLevelQuery,
  useNewSessionMutation,
  useSetUserSubscriptionLevelMutation,
} from '../../features/users/usersSlice';
import { useFlags } from 'flagsmith/react';
import { Button, DatePicker, Form, Select, Spin } from 'antd';
import { useGetBookableFisheriesQuery } from '../../features/fisheries/fisheriesSlice';

function CreateSessionForm() {
  const [form] = Form.useForm();
  const { data: fisheries } = useGetBookableFisheriesQuery();
  const [createSession] = useNewSessionMutation();

  return (
    <Form
      form={form}
      className='mx-auto max-w-xs'
      onFinish={(data) => {
        createSession({
          fishery_id: data.fishery_id,
          fishery_name: fisheries?.find(({ id }) => id === data.fishery_id)
            ?.name,
          start_ts: data.start_time.toISOString(),
          end_ts: data.end_time.toISOString(),
        })
          .unwrap()
          .then(() => form.resetFields());
      }}
    >
      <Form.Item label='Fishery' name='fishery_id' rules={[{ required: true }]}>
        <Select
          options={fisheries?.map((fishery) => ({
            label: fishery.name,
            value: fishery.id,
          }))}
        />
      </Form.Item>
      <Form.Item
        label='Start Time'
        name='start_time'
        rules={[{ required: true }]}
      >
        <DatePicker showTime />
      </Form.Item>
      <Form.Item label='End Time' name='end_time' rules={[{ required: true }]}>
        <DatePicker showTime />
      </Form.Item>
      <Form.Item>
        <Button type='primary' htmlType='submit'>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}

function AuthMock(): React.JSX.Element {
  const { data: userData, isLoading: loadingUser } = useGetUserQuery();

  const { data: subscriptionLevel, isFetching: loadingSubLevel } =
    useGetUserSubscriptionLevelQuery(undefined, { skip: !userData });

  const [setSubLevel] = useSetUserSubscriptionLevelMutation();

  const { isSuccess: inValidSession, isFetching: loadingIsInSession } =
    useGetCurrentSessionQuery(undefined, {
      skip: !userData || !subscriptionLevel,
    });

  const subscriptionOptions = [
    { name: 'NoSubscription', label: 'None' },
    { name: 'Blue', label: 'SB Blue' },
    { name: 'Pro', label: 'SB Pro' },
    { name: 'Ultra', label: 'SB Ultra' },
  ];

  let pageContents;
  if (loadingUser) {
    return (
      <div className='text-center'>
        <Spin />
      </div>
    );
  } else {
    pageContents = (
      <div className='text-base'>
        <p>
          You are logged in as:{' '}
          <span className='text-blue'>{userData?.email}</span>
        </p>
        <p className='mb-3 text-xs/4 text-grey-500'>
          (To log out, reset your cookies)
        </p>

        {loadingIsInSession ? (
          <Spin />
        ) : (
          <p>
            Currently in a session:{' '}
            <span className='font-semibold'>
              {inValidSession ? 'Yes' : 'No'}
            </span>
          </p>
        )}

        <h2 className=' mb-1 mt-4 font-semibold'>
          Change Your Subscription Level:
        </h2>
        <div className='mb-3 flex flex-row justify-center gap-3'>
          {loadingSubLevel ? (
            <Spin />
          ) : (
            subscriptionOptions.map((opt) => (
              <Button
                key={opt.name}
                className={
                  subscriptionLevel === opt.name
                    ? 'bg-blue text-white'
                    : 'bg-white'
                }
                onClick={() => {
                  if (subscriptionLevel === opt.name) {
                    return;
                  }
                  setSubLevel(opt.name);
                }}
              >
                {opt.label}
              </Button>
            ))
          )}
        </div>

        <h2 className=' mb-1 mt-4 font-semibold'>Create a session:</h2>
        <CreateSessionForm />
      </div>
    );
  }

  return (
    <div className='font-large p-5 text-center'>
      <h1 className='mb-4 mt-5 w-full text-center text-5xl font-bold'>
        <span className='text-blue'>In:</span>Session
      </h1>
      {pageContents}
    </div>
  );
}

export default function AuthMockWrapper(): React.JSX.Element | null {
  const flags = useFlags(['mock_pro_auth']);

  return flags.mock_pro_auth.enabled ? <AuthMock /> : null;
}
