import React from 'react';
import { useFlags } from 'flagsmith/react';
import { FeatureFlagProtectionWrapperProps } from '../index';
import { Link } from 'react-router-dom';
import { Button } from 'antd';

export default function MockAuthFlagWrapper({
  children,
}: FeatureFlagProtectionWrapperProps): React.JSX.Element | null {
  const flags = useFlags(['mock_pro_auth']);

  return flags.mock_pro_auth?.enabled ? (
    children
  ) : (
    <div className='font-large p-5 text-center'>
      <h1 className='mb-4 mt-5 w-full text-center text-5xl font-bold'>
        <span className='text-blue'>In:</span>Session
      </h1>
      <div className='mx-auto my-8 max-w-md'>
        It looks like you don&apos;t have permission to view this page, try{' '}
        <Link to='/login' className='text-blue underline'>
          logging in
        </Link>{' '}
        or{' '}
        <a
          href='https://swimbooker.com/contact/anglers'
          className='text-blue underline'
        >
          contacting us
        </a>{' '}
        if you believe this to be a mistake
      </div>
      <div className='flex flex-row items-center justify-center gap-3 rounded-md bg-neutral-800 py-6 text-lg text-white'>
        Not signed up yet?{' '}
        <Link to='/join-waitlist'>
          <Button className='h-fit bg-seagreen-500 text-lg' type='primary'>
            Find out more!
          </Button>
        </Link>
      </div>
    </div>
  );
}
