import { SVGProps } from 'react';
const SvgAvivaLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={23}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <mask
      id='aviva-logo_svg__a'
      style={{
        maskType: 'luminance',
      }}
      maskUnits='userSpaceOnUse'
      x={15}
      y={1}
      width={7}
      height={19}
    >
      <path
        d='m15.92 1.031 6.074.003-.006 18.038a74.365 74.365 0 0 0-4.305.133L15.92 1.034V1.03Z'
        fill='#fff'
      />
    </mask>
    <g mask='url(#aviva-logo_svg__a)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.411-4.487h17.092v29.21H10.411v-29.21Z'
        fill='#fff'
      />
    </g>
    <mask
      id='aviva-logo_svg__b'
      style={{
        maskType: 'luminance',
      }}
      maskUnits='userSpaceOnUse'
      x={0}
      y={0}
      width={16}
      height={23}
    >
      <path
        d='M15.913.996v.002l-1.79 18.518c-5.509.612-10.376 1.832-14.114 3.47L0 .997 15.913.996Z'
        fill='#fff'
      />
    </mask>
    <g mask='url(#aviva-logo_svg__b)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M-5.512-4.523h26.936v33.026H-5.512V-4.523Z'
        fill='#fff'
      />
    </g>
  </svg>
);
export default SvgAvivaLogo;
