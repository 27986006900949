import { notification as antNotification } from 'antd';
import React from 'react';
import { ArgsProps } from 'antd/es/notification/interface';
import axios, { AxiosError } from 'axios';

export interface Notification extends Omit<ArgsProps, 'message'> {
  message?: string | React.ReactNode;
  description?: undefined | string | React.ReactNode;
}

export const notification = {
  open: ({
    message = undefined,
    description = undefined,
    ...props
  }: Notification) =>
    antNotification.open({
      message: <div className='font-semibold'>{message}</div>,
      style: { border: '1px #e7e7e7 solid' },
      placement:
        (props?.placement ?? window?.innerWidth <= 500) ? 'bottom' : 'topRight',
      className: 'rounded-xl',
      description: description,
      ...props,
    }),

  success: ({
    message = 'Success!',
    description = undefined,
    ...props
  }: Notification) =>
    antNotification.success({
      message: <div className='font-semibold'>{message}</div>,
      style: { border: '1px #2CB972 solid' },
      placement:
        (props?.placement ?? window?.innerWidth <= 500) ? 'bottom' : 'topRight',
      className: 'rounded-xl',
      description: description,
      ...props,
    }),

  warning: ({
    message = 'Please be aware!',
    description = undefined,
    ...props
  }: Notification) =>
    antNotification.warning({
      message: <div className='font-semibold'>{message}</div>,
      style: { border: '1px #F7C046 solid' },
      placement:
        (props?.placement ?? window?.innerWidth <= 500) ? 'bottom' : 'topRight',
      className: 'rounded-xl',
      description: description,
      ...props,
    }),

  error: ({
    message = 'There was an issue!',
    description = undefined,
    ...props
  }: Notification) =>
    antNotification.error({
      message: <div className='font-semibold'>{message}</div>,
      style: { border: '1px #D34545 solid' },
      placement:
        (props?.placement ?? window?.innerWidth <= 500) ? 'bottom' : 'topRight',
      className: 'rounded-xl',
      description: description,
      ...props,
    }),
} as const;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const defaultErrorNotification = (err: AxiosError | any) => {
  if (axios.isAxiosError(err)) {
    if (err?.response?.status === 500) {
      notification.error({
        description: 'Server Response failed. Please try again later',
      });
    } else if (
      err?.response?.status === 401 ||
      (err?.response?.status === 422 &&
        err?.response?.data.detail === 'Signature has expired')
    ) {
      notification.error({
        description: 'Please Login and try again!',
      });
    } else if (err?.response?.status === 422) {
      notification.error({
        description:
          'OOPS! There was an issue processing your request. If this persists please contact our support team',
      });
    } else {
      notification.error({
        description: err?.response?.data.detail,
      });
    }
  } else if (err?.data?.detail) {
    notification.error({
      description: err?.data?.detail,
    });
  } else {
    notification.error({
      description: 'Something went wrong!',
    });
  }
};
