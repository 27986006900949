import noImage from '../../../assets/media/no-photo.jpg';
import { useGlobalState } from './ReportsGlobalState';

import { StepThreeProps, SessionNotesFocusType } from '../types';

interface SessionNotesPreviewProps extends Omit<StepThreeProps, 'setFocus'> {
  setFocus: React.Dispatch<React.SetStateAction<SessionNotesFocusType>>;
}

function SessionNotesPreview({
  focus,
  setFocus,
  date,
  time,
  dontHavePic,
}: SessionNotesPreviewProps) {
  const [, setCurrent] = useGlobalState('current');
  const [fileList] = useGlobalState('fileList');
  const [sessionNotesInfo] = useGlobalState('sessionNotesInfo');
  const [commonInfo] = useGlobalState('commonInfo');

  return (
    <div className='text-base'>
      <div className='catch-report-image-display mb-5 h-[12rem] min-h-fit w-full overflow-hidden object-cover'>
        {fileList && !dontHavePic ? (
          <div className='flex flex-col items-center'>
            <img alt='catch' src={fileList} className='h-full' />
          </div>
        ) : (
          <div className='flex  flex-col items-center '>
            <img
              alt='empty'
              src={noImage}
              className='aspect-[7/3] h-full object-cover'
            />
          </div>
        )}
      </div>
      <p className='mb-5 font-semibold'>
        Please confirm the details of your issue report:
      </p>

      <div className='mb-2.5'>
        <span className=' font-semibold '>Note Title:</span>
        <div className=' flex justify-between'>
          <span className=''>{`${sessionNotesInfo.noteTitle}`}</span>
          <div
            className='center cursor-pointer text-base font-semibold underline '
            onClick={() => {
              setFocus((prevState) => ({
                ...prevState,
                fishery: false,
                lake: false,
                swim: false,
                date: false,
                time: false,
                noteTitle: true,
              }));
              setCurrent(1);
            }}
          >
            Edit
          </div>
        </div>
      </div>
      <div className='mb-2.5'>
        <span className=' font-semibold '>Note Details:</span>
        <div className='flex justify-between'>
          <span className=''>{sessionNotesInfo?.noteDetail}</span>
          <span
            className='flex cursor-pointer flex-col items-center text-base font-semibold underline '
            onClick={() => {
              setFocus((prevState) => ({
                ...prevState,
                fishery: false,
                lake: false,
                swim: false,
                date: false,
                time: false,
                noteDetail: true,
              }));
              setCurrent(1);
            }}
          >
            Edit
          </span>
        </div>
      </div>
      <div className='mb-2.5'>
        <span className=' font-semibold '>Swim:</span>
        <div className='flex justify-between'>
          <span className=''>{`${commonInfo?.fisheryName}, ${commonInfo?.lake}, ${commonInfo?.swim}`}</span>
          <span
            className='flex cursor-pointer flex-col items-center text-base font-semibold underline '
            onClick={() => {
              setFocus((prevState) => ({
                ...prevState,
                fishery: true,
                lake: true,
                swim: true,
                date: false,
                time: false,
              }));
              setCurrent(0);
            }}
          >
            Edit
          </span>
        </div>
      </div>
      <div className='mb-2.5'>
        <span className=' font-semibold '>On:</span>
        <div className='flex justify-between'>
          <span className=''>{`${date?.format(
            'dddd Do MMMM YYYY'
          )} ${time?.format('HH:mm')}`}</span>
          <span
            className='flex cursor-pointer flex-col items-center text-base font-semibold underline '
            onClick={() => {
              setFocus((prevState) => ({
                ...prevState,
                fishery: false,
                lake: false,
                swim: false,
                date: true,
                time: true,
              }));
              setCurrent(0);
            }}
          >
            Edit
          </span>
        </div>
      </div>
    </div>
  );
}

export default SessionNotesPreview;
