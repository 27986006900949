import { SVGProps } from 'react';

const AddCircleSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
  >
    <path
      style={{ fill: 'currentColor' }}
      id='so-add-circle'
      d='M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm4,11H13v3a1,1,0,0,1-2,0V13H8a1,1,0,0,1,0-2h3V8a1,1,0,0,1,2,0v3h3a1,1,0,0,1,0,2Z'
      transform='translate(-2 -2)'
    />
  </svg>
);
export default AddCircleSVG;
