import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLazyGetCheckoutCustomerDetailsQuery } from '../../features/registration/registrationSlice';
import { useIsLoggedInQuery } from '../../features/users/usersSlice';
import { Alert } from 'antd';
import { grantCookiesConsent } from '../../features/cookieConsent';
import { SUBSCRIPTION_SIGN_UP_ROUTE } from '../../app/appConstants';
import { SubscriptionLoading } from '../../features/registration';

export default function SubscriptionPreSuccessPage(): React.JSX.Element {
  const [searchParams] = useSearchParams();
  const subscriptionLevel = searchParams.get('plan') ?? 'Plus';
  const sessionId = searchParams.get('sessionId');

  const [error, setError] = useState<string | null>(null);

  const { data: isLoggedIn, isFetching: loadingLoggedIn } =
    useIsLoggedInQuery();
  const [getCheckoutUserId] = useLazyGetCheckoutCustomerDetailsQuery();
  const navigate = useNavigate();

  const trackedSubscription = useRef<boolean>(false);

  useEffect(() => {
    grantCookiesConsent();
    if (!trackedSubscription?.current) {
      window?.fbq && window.fbq('track', 'Subscribe');
      trackedSubscription.current = true;
    }
  }, []);

  useEffect(() => {
    if (loadingLoggedIn || !trackedSubscription.current || !sessionId) {
      // Wait for subscription tracking event and for isLoggedIn query to finish loading
      return;
    }
    if (isLoggedIn) {
      navigate(
        `${SUBSCRIPTION_SIGN_UP_ROUTE}/success?plan=${encodeURIComponent(
          subscriptionLevel
        )}&sessionId=${encodeURIComponent(sessionId)}`
      );
      return;
    }
    (async () => {
      const {
        data: customerDetails,
        error,
        isError: hasError,
      } = await getCheckoutUserId(sessionId);
      if (hasError) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const errorMessage = error?.data?.detail ?? error?.data;
        setError('Error determining account status: ' + errorMessage);
        return;
      }
      const userId = customerDetails?.user_id;
      if (!userId) {
        // User has no account -- get them to create one
        navigate(
          `${SUBSCRIPTION_SIGN_UP_ROUTE}/new-user?plan=${encodeURIComponent(
            subscriptionLevel
          )}&sessionId=${encodeURIComponent(sessionId)}`
        );
      } else {
        // User has account -- go straight to success page, passing userId as search param
        navigate(
          `${SUBSCRIPTION_SIGN_UP_ROUTE}/success?plan=${encodeURIComponent(
            subscriptionLevel
          )}&sessionId=${encodeURIComponent(
            sessionId
          )}&userId=${encodeURIComponent(userId)}`
        );
      }
    })();
  }, [
    getCheckoutUserId,
    isLoggedIn,
    loadingLoggedIn,
    navigate,
    sessionId,
    subscriptionLevel,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>swimbooker+ | Subscribe Today!</title>
      </Helmet>
      <div className='grid h-full min-h-screen w-full place-content-center bg-gradient-to-b from-blue to-[#1B4C74] px-6 py-4 text-white min-[400px]:px-6'>
        <div className='mx-auto my-auto max-w-sm text-center'>
          {!sessionId ? (
            <Alert
              message='Something went wrong!'
              description='No checkout session ID found.'
              type='error'
              showIcon
            />
          ) : error ? (
            <Alert
              message='Something went wrong!'
              description={error + '. Please try reloading the page!'}
              type='error'
              showIcon
            />
          ) : (
            <SubscriptionLoading />
          )}
        </div>
      </div>
    </>
  );
}
