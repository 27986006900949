import React from 'react';
import { Button } from 'antd';
import SuccessAnimationFireworksFish from './SuccessAnimationFireworksFish';

interface SuccessContentProps {
  subscriptionName: string;
}

function SuccessContent({
  subscriptionName,
}: SuccessContentProps): React.JSX.Element {
  return (
    <>
      <SuccessAnimationFireworksFish />
      <div className='mx-auto max-w-xs'>
        <p className='mb-9 text-xl font-semibold uppercase min-[350px]:text-3xl'>
          Congratulations! You are now a {subscriptionName} member!
        </p>
        <a href='https://www.facebook.com/groups/swimbookerplus'>
          <Button
            type='primary'
            className='mb-4 h-fit w-full whitespace-normal rounded-md border-none bg-[#008DFF] p-2.5 text-base font-semibold text-white min-[350px]:text-lg'
            htmlType='submit'
          >
            Join Exclusive SB+ Facebook Group
          </Button>
        </a>
        <a href={`${process.env?.REACT_APP_MAIN_SITE}user/profile`}>
          <Button
            type='primary'
            className='h-fit w-full whitespace-normal rounded-md border-none bg-black p-2.5 text-xl font-semibold text-white'
            htmlType='submit'
          >
            Explore swimbooker+
          </Button>
        </a>
      </div>
    </>
  );
}

export default SuccessContent;
