import { Button, Checkbox, Col, Form, Input, Row, Spin } from 'antd';
import {
  loginErrorHandler,
  useLoginMutation,
  useNewAnglerSignupMutation,
} from '../usersSlice';
import {
  PRIVACY_POLICY_URL,
  SUBSCRIPTION_SIGN_UP_ROUTE,
  TERMS_AND_CONDITIONS_URL,
} from '../../../app/appConstants';
import { useNavigate } from 'react-router-dom';

export interface CreateAccountFields {
  email: string;
  password: string;
  first_name: string;
  last_name: string;
  is_news_subscribed?: boolean;
}

interface CreateAccountFormProps {
  defaultValues?: Partial<CreateAccountFields>;
  fixedEmail?: boolean;
  successRedirect?: string | null;
}

export const namePattern =
  /^[a-zA-Z0-9\s\-'`àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆŠŽ∂ð]*$/;

export default function CreateAccountForm({
  defaultValues = undefined,
  fixedEmail = false,
  successRedirect = SUBSCRIPTION_SIGN_UP_ROUTE,
}: CreateAccountFormProps) {
  const labelStyle = '-mb-2 text-lg font-medium text-white';
  const inputStyle = 'py-3 text-base border-2 border-[#379BEC]';
  const checkboxTextStyle = 'block text-sm text-white leading-1';

  const [signup, { isLoading: isLoadingCreation }] =
    useNewAnglerSignupMutation();
  const [login, { isLoading: isLoadingLogin }] = useLoginMutation();
  const isLoading = isLoadingCreation || isLoadingLogin;

  const navigate = useNavigate();

  const emailKnown = Boolean(fixedEmail && defaultValues?.email);

  const handleSubmit = (formValues: CreateAccountFields) => {
    signup({
      email: formValues.email,
      password: formValues.password,
      first_name: formValues.first_name,
      last_name: formValues.last_name,
      is_news_subscribed: Boolean(formValues?.is_news_subscribed),
    })
      .unwrap()
      .then(() => {
        login({
          username: formValues.email,
          password: formValues.password,
        })
          .then(() => {
            if (successRedirect) {
              navigate(successRedirect);
            }
          })
          .catch(loginErrorHandler);
      })
      .catch(() => {
        /* Handled by defaultErrorHandler */
      });
  };

  return (
    <Form
      name='basic'
      className='form-error-orange text-left'
      requiredMark={false}
      colon={false}
      layout='vertical'
      onFinish={handleSubmit}
      initialValues={{ is_news_subscribed: false, ...(defaultValues ?? {}) }}
    >
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item<CreateAccountFields>
            label={<p className={labelStyle}>First Name</p>}
            name='first_name'
            rules={[
              {
                required: true,
                message: 'Please input your first name',
              },
              {
                pattern: namePattern,
                message: 'Unsupported character in first name',
              },
            ]}
          >
            <Input className={inputStyle} placeholder='e.g. Rod' />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item<CreateAccountFields>
            label={<p className={labelStyle}>Last Name</p>}
            name='last_name'
            rules={[
              {
                required: true,
                message: 'Please input your last name',
              },
              {
                pattern: namePattern,
                message: 'Unsupported character in last name',
              },
            ]}
          >
            <Input className={inputStyle} placeholder='e.g. Hutchinson' />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item<CreateAccountFields>
        label={<p className={labelStyle}>Email Address</p>}
        className={!emailKnown ? 'mb-1' : ''}
        name='email'
        rules={[
          { type: 'email', message: 'Please enter a valid email' },
          {
            required: true,
            message: 'Please input your email',
          },
        ]}
      >
        <Input
          className={inputStyle + (emailKnown ? ' !text-white ' : ' ')}
          placeholder='e.g. rodhutch@gmail.com'
          disabled={emailKnown}
        />
      </Form.Item>
      {!emailKnown && (
        <Form.Item
          label={<p className={labelStyle}>Confirm Email Address</p>}
          name='confirm_email'
          rules={[
            {
              required: true,
              message: 'Please confirm your email',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('email') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('The two emails that you entered do not match')
                );
              },
            }),
          ]}
        >
          <Input className={inputStyle} placeholder='e.g. rodhutch@gmail.com' />
        </Form.Item>
      )}
      <Form.Item<CreateAccountFields>
        label={<p className={labelStyle}>Create Password</p>}
        name='password'
        className='mb-1'
        rules={[
          {
            required: true,
            message: 'Please input a password',
          },
        ]}
      >
        <Input.Password autoComplete='new-password' className={inputStyle} />
      </Form.Item>
      <Form.Item
        name='confirm_password'
        label={<p className={labelStyle}>Confirm Password</p>}
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Please confirm your password',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error('The two passwords that you entered do not match')
              );
            },
          }),
        ]}
      >
        <Input.Password autoComplete='new-password' className={inputStyle} />
      </Form.Item>
      <Form.Item
        name='terms_and_conditions'
        valuePropName='checked'
        rules={[
          {
            validator: async (_, checked) => {
              if (!checked) {
                return Promise.reject(
                  new Error(
                    'You must accept the terms and conditions to continue'
                  )
                );
              }
            },
          },
        ]}
      >
        <Checkbox className='checkbox-xl checkbox-border-light-blue'>
          <span className={checkboxTextStyle}>
            By creating an account I verify that I agree with swimbooker&apos;s{' '}
            <a
              href={PRIVACY_POLICY_URL}
              target='_blank'
              rel='noopener noreferrer'
              className='underline'
            >
              data policy
            </a>{' '}
            and{' '}
            <a
              href={TERMS_AND_CONDITIONS_URL}
              target='_blank'
              rel='noopener noreferrer'
              className='underline'
            >
              terms and conditions.
            </a>
            .
          </span>
        </Checkbox>
      </Form.Item>
      <Form.Item<CreateAccountFields>
        name='is_news_subscribed'
        valuePropName='checked'
      >
        <Checkbox className='checkbox-xl checkbox-border-light-blue'>
          <span className={checkboxTextStyle}>
            Keep me up to date with swimbooker&apos;s new fisheries, updates,
            news and content.
          </span>
        </Checkbox>
      </Form.Item>
      <Form.Item className='mt-10'>
        <Button
          type='primary'
          className='h-fit w-full rounded-md border-none bg-[#379BEC] p-3 text-lg font-semibold uppercase text-white'
          htmlType='submit'
          disabled={isLoading}
        >
          {isLoading ? <Spin /> : 'Continue'}
        </Button>
      </Form.Item>
    </Form>
  );
}
