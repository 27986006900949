import { SVGProps } from 'react';

const DocumentSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16.542'
    height='20.677'
    viewBox='0 0 16.542 20.677'
  >
    <path
      style={{ fill: 'currentColor' }}
      id='so-document-ui'
      d='M16.406,2l4.135,4.135H16.406ZM14.339,8.2h6.2v13.44a1.034,1.034,0,0,1-1.034,1.034H5.034A1.034,1.034,0,0,1,4,21.643V3.034A1.034,1.034,0,0,1,5.034,2h9.3ZM7.1,6.135A1.034,1.034,0,0,0,8.135,7.169h3.1a1.034,1.034,0,1,0,0-2.068h-3.1A1.034,1.034,0,0,0,7.1,6.135ZM17.44,18.542a1.034,1.034,0,0,0-1.034-1.034H8.135a1.034,1.034,0,1,0,0,2.068h8.271A1.034,1.034,0,0,0,17.44,18.542Zm-9.3-5.169a1.034,1.034,0,0,0,0,2.068h8.271a1.034,1.034,0,1,0,0-2.068Z'
      transform='translate(-4 -2)'
    />
  </svg>
);
export default DocumentSVG;
