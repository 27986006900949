import React, { useState, useEffect, useCallback } from 'react';
import { Button, Drawer, Row, Col, Checkbox } from 'antd';
import { BackButtonLayout } from '../../../common/components/BackButtonLayout';
import { Progress } from 'antd';
import Step1 from '../../Reports/components/Step1';
import Step2 from './Step2';
import CatchReportsPreview from './CatchReportPreview';
import SessionNotesPreview from './SessionNotesPreview';
import Success from './Success';
import ImageUpload from '../../Reports/components/ImageUpload';

import noImage from '../../../assets/media/no-photo.jpg';
import { useGlobalState } from './ReportsGlobalState';
import {
  useCreateSessionNoteMutation,
  SessionNotesRequest,
} from '../../../store/apiSlices/SessionNotesSlice';

import {
  useCreateCatchReportMutation,
  NewCatchReportRequest,
} from '../../../store/apiSlices/CatchReportsSlice';

import { notification } from '../../../utils/messaging';

import dayjs from 'dayjs';
import { usePreventScroll } from '../../../utils/hooks';
import { FisheryDetails } from '../../../store/apiSlices/fisheryDetailsSlice';

const Reports = ({
  isOpen,
  setIsOpen,
  fisheryDetails,
  sessionId,
  isCatchReport = false,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<
    React.SetStateAction<{ isOpen: boolean; isCatchReport: boolean }>
  >;
  isCatchReport?: boolean;
  sessionId: string;
  fisheryDetails?: FisheryDetails;
}) => {
  // Global State
  const [catchInfo, setCatchInfo] = useGlobalState('catchInfo');
  const [commonInfo, setCommonInfo] = useGlobalState('commonInfo');
  const [sessionNotesInfo] = useGlobalState('sessionNotesInfo');
  const [, setFilePreviews] = useGlobalState('filePreview');
  const [fileList, setFileList] = useGlobalState('fileList');
  const [, setValidating] = useGlobalState('validating');
  const [itemRefs, setItemRefs] = useGlobalState('itemRefs');
  const [sessionNotesItemsRef, setSessionNotesItemsRef] = useGlobalState(
    'sessionNotesItemsRef'
  );
  const [current, setCurrent] = useGlobalState('current');
  const [anglerName, setAnglerName] = useGlobalState('anglerName');

  const [dontHavePic, setDontHavePic] = useState(false);
  const [date, setDate] = useState(dayjs(new Date()));
  const [time, setTime] = useState(dayjs(new Date()));
  const [isSubmittingReport, setIsSubmittingReport] = useState(false);

  const [submitSessionNotes] = useCreateSessionNoteMutation();
  const [submitCatchReport] = useCreateCatchReportMutation();

  usePreventScroll('reportsSection', isOpen);

  const steps = [
    {
      title: isCatchReport ? 'Basic Info & Photo' : 'Basic Note Details',
      content: (
        <Step1
          focus={isCatchReport ? itemRefs : sessionNotesItemsRef}
          date={date}
          time={time}
          setDate={setDate}
          setTime={setTime}
          fisheryDetails={fisheryDetails}
        />
      ),
      progress: 33,
    },
    {
      title: isCatchReport ? 'Fishing Details' : 'Issue Details',
      progress: 66,
      content: (
        <Step2
          focus={isCatchReport ? itemRefs : sessionNotesItemsRef}
          isCatchReport={isCatchReport}
        />
      ),
    },
    {
      progress: 100,
      title: 'Summary',
      content: isCatchReport ? (
        <CatchReportsPreview
          focus={itemRefs}
          setFocus={setItemRefs}
          date={date}
          time={time}
          dontHavePic={dontHavePic}
        />
      ) : (
        <SessionNotesPreview
          focus={sessionNotesItemsRef}
          setFocus={setSessionNotesItemsRef}
          date={date}
          time={time}
          dontHavePic={dontHavePic}
        />
      ),
    },
  ];

  const handleSubmitSessionNotes = (notesPayload: SessionNotesRequest) => {
    submitSessionNotes(notesPayload)
      .unwrap()
      .then(() => {
        setIsSubmittingReport(false);
        setCurrent((prev) => prev + 1);
      })
      .catch((err) => {
        setIsSubmittingReport(false);
        notification.error({
          description: 'Please contact the administrator',
        });
      });
  };

  const handleSubmitCatchReport = (
    catchReportPayload: NewCatchReportRequest
  ) => {
    submitCatchReport(catchReportPayload)
      .unwrap()
      .then(() => {
        setIsSubmittingReport(false);
        setCurrent((prev) => prev + 1);
      })
      .catch((err) => {
        setIsSubmittingReport(false);
        notification.error({
          description: 'Please contact the administrator',
        });
      });
  };

  const handleReportSubmission = () => {
    setIsSubmittingReport(true);

    const catchReportPayload = {
      name: anglerName,
      fishery: fisheryDetails?.venue_name,
      lake: commonInfo.lake,
      swim: commonInfo.swim,
      fish_species: catchInfo.species,
      fish_weight_lb: +catchInfo.fish_weight_lb,
      fish_weight_oz: +catchInfo.fish_weight_oz,
      rig: catchInfo.rig,
      bait: catchInfo.bait,
      notes: catchInfo.note,
      personal_best: catchInfo.personalBest,
      image_upload: !dontHavePic && fileList ? fileList : '',
      fish_caught_date: `${date.format('YYYY-MM-DD')} ${time.format('HH:mm')}`,
    };

    const notesData = {
      swim: commonInfo.swim,
      title: sessionNotesInfo.noteTitle,
      detail: sessionNotesInfo.noteDetail,
      note_date: `${date.format('YYYY-MM-DD')} ${time.format('HH:mm')}`,
      image_upload: fileList,
      session_id: sessionId,
      lake: commonInfo.lake,
    };

    if (isCatchReport) {
      handleSubmitCatchReport(catchReportPayload);
    } else {
      handleSubmitSessionNotes(notesData);
    }
  };

  const resetState = useCallback(() => {
    setCurrent(0);
    setValidating(false);
    setDontHavePic(false);
    setAnglerName('');
    setFilePreviews([]);
    setFileList('');
    setCommonInfo({
      fisheryName: fisheryDetails?.venue_name || '',
      fisheryId: '',
      fisheryPublicId: '',
      lake: null,
      swim: '',
      date: dayjs(new Date()),
      time: '',
    });
    setCatchInfo({
      species: '',
      speciesValue: '',
      fish_weight_lb: '',
      fish_weight_oz: '',
      bait: '',
      rig: '',
      personalBest: false,
      note: '',
      lakeDisable: true,
    });
  }, [
    setCurrent,
    setValidating,
    setDontHavePic,
    setAnglerName,
    setFilePreviews,
    setFileList,
    setCommonInfo,
    setCatchInfo,
    fisheryDetails,
  ]);

  useEffect(() => {
    if (!document) {
      return;
    }
    if (isOpen) {
      resetState();
    }
  }, [isOpen, resetState]);

  return (
    <Drawer
      title={null}
      footer={null}
      closable={false}
      rootClassName='catch_report__drawer'
      open={isOpen}
      onClose={() => {
        setIsOpen({ isOpen: false, isCatchReport: false });
      }}
      key={isOpen as unknown as string}
    >
      <BackButtonLayout
        useCrossIcon
        overlapHeader={true}
        onBack={() => {
          setIsOpen({ isOpen: false, isCatchReport: false });
        }}
      >
        <div
          className={` ${
            current > 2 ? ' bg-blue' : ' px-6 py-4 md:px-10'
          } flex min-h-[100vh] flex-col justify-between`}
        >
          {current > 2 ? (
            <Success setIsOpen={setIsOpen} isCatchReport={isCatchReport} />
          ) : (
            <React.Fragment>
              <div className=' relative mx-auto my-0 w-full !max-w-[1300px]  !px-0'>
                <h2 className='mb-4 flex flex-col items-center pt-4 text-xl font-semibold '>
                  {isCatchReport ? 'New Catch Report' : 'New Session Note'}{' '}
                </h2>
                <Progress
                  percent={steps[current]?.progress}
                  showInfo={false}
                  trailColor='#CFE4F5'
                  strokeColor='#2772AF'
                />
                <h2 className='mb-4 flex flex-col items-center pt-4 text-xl font-medium '>
                  {steps[current].title}
                </h2>
                {current < 2 && (
                  <div className='catch-report-image-display mb-5 h-[12rem] min-h-fit w-full overflow-hidden object-cover'>
                    {!dontHavePic ? (
                      <ImageUpload isCatchReport={isCatchReport} />
                    ) : (
                      <div className='flex flex-col items-center'>
                        <img
                          alt='empty'
                          src={noImage}
                          className='aspect-[7/3] h-full object-cover'
                        />
                      </div>
                    )}
                  </div>
                )}

                {current === 0 && isCatchReport && (
                  <div className=' flex flex-col items-center'>
                    <Checkbox
                      className='picture-checkbox flex-row-reverse justify-center font-light'
                      onClick={() => setDontHavePic((prev) => !prev)}
                    >
                      I havent got a picture
                    </Checkbox>
                  </div>
                )}
                {steps[current].content}
              </div>
              <div className='mt-5'>
                {current === 0 && (
                  <Button
                    className='reports-cta h-fit w-full rounded-full bg-blue p-2.5 px-[28px]  text-center text-xl font-semibold text-white'
                    onClick={() => {
                      setValidating(true);

                      if (date && time && commonInfo.lake) {
                        setCurrent((prev) => prev + 1);
                        setValidating(false);
                      } else {
                        notification.warning({
                          message: (
                            <div className='font-semibold'>
                              Please be aware!
                            </div>
                          ),
                          style: { border: '1px #F7C046 solid' },
                          className: ' rounded-[10px]',
                          description: 'Please fill in all required fields',
                          placement:
                            window?.innerWidth <= 500 ? 'bottom' : 'topRight',
                        });
                      }
                    }}
                  >
                    Next
                  </Button>
                )}
                {current === 1 && (
                  <Row>
                    <Col span={12} className='flex items-center'>
                      <span
                        className='ml-2.5 cursor-pointer flex-col items-center text-2xl font-semibold underline'
                        onClick={() => {
                          setCurrent((prev) => prev - 1);
                        }}
                      >
                        Back
                      </span>
                    </Col>
                    <Col span={12}>
                      <Button
                        className='reports-cta  float-right h-fit w-full rounded-full bg-blue p-2.5  px-[28px] text-center text-xl font-semibold text-white'
                        style={{ maxWidth: '11rem' }}
                        onClick={() => {
                          setValidating(true);
                          if (
                            isCatchReport &&
                            catchInfo.species &&
                            catchInfo.fish_weight_lb &&
                            catchInfo.fish_weight_oz
                          ) {
                            setValidating(false);
                            setCurrent((prev) => prev + 1);
                          } else if (
                            !isCatchReport &&
                            sessionNotesInfo.noteTitle &&
                            sessionNotesInfo.noteDetail
                          ) {
                            setValidating(false);
                            setCurrent((prev) => prev + 1);
                          } else {
                            notification.warning({
                              message: (
                                <div className='font-semibold'>
                                  Please be aware!
                                </div>
                              ),
                              style: { border: '1px #F7C046 solid' },
                              className: ' rounded-[10px]',
                              description: 'Please fill in all required fields',
                              placement:
                                window?.innerWidth <= 500
                                  ? 'bottom'
                                  : 'topRight',
                            });
                          }
                        }}
                      >
                        Next
                      </Button>
                    </Col>
                  </Row>
                )}
                {current === 2 && (
                  <Row>
                    <Col span={24}>
                      <Button
                        className='reports-cta  float-right h-fit w-full rounded-full bg-[#3cb371] p-2.5  px-[28px] text-center text-xl font-semibold text-white'
                        onClick={handleReportSubmission}
                        loading={isSubmittingReport}
                      >
                        Submit
                      </Button>
                    </Col>
                    <Col span={24} className='flex items-center justify-center'>
                      <span
                        className='mt-[25px] cursor-pointer flex-col items-center text-2xl font-semibold  underline'
                        onClick={() => {
                          setCurrent((prev) => prev - 1);
                        }}
                      >
                        Back
                      </span>
                    </Col>
                  </Row>
                )}
              </div>
            </React.Fragment>
          )}
        </div>
      </BackButtonLayout>
    </Drawer>
  );
};
export default Reports;
