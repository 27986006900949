import { useState } from 'react';
import axios from 'axios';

import { Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useGlobalState } from './ReportsGlobalState';

import { UploadChangeParam } from 'antd/lib/upload/interface';

import { AntdUploadedFile } from '../types';
import { UploadListType } from 'antd/es/upload/interface';
import { notification } from '../../../utils/messaging';

function UploadImages({ isCatchReport }: { isCatchReport: boolean }) {
  const [fileList, setFileList] = useGlobalState('fileList');
  const [filePreviews, setFilePreviews] = useState<AntdUploadedFile[]>([]);

  const [, setUploading] = useState(false);
  const [, setPreview] = useState({
    visible: false,
    image: '',
    title: '',
  });

  function validateType(file: AntdUploadedFile) {
    return file.type === 'image/jpeg' || file.type === 'image/png';
  }

  function validateSize(file: AntdUploadedFile) {
    if (file && file.size) {
      const isGt2M = file.size / 1024 / 1024 > 10;
      return !isGt2M;
    }
  }

  const beforeUpload = (file: AntdUploadedFile) => {
    if (validateType(file)) {
      if (validateSize(file)) {
        setFilePreviews([file]);

        return false;
      } else {
        setFileList('');
        setFilePreviews([]);
        notification.error({
          description: 'Image must smaller than 10MB!',
        });
      }
    } else {
      setFileList('');
      setFilePreviews([]);
      notification.error({
        description: 'You can only upload JPG/PNG file!',
      });
    }
  };

  const handlePreview = async (file: AntdUploadedFile) => {
    if (file && file.url) {
      setPreview({
        image: file.url,
        visible: true,
        title: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
      });
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className='' style={{ marginTop: 8 }}>
        Upload Photo
      </div>
    </div>
  );

  const postFiles = async (body: FormData) => {
    const baseURL = isCatchReport
      ? `${process.env.REACT_APP_CORE_URL}fishery`
      : process.env.REACT_APP_PRO_URL;

    const postUrl = isCatchReport
      ? '/catch/report/image'
      : 'session_notes/image';
    try {
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
        withCredentials: true,
        crossDomain: true,
        baseURL,
        'Access-Control-Allow-Origin': '*',
        sameSite: null,
        secure: false,
      };
      return axios
        .post(postUrl, body, config)
        .then((res) => res)
        .catch((err) => err);
    } catch (err) {
      return [];
    }
  };

  const uploadImageToServer = (info: UploadChangeParam) => {
    if (validateType(info.file) && validateSize(info.file)) {
      setFilePreviews(info.fileList);

      const formData = new FormData();

      formData.append('file', info.file as unknown as Blob);

      postFiles(formData)
        .then((res) => {
          setFileList(res.data.image_path);
          setUploading(false);
          if (res.data.status === 'Image uploaded') {
            notification.success({
              description: `Uploaded Image Successfully`,
            });
          } else {
            notification.error({
              description: `Failed to upload ${info.file.name}.`,
            });
          }
        })
        .catch(() => {
          notification.error({
            description: `Failed to upload ${info.file.name}.`,
          });
          setUploading(false);
        });
    }
  };

  const onRemove = (file: AntdUploadedFile) => {
    setFileList('');
    setFilePreviews((curList) => {
      const index = curList?.indexOf(file);
      const newFileList = curList.slice();
      newFileList.splice(index, 1);
      return newFileList;
    });
  };

  const uploadProps = {
    name: 'avatar',
    listType: 'picture-card' as UploadListType,
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info: UploadChangeParam) {
      if (info.fileList.length > 0) {
        setUploading(true);
        uploadImageToServer(info);
      } else {
        setFilePreviews(info.fileList);
      }
    },
  };

  return (
    <Upload
      {...uploadProps}
      beforeUpload={beforeUpload}
      onRemove={onRemove}
      onPreview={handlePreview}
      className='catch-report-upload'
      maxCount={1}
      fileList={filePreviews as AntdUploadedFile[]}
    >
      {fileList ? null : uploadButton}
    </Upload>
  );
}

export default UploadImages;
