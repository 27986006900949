const SvgDownChevron = ({ ...props }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    {...props}
  >
    <path
      d='M8.00004 12.5601C8.2868 12.5601 8.57351 12.4507 8.79213 12.2321L15.6718 5.35243C16.1094 4.9148 16.1094 4.20525 15.6718 3.76779C15.2343 3.33033 14.5249 3.33033 14.0872 3.76779L8.00004 9.85534L1.91282 3.768C1.47518 3.33055 0.765843 3.33055 0.328421 3.768C-0.109426 4.20546 -0.109426 4.91501 0.328421 5.35265L7.20795 12.2324C7.42668 12.4509 7.7134 12.5601 8.00004 12.5601Z'
      fill='#DCE1E5'
    />
  </svg>
);
export default SvgDownChevron;
