import React, { useMemo, useState } from 'react';
import { useGetPricesQuery } from '../registrationSlice';
import PricingCard, { SubscriptionLevel } from './PricingCard';
import TextSwitch from '../../../common/components/TextSwitch';
import { Spin } from 'antd';
import stripeLogo from '../assets/media/stripe-logo.svg';

const proFeatures = [
  'Personal Accident Insurance',
  <>
    Equipment cover up to <b className='font-bold text-grey-100'>£1000</b>
  </>,
  'Up to 2% credit back on purchases',
  'Up to 1% credit back on fishing',
];

const plusFeatures = [
  'Personal Accident Insurance',
  <>
    Equipment cover up to <b className='font-bold text-grey-100'>£350</b>
  </>,
  'Up to 1% credit back on purchases',
  'Up to 0.10% credit back on fishing',
];

const basicFeatures = [
  'Book Fishing Online & In-App',
  'Submit & Manage Catch Reports',
  'No added booking fees',
];

export default function PricingPlanSelection({
  className = '',
  showBasic = false,
  offerTrial = false,
}: {
  className?: string;
  showBasic?: boolean;
  offerTrial?: boolean;
}): React.JSX.Element {
  const { data: prices, isLoading: loading } = useGetPricesQuery();

  const { plusMonthlyPrice, plusAnnualPrice, proMonthlyPrice, proAnnualPrice } =
    useMemo(
      () => ({
        plusMonthlyPrice: prices?.Plus?.find((p) => p.interval === 'month'),
        plusAnnualPrice: prices?.Plus?.find((p) => p.interval === 'year'),
        proMonthlyPrice: prices?.Pro?.find((p) => p.interval === 'month'),
        proAnnualPrice: prices?.Pro?.find((p) => p.interval === 'year'),
      }),
      [prices?.Plus, prices?.Pro]
    );

  const trial_length = useMemo(() => prices?.trial_length ?? null, [prices]);
  const trialDays = offerTrial ? trial_length : 0;

  const [isAnnualSelected, setIsAnnualSelected] = useState<boolean>(false);

  const basicCard = (
    <PricingCard
      plan={SubscriptionLevel.Basic}
      priceId={proMonthlyPrice?.id}
      features={basicFeatures}
    />
  );

  const monthlyCards = (
    <>
      {proMonthlyPrice && (
        <PricingCard
          plan={SubscriptionLevel.Pro}
          price={proMonthlyPrice?.cost ?? 0}
          interval='month'
          fullPrice={1399}
          priceId={proMonthlyPrice?.id}
          features={proFeatures}
          trialDays={trialDays}
        />
      )}
      {plusMonthlyPrice && (
        <PricingCard
          plan={SubscriptionLevel.Plus}
          price={plusMonthlyPrice?.cost ?? 0}
          interval='month'
          fullPrice={999}
          priceId={plusMonthlyPrice?.id}
          features={plusFeatures}
          trialDays={trialDays}
        />
      )}
    </>
  );

  const annualCards = (
    <>
      {proAnnualPrice && (
        <PricingCard
          plan={SubscriptionLevel.Pro}
          price={proAnnualPrice?.cost ?? 0}
          interval='year'
          fullPrice={9999}
          priceId={proAnnualPrice?.id}
          features={proFeatures}
          trialDays={trialDays}
        />
      )}
      {plusAnnualPrice && (
        <PricingCard
          plan={SubscriptionLevel.Plus}
          price={plusAnnualPrice?.cost ?? 0}
          interval='year'
          fullPrice={6999}
          priceId={plusAnnualPrice?.id}
          features={plusFeatures}
          trialDays={trialDays}
        />
      )}
    </>
  );

  return (
    <div
      className={
        'flex flex-col items-center justify-center text-center text-lg ' +
        className
      }
    >
      <TextSwitch
        checked={isAnnualSelected}
        onChange={setIsAnnualSelected}
        checkedChildren='Annual'
        unCheckedChildren='Monthly'
      />
      <div className='mt-4 flex flex-row flex-wrap justify-center gap-3'>
        {showBasic && basicCard}
        {loading ? (
          <Spin className='mt-10' />
        ) : !isAnnualSelected ? (
          monthlyCards
        ) : (
          annualCards
        )}
      </div>
      <div className='mt-5 flex flex-row text-sm font-medium'>
        <p>
          Securely processed via{' '}
          <img
            src={stripeLogo}
            alt='Stripe'
            className='-ml-0.5 inline-block h-8'
          />
        </p>
      </div>
    </div>
  );
}
