/**
 * Converts a value from int pence to a pounds string
 *
 * E.g. 499 => "£4.99"
 * @param pence the amount in pence
 */
export function formatAsPounds(pence: number) {
  return `£${Math.floor(pence / 100)}.${(pence % 100)
    .toString()
    .padStart(2, '0')}`;
}
