import React from 'react';
import { useGetNextSessionStartQuery } from '../features/users/usersSlice';
import dayjs from 'dayjs';
import { useCountdown } from '../utils/countdown';
import { Button, Spin } from 'antd';
import { Link } from 'react-router-dom';

export default function WaitRoomPage(): React.JSX.Element {
  const {
    data: startTime,
    isFetching: loading,
    isError: error,
  } = useGetNextSessionStartQuery();
  const nextSessionStart = dayjs(error ? null : startTime);
  const hasValidSession = !loading && !error && nextSessionStart.isValid();

  const [days, hours, minutes, seconds] = useCountdown(
    hasValidSession ? nextSessionStart.diff(dayjs(), 'seconds') : 0
  );

  const noSessionsMessage = (
    <>
      <p>You don&apos;t have any sessions.</p>
      <p>
        Make a booking at{' '}
        <a href='https://swimbooker.com' className='text-blue underline'>
          swimbooker.com
        </a>{' '}
        to get started
      </p>
    </>
  );

  const sessionReadyMessage = (
    <p>
      Your next session is ready!{' '}
      <Link to='/'>
        <Button className='h-fit bg-blue text-lg' type='primary'>
          Enter InSession
        </Button>
      </Link>
    </p>
  );

  const sessionUpcomingMessage = (
    <>
      You are not currently in a session. Your next session is in:{' '}
      <div className='mx-auto mt-1 w-fit rounded-lg border-2 p-2'>
        {loading ? (
          <Spin />
        ) : (
          <>
            {`${days} day${days !== 1 ? 's' : ''},`}
            {` ${hours} hour${hours !== 1 ? 's' : ''},`}
            {` ${minutes} minute${minutes !== 1 ? 's' : ''},`}
            {` & ${seconds} second${seconds !== 1 ? 's' : ''}`}
          </>
        )}
      </div>
    </>
  );

  return (
    <div className='font-large p-5 text-center'>
      <h1 className='mb-4 mt-5 w-full text-center text-5xl font-bold'>
        <span className='text-blue'>In:</span>Session
      </h1>
      <div className='mx-auto my-8 max-w-xs'>
        {!loading && !hasValidSession
          ? noSessionsMessage
          : dayjs(nextSessionStart).isBefore(dayjs())
            ? sessionReadyMessage
            : sessionUpcomingMessage}
      </div>
    </div>
  );
}
