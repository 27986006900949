import { Button } from 'antd';
import ArrowLeft from '../assets/media/LeftArrow.png';
import ArrowRight from '../assets/media/RightArrow.png';
import 'react-multi-carousel/lib/styles.css';
import { useEffect } from 'react';
import '../assets/css/sliders.css';

export interface CarouselStateType {
  currentSlide: number;
  totalItems: number;
  currentSlideIndex: number;
  isLastSlide: boolean;
  isCarouselAnimated: boolean;
  itemWidth: number;
  isInfinite: boolean;
  slidesToShow: number;
  totalItemsInSlideQueue: number;
}
interface ButtonGroupProps {
  onChange: (currentIndex: number) => void;
  onStateChange: (carouselState: CarouselStateType) => void;
  infinite?: boolean;
  numSlides?: number;
  next?: () => void;
  previous?: () => void;
  carouselState?: CarouselStateType;
}

const ButtonGroup = ({
  onChange,
  onStateChange,
  infinite = false,
  numSlides = 1,
  next,
  previous,
  ...rest
}: ButtonGroupProps) => {
  const { carouselState } = rest;
  const { currentSlide } = carouselState ?? { currentSlide: 1 };

  useEffect(() => {
    if (carouselState) {
      onStateChange(carouselState);
    }
  }, [carouselState, onStateChange]);

  useEffect(() => {
    onChange((currentSlide + numSlides - 2) % numSlides);
  }, [onChange, currentSlide, numSlides, carouselState]);

  return (
    <div className='carousel-button-group pointer-events-none absolute inset-0 mx-auto my-0 flex h-full w-full max-w-[95vw] items-center justify-between'>
      <Button
        className='carousel-btn left-0 !grid w-[33px] items-center justify-center !bg-[#f5f5f5]  !p-0'
        disabled={numSlides <= 1 || (!infinite && currentSlide === 0)}
        onClick={previous}
      >
        <img src={ArrowLeft} alt='Left arrow' />
      </Button>
      <Button
        className='carousel-btn right-0 !grid w-[33px] items-center justify-center !bg-[#f5f5f5] !p-0'
        disabled={
          numSlides <= 1 || (!infinite && currentSlide === numSlides - 1)
        }
        onClick={next}
      >
        <img src={ArrowRight} alt='Right arrow' />
      </Button>
    </div>
  );
};

export default ButtonGroup;
