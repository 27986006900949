import React from 'react';
import { Collapse, CollapseProps } from 'antd';
import '../assets/css/faqs.css';
import Icon from '@ant-design/icons';
import DownChevron from '../../../assets/icons/DownChevron';

const questions: CollapseProps['items'] = [
  {
    label: 'What exactly does the insurance cover?',
    children: (
      <>
        <p>
          swimbooker+ insurance cover is backed by Aviva and claims are handled
          by our partners at Marsh Sports.
        </p>
        <p>
          For both membership packages, insurance covers anglers whilst they are
          actively participating in the sport. (i.e actively partaking in
          fishing)
        </p>
        <p>
          Full details of each package{' '}
          <a
            className='underline'
            href={process.env?.REACT_APP_INSURANCE_SUMMARY_DOCUMENT}
          >
            can be seen here
          </a>
          .
        </p>
      </>
    ),
  },
  {
    label: 'How do I signup to swimbooker+?',
    children: (
      <>
        <p>
          Signing up is simple! When ready, choose one of the packages above and
          you will be taken to the secure Stripe billing checkout. Stripe is the
          most widely used online payment gateway as it is extremely well
          secured, robust and provides multiple payment options.
        </p>
        <p>
          When on the Stripe billing page, you will be asked to enter an email
          address. This email will be used to either create a new swimbooker
          account, or match to an existing swimbooker free account. This is an
          automated process.
        </p>
        <p>
          Once you’ve chosen your preferred payment method and checked out, you
          will be guided through the final steps of either creating your
          account, or logging in.
        </p>
      </>
    ),
  },
  {
    label: 'Am I able to cancel whenever I want?',
    children: (
      <p>
        Absolutely! All swimbooker+ packages can be cancelled whenever you wish.
        Once you’ve signed up, you can manage your subscription directly from
        the ‘Manage Subscription’ button on your angler profile.
      </p>
    ),
  },
  {
    label: 'How do the giveaways work?',
    children: (
      <>
        <p>
          swimbooker+ members gain access to a weekly giveaway which can be
          entered for free via the mobile app or website. Simply navigate to
          your angler profile and click on ‘Giveaways’.
        </p>

        <p>
          Once there, users can answer the weekly question. If answered
          correctly, they will be allocated a ticket number and be included in
          the weekly live draw. There are no additional charges to enter on a
          weekly basis.
        </p>
      </>
    ),
  },
  {
    label: 'Do I earn credit back on all bookings?',
    children: (
      <p>
        Once signed up, members earn a percentage back from all fishing bookings
        made online via the swimbooker platform. This value is accumulated in
        your SB angler wallet and can be collected or spent as you wish. You can
        utilise the value in your wallet towards future bookings on swimbooker.
      </p>
    ),
  },
  {
    label: 'How do I claim and use perks?',
    children: (
      <>
        <p>
          There are many partners and fisheries offering exclusive perks and
          benefits to swimbooker+ members. Once you’ve signed up, you will gain
          access to all of them, including any that are added in the future. We
          are currently adding new perks weekly to the platform which is
          providing even more value to anglers on a daily basis.
        </p>

        <p>
          On the perks section of the platform, you will find detailed
          instructions on how to claim each individual perk.
        </p>
      </>
    ),
  },
];

export default function FAQs(): React.JSX.Element {
  return (
    <Collapse
      className='faq-collapse rounded-none bg-blue-800 text-grey-200'
      accordion
      items={questions}
      defaultActiveKey={0}
      expandIconPosition='end'
      bordered={false}
      expandIcon={() => <Icon component={DownChevron} />}
    />
  );
}
