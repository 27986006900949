import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  SubscriptionLoading,
  SuccessContent,
} from '../../features/registration';
import { useSearchParams } from 'react-router-dom';
import {
  useConfirmRegistrationMutation,
  useTriggerWebhooksMutation,
} from '../../features/registration/registrationSlice';
import {
  useGetUserIdQuery,
  useGetUserSubscriptionLevelQuery,
} from '../../features/users/usersSlice';
import { Alert } from 'antd';
import { grantCookiesConsent } from '../../features/cookieConsent';
import { defaultErrorNotification } from '../../utils/messaging';

export default function SubscriptionSuccessPage(): React.JSX.Element {
  const [searchParams] = useSearchParams();
  const subscriptionLevel = searchParams.get('plan') ?? 'Plus';
  const sessionId = searchParams.get('sessionId');

  // Pass userId as param or fetch from currently logged-in user
  const userIdParam = searchParams.get('userId');
  const { data: userIds, isFetching: loadingUserId } = useGetUserIdQuery(
    undefined,
    {
      skip: Boolean(userIdParam),
    }
  );
  const userId = userIdParam || userIds?.user_id;
  const noIdProvded = !loadingUserId && !userId;

  const [confirmRegistration] = useConfirmRegistrationMutation();
  const [triggerProWebhooks] = useTriggerWebhooksMutation();
  const { data: userSubscription, isFetching: loadingSubscription } =
    useGetUserSubscriptionLevelQuery();
  const [loading, setLoading] = useState<boolean>(true);

  const trackedSubscription = useRef<boolean>(false);

  useEffect(() => {
    grantCookiesConsent();
    if (!trackedSubscription?.current) {
      window?.fbq && window.fbq('track', 'Subscribe');
      trackedSubscription.current = true;
    }
  }, []);

  useEffect(() => {
    if (userSubscription) {
      // User purchase has already been validated
      setLoading(false);
    }
    if (!sessionId || !userId) {
      // Page is loading or no userId supplied
      return;
    }
    setLoading(true);
    confirmRegistration({
      checkout_session_id: sessionId,
      user_id: userId,
    })
      .unwrap()
      .then(() => {
        setLoading(false);
        triggerProWebhooks();
      })
      .catch((err) => {
        setLoading(false);
        if (err?.data?.detail !== 'You already have a subscription!') {
          defaultErrorNotification(err);
        }
      });
  }, [
    confirmRegistration,
    sessionId,
    triggerProWebhooks,
    userId,
    userSubscription,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>swimbooker+ | Subscribe Today!</title>
      </Helmet>
      <div className='grid h-full min-h-screen w-full place-content-center bg-gradient-to-b from-blue to-[#1B4C74] px-6 py-4 text-white min-[400px]:px-6'>
        <div className='mx-auto my-auto max-w-sm text-center'>
          {noIdProvded ? (
            <Alert
              message='Something went wrong'
              description='Unable to fetch User ID.'
              type='error'
              showIcon
            />
          ) : loadingSubscription || loading ? (
            <SubscriptionLoading />
          ) : (
            <SuccessContent subscriptionName={subscriptionLevel} />
          )}
        </div>
      </div>
    </>
  );
}
