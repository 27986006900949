import {
  createBrowserRouter,
  LoaderFunctionArgs,
  Navigate,
  redirect,
  RouteObject,
} from 'react-router-dom';
import {
  extendedCoreApiSlice as usersApiSlice,
  extendedProApiSlice as usersProApiSlice,
} from '../../features/users/usersSlice';
import { reduxStore } from '../../store';
import {
  LOGIN_ROUTE,
  SUBSCRIPTION_SIGN_UP_ROUTE,
  WAITING_ROOM_ROUTE,
} from '../appConstants';

export enum SecurityLevel {
  /** All Users */
  Public,
  /** Logged In = false */
  NotLoggedIn,
  /** Logged In = true */
  LoggedIn,
  /** Logged In = false && Subscription = false */
  NotLoggedInOrNoSubscription,
  /** Logged In = true && Subscription = false */
  NoSubscription,
  /** Logged In = true && Subscription = false || Subscription == PreRegistered */
  NoSubscriptionOrPreRegistered,
  /** Logged In = true && Subscription = true */
  Subscription,
  /** Logged In = true && Subscription = true && Session = false */
  NoSession,
  /** Logged In = true && Subscription = true && Session = true */
  InSession,
}

export type RouteSecurity =
  | { level: SecurityLevel.Public; redirect?: never }
  | { level: Exclude<SecurityLevel, SecurityLevel.Public>; redirect?: string };

const isUserLoggedIn = async () => {
  const auth = reduxStore.dispatch(usersApiSlice.endpoints.getUser.initiate());
  try {
    const user = await auth.unwrap();
    auth.unsubscribe();
    return Boolean(user);
  } catch (e) {
    // Catch 401/403/etc.
    auth.unsubscribe();
    return false;
  }
};

const getUserSubscription = async (return_pre_registered = false) => {
  const subLevel = reduxStore.dispatch(
    usersProApiSlice.endpoints.getUserSubscriptionLevel.initiate(
      return_pre_registered
    )
  );
  try {
    const userSubscriptionLevel = await subLevel.unwrap();
    subLevel.unsubscribe();
    return userSubscriptionLevel;
  } catch (e) {
    // Catch 401/403/etc.
    subLevel.unsubscribe();
    return null;
  }
};

const getCurrentSession = async () => {
  const currentSession = reduxStore.dispatch(
    usersProApiSlice.endpoints.getCurrentSession.initiate()
  );
  currentSession.unsubscribe();
  try {
    const session = await currentSession.unwrap();
    currentSession.unsubscribe();
    return session;
  } catch (e) {
    // Catch 401/403/etc.
    currentSession.unsubscribe();
    return null;
  }
};

export type AppRoute = RouteObject & {
  security?: RouteSecurity;
};

const getLoginRedirect = (redirectRoute: string, request: Request) => {
  const pathname = new URL(request.url)?.pathname;
  const params = new URLSearchParams();
  params.set('redirect', pathname);
  return redirect(`${redirectRoute}?${params.toString()}`);
};

export const getRouterRoute = ({
  security = { level: SecurityLevel.Public },
  ...routeProps
}: AppRoute): RouteObject => {
  switch (security.level) {
    case SecurityLevel.Public:
      return { ...routeProps };

    case SecurityLevel.NotLoggedIn:
      return {
        loader: async () => {
          const loggedIn = await isUserLoggedIn();
          if (loggedIn) {
            return redirect(`${security?.redirect ?? '/'}`);
          }
          return loggedIn;
        },
        ...routeProps,
      };

    case SecurityLevel.LoggedIn:
      return {
        loader: async ({ request }: LoaderFunctionArgs) => {
          const loggedIn = await isUserLoggedIn();
          if (!loggedIn) {
            return getLoginRedirect(security?.redirect ?? LOGIN_ROUTE, request);
          }
          return loggedIn;
        },
        ...routeProps,
      };

    case SecurityLevel.NotLoggedInOrNoSubscription:
      return {
        loader: async ({ request }: LoaderFunctionArgs) => {
          const loggedIn = await isUserLoggedIn();
          if (!loggedIn) {
            return null;
          }
          const subscription = await getUserSubscription(true);
          if (subscription === 'PreRegistered') {
            return redirect(`${SUBSCRIPTION_SIGN_UP_ROUTE}/success`);
          }
          if (subscription) {
            return redirect(`${security?.redirect ?? '/'}`);
          }
          return null;
        },
        ...routeProps,
      };

    case SecurityLevel.NoSubscription:
      return {
        loader: async ({ request }: LoaderFunctionArgs) => {
          const loggedIn = await isUserLoggedIn();
          if (!loggedIn) {
            return getLoginRedirect(LOGIN_ROUTE, request);
          }
          const subscription = await getUserSubscription(true);
          if (subscription === 'PreRegistered') {
            return redirect(`${SUBSCRIPTION_SIGN_UP_ROUTE}/success`);
          }
          if (subscription) {
            return redirect(`${security?.redirect ?? '/'}`);
          }
          return null;
        },
        ...routeProps,
      };

    case SecurityLevel.NoSubscriptionOrPreRegistered:
      return {
        loader: async ({ request }: LoaderFunctionArgs) => {
          const loggedIn = await isUserLoggedIn();
          if (!loggedIn) {
            return getLoginRedirect(LOGIN_ROUTE, request);
          }
          const subscription = await getUserSubscription(true);
          if (subscription && subscription !== 'PreRegistered') {
            return redirect(`${security?.redirect ?? '/'}`);
          }
          return null;
        },
        ...routeProps,
      };

    case SecurityLevel.Subscription:
      return {
        loader: async ({ request }: LoaderFunctionArgs) => {
          const loggedIn = await isUserLoggedIn();
          if (!loggedIn) {
            return getLoginRedirect(LOGIN_ROUTE, request);
          }
          const subscription = await getUserSubscription();
          if (!subscription) {
            return redirect(
              `${security?.redirect ?? SUBSCRIPTION_SIGN_UP_ROUTE}`
            );
          }
          return subscription;
        },
        ...routeProps,
      };

    case SecurityLevel.NoSession:
      return {
        loader: async ({ request }: LoaderFunctionArgs) => {
          const loggedIn = await isUserLoggedIn();
          if (!loggedIn) {
            return getLoginRedirect(LOGIN_ROUTE, request);
          }
          const subscription = await getUserSubscription();
          if (!subscription) {
            return redirect(`${SUBSCRIPTION_SIGN_UP_ROUTE}`);
          }
          const session = await getCurrentSession();
          if (session) {
            return redirect(`${security?.redirect ?? '/'}`);
          }
          return session;
        },
        ...routeProps,
      };

    case SecurityLevel.InSession:
      return {
        loader: async ({ request }: LoaderFunctionArgs) => {
          const loggedIn = await isUserLoggedIn();
          if (!loggedIn) {
            return getLoginRedirect(LOGIN_ROUTE, request);
          }
          const subscription = await getUserSubscription();
          if (!subscription) {
            return redirect(`${SUBSCRIPTION_SIGN_UP_ROUTE}`);
          }
          const session = await getCurrentSession();
          if (!session) {
            return redirect(`${security?.redirect ?? WAITING_ROOM_ROUTE}`);
          }
          return session;
        },
        ...routeProps,
      };
  }
};

export const createAppRouter = (routes: AppRoute[]) => {
  return createBrowserRouter([
    ...routes.map((route) => getRouterRoute(route)),
    {
      path: '*',
      element: <Navigate to='/' replace />,
    },
  ]);
};
