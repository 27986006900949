import { SVGProps } from 'react';
const SvgBasketDark = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={21}
    height={20}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M19.298 6.904H17.82l-3.736-5.608a1.01 1.01 0 1 0-1.68 1.12l2.99 4.488H5.03l2.993-4.488a1.01 1.01 0 1 0-1.68-1.12L2.604 6.904H1.125a1.01 1.01 0 0 0 0 2.02h1.01V17a2.02 2.02 0 0 0 2.019 2.02h12.115A2.019 2.019 0 0 0 18.29 17V8.923h1.01a1.01 1.01 0 0 0 0-2.019ZM7.183 15.486a1.01 1.01 0 0 1-2.02 0v-4.039a1.01 1.01 0 1 1 2.02 0v4.039Zm4.038 0a1.01 1.01 0 0 1-2.02 0v-4.039a1.01 1.01 0 1 1 2.02 0v4.039Zm4.039 0a1.01 1.01 0 0 1-2.02 0v-4.039a1.01 1.01 0 0 1 2.02 0v4.039Z'
      fill='#292929'
    />
  </svg>
);
export default SvgBasketDark;
