import { createGlobalState } from 'react-hooks-global-state';
import dayjs from 'dayjs';

export const { useGlobalState } = createGlobalState({
  commonInfo: {
    fisheryName: '',
    fisheryId: '',
    fisheryPublicId: '',
    lake: null,
    swim: '',
    date: dayjs(new Date()),
    time: '',
  },
  catchInfo: {
    species: '',
    speciesValue: '',
    fish_weight_lb: '',
    fish_weight_oz: '',
    bait: '',
    rig: '',
    personalBest: false,
    note: '',
    lakeDisable: true,
  },
  sessionNotesInfo: {
    noteTitle: '',
    noteDetail: '',
    lakeDisable: false,
  },
  anglerName: '',
  validating: false,
  current: 0,
  fileList: '',
  filePreview: [],
  itemRefs: {
    species: false,
    bait: false,
    rig: false,
    fishery: false,
    lake: false,
    swim: false,
    date: false,
    time: false,
  },
  sessionNotesItemsRef: {
    noteTitle: false,
    noteDetail: false,
    species: false,
    bait: false,
    rig: false,
    fishery: false,
    lake: false,
    swim: false,
    date: false,
    time: false,
  },
});
