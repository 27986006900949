import React from 'react';
import { Collapse } from 'antd';
import '../assets/css/quickLink.css';
import { PlusCircleFilled, MinusCircleFilled } from '@ant-design/icons';
import { FisheryDetails } from '../../../store/apiSlices/fisheryDetailsSlice';

import Items from './LInkItems';

export default function FisheryQuickLinks({
  fisheryDetails,
}: {
  fisheryDetails?: FisheryDetails;
}): React.JSX.Element {
  return (
    <div className='quicklink-container relative mt-8 md:mt-0'>
      <div className='py-4'>
        <Collapse
          items={Items(fisheryDetails)}
          accordion
          expandIcon={({ isActive }) =>
            isActive ? (
              <div>
                <MinusCircleFilled className='!text-xl !text-[#4E4E4E] ' />
              </div>
            ) : (
              <PlusCircleFilled className='!text-xl !text-[#4E4E4E]' />
            )
          }
          className='quicklink-collapse  border-0 bg-inherit'
          expandIconPosition='end'
        />
      </div>
    </div>
  );
}
