import BaseSlider from './BaseSlider';
import { Col } from 'antd';
import AvatarSliderCard from './AvatarSliderCard';
import { RightOutlined } from '@ant-design/icons';
import userPlaceholder from '../assets/media/placeholder_user.png';
import { getUserSummaryLine } from './AvatarSliderCard';

import { Reviews } from '../../../../store/apiSlices/fisheryDetailsSlice';

interface ReviewsSliderProps {
  reviewsData: Reviews[];
  fisheryPublicId: string | number;
  loading?: boolean;
}

export function ReviewsSlider({
  reviewsData,
  fisheryPublicId,
  loading = false,
}: ReviewsSliderProps) {
  return (
    <BaseSlider
      horizontal
      extra=''
      showPartialNext
      noHoverAnimation
      hideButtonsOnMobile
      infinite={false}
    >
      {reviewsData?.length > 0
        ? reviewsData?.map((data, index) => (
            <Col className='slider-content' key={index} span={24}>
              <AvatarSliderCard
                href={`${
                  process.env.REACT_APP_CORE_SITE_URL
                }/fishery/${fisheryPublicId}/reviews/${index + 1}`}
                image={{
                  src: data?.profile_image || userPlaceholder,
                  alt: data?.first_name + ' ' + data?.last_name,
                }}
                title={data?.first_name + ' ' + data?.last_name}
                postTitle={getUserSummaryLine(data)}
                extra={
                  <>
                    Show more <RightOutlined />
                  </>
                }
              >
                <p className=' review-slider-preview mb-[5px]'>
                  {data?.comment}
                </p>
              </AvatarSliderCard>
            </Col>
          ))
        : null}
    </BaseSlider>
  );
}

export default ReviewsSlider;
