import { SVGProps } from 'react';
const DotIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 16 16'
    fill='none'
    {...props}
  >
    <circle cx={4.5} cy={4.5} r={4.5} fill='currentColor' />
  </svg>
);
export default DotIcon;
