import { SVGProps } from 'react';
const SvgDinner = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={28}
    height={28}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M14.447 4.206a9.273 9.273 0 1 0 0 18.546 9.273 9.273 0 0 0 0-18.546Zm0 16.309a7.044 7.044 0 0 1-7.036-7.036 7.044 7.044 0 0 1 7.036-7.036 7.044 7.044 0 0 1 7.036 7.036 7.044 7.044 0 0 1-7.036 7.036Z'
      fill='#fff'
    />
    <path
      d='M14.447 8.329c-2.84 0-5.15 2.31-5.15 5.15 0 2.84 2.31 5.15 5.15 5.15 2.84 0 5.15-2.31 5.15-5.15 0-2.84-2.31-5.15-5.15-5.15ZM27.416 14.256V2.988a.253.253 0 0 0-.455-.153 10.664 10.664 0 0 0-2.167 6.444V13.4c0 .473.383.856.856.856h.357c-.352 3.634-.585 8.716-.585 9.31 0 .938.578 1.698 1.29 1.698.711 0 1.288-.76 1.288-1.697 0-.595-.232-5.678-.584-9.312ZM3.742 12.023a2.464 2.464 0 0 0 1.19-2.11l-.354-5.777a.417.417 0 0 0-.833.047l.23 4.632a.471.471 0 0 1-.943 0l-.115-4.63a.451.451 0 0 0-.902 0L1.9 8.815a.471.471 0 0 1-.943 0l.23-4.632a.418.418 0 0 0-.834-.047L0 9.913c0 .894.477 1.678 1.19 2.11.464.281.723.807.663 1.346-.403 3.629-.676 9.554-.676 10.199 0 .937.577 1.697 1.289 1.697s1.289-.76 1.289-1.697c0-.645-.274-6.57-.677-10.199-.06-.54.2-1.065.664-1.346Z'
      fill='#fff'
    />
  </svg>
);
export default SvgDinner;
