import { Col, Row } from 'antd';
import Carousel, { ResponsiveType } from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import React, { ReactNode, useCallback, useRef, useState } from 'react';
import '../assets/css/sliders.css';
import ButtonGroup from './ButtonGroup';

import {
  horizontalBreakpoints,
  verticalBreakpoints,
} from '../assets/breakpoints';
import { CarouselStateType } from './ButtonGroup';

const preventDefault = (e: Event) => e.preventDefault();

// Detect whether the browser supports "wheel" or "mousewheel" events
const wheelEvent =
  'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

/**
 * Base responsive card slider using react-multi-carousel
 * @param children {Array<JSX.Element>} The slide elements to be rendered
 * @param className {string | null} (optional) classes to apply to the slider
 * @param fullWidth {boolean} (optional) Whether the slider always shows 1 full-width card (non-responsive number of cards). Should not be specified along with breakpointsOverride
 * @param noHoverAnimation {boolean} (optional) Whether to disable the on hover/click user feedback animation
 * @param horizontal {boolean} (optional) Whether the slider user horizontal (wide) cards or vertical (tall) cards
 * @param hideButtons {boolean} (optional) Whether to hide the left-right buttons
 * @param hideButtonsOnMobile {boolean} (optional) Whether to hide the left-right buttons on mobile devices (recommended if fullWidth=true)
 * @param infinite {boolean} (optional) Whether the slider repeats (is an infinite loop) -- default: true
 * @param showPartialNext {boolean} (optional) Whether to show a partial preview of the next card (often used alongside hideButtons)
 * @param extra {string | null} (optional) The text to show below the slider (default: 'Swipe for more ...')
 * @param extraClassName {string | null} (optional) classes to apply to the extra text
 * @param breakpointsOverride {
 *     [key: string]: {
 *         breakpoint: {
 *             max: number;
 *             min: number;
 *         };
 *         items: number;
 *         partialVisibilityGutter?: number;
 *         slidesToSlide?: number;
 *     } | null} (optional) Allows specification of breakpoints different from the default. Should not be specified along with fullWidth
 * @param onChange {(currentIndex: number) => void} (optional) A function to carry out when the slider changes (index may be inaccurate when infinite=true)
 * @returns {JSX.Element}
 */
interface BaseSliderProps {
  children: ReactNode;
  fullWidth?: boolean;
  noHoverAnimation?: boolean;
  horizontal?: boolean;
  hideButtons?: boolean;
  hideButtonsOnMobile?: boolean;
  infinite?: boolean;
  showPartialNext?: boolean;
  extra?: string;
  extraClassName?: string;
  className?: string;
  breakpointsOverride?: ResponsiveType;
  onChange?: (currentIndex: number) => void;
  preventScrollThresholdPx?: number;
  preventScrollThresholdRatio?: number;
}
export default function BaseSlider({
  children,
  fullWidth = false,
  noHoverAnimation = false,
  horizontal = false,
  hideButtons = false,
  hideButtonsOnMobile = false,
  infinite = true,
  showPartialNext = false,
  extra = 'Swipe for more ...',
  extraClassName = '',
  className = '',
  breakpointsOverride = undefined,
  onChange = () => {},
  preventScrollThresholdPx = 10,
  preventScrollThresholdRatio = 0.75,
}: BaseSliderProps) {
  const childrenLength = (children as React.JSX.Element[])?.length;
  const touchStartPos: React.MutableRefObject<{ x: number; y: number }> =
    useRef({ x: 0, y: 0 });
  const verticalScrollDisabled = useRef(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [areButtonsUnnecessary, setAreButtonsUnnecessary] = useState(
    childrenLength <= 1
  );

  const onStateChange = useCallback(
    (carouselState: CarouselStateType) => {
      if (!carouselState) {
        return;
      }
      const { slidesToShow } = carouselState;
      setAreButtonsUnnecessary(childrenLength <= slidesToShow);
    },
    [childrenLength]
  );

  let breakpoints = breakpointsOverride;
  if (!breakpoints) {
    if (fullWidth) {
      breakpoints = {
        singleItem: {
          breakpoint: { max: 10000, min: 0 },
          items: 1,
          slidesToSlide: 1,
          partialVisibilityGutter: 0,
        },
      };
    } else if (horizontal) {
      breakpoints = horizontalBreakpoints;
    } else {
      breakpoints = verticalBreakpoints;
    }
  }

  const shouldShowPartialNext = showPartialNext && !areButtonsUnnecessary;

  const disableVerticalScroll = useCallback(() => {
    const html = document.getElementsByTagName('html')[0];
    html.style.overflow = 'hidden';
    html.style.overscrollBehavior = 'none';
    html.style.touchAction = 'none';
    const body = document.body;
    body.style.overflow = 'hidden';
    body.style.overscrollBehavior = 'none';
    body.style.touchAction = 'none';
    // Fix for issue on Safari
    window.addEventListener('DOMMouseScroll', preventDefault, {
      passive: false,
    });
    window.addEventListener(wheelEvent, preventDefault, { passive: false });
    window.addEventListener('touchmove', preventDefault, { passive: false });

    verticalScrollDisabled.current = true;
  }, []);

  const enableVerticalScroll = useCallback(() => {
    const html = document.getElementsByTagName('html')[0];
    html.style.overflow = 'initial';
    html.style.overscrollBehavior = 'auto';
    html.style.touchAction = 'auto';
    const body = document.body;
    body.style.overflow = 'initial';
    body.style.overscrollBehavior = 'auto';
    body.style.touchAction = 'auto';
    // Fix for issue on Safari
    window.removeEventListener('DOMMouseScroll', preventDefault);
    window.removeEventListener(wheelEvent, preventDefault);
    window.removeEventListener('touchmove', preventDefault);
    verticalScrollDisabled.current = false;
  }, []);

  return (
    <Row
      className={
        'slider-wrapper m-0 w-full ' +
        (horizontal ? 'horizontal ' : 'vertical ') +
        (fullWidth ? 'fullWidth ' : '') +
        (shouldShowPartialNext ? 'partialNext ' : '') +
        (hideButtons || areButtonsUnnecessary ? 'hideButtons ' : '') +
        (hideButtonsOnMobile ? 'hideButtonsOnMobile ' : '') +
        (noHoverAnimation ? 'noHover ' : '') +
        className
      }
      ref={wrapperRef}
    >
      <Col className='gutter-row slider br20' span={24}>
        <div
          onTouchStart={(e) => {
            touchStartPos.current = {
              x: e.touches[0].clientX,
              y: e.touches[0].clientY,
            };
            if (
              preventScrollThresholdPx === 0 ||
              preventScrollThresholdRatio === 0
            ) {
              // Uses setTimeout to push code into a different execution flow (run asynchronously) to help
              // prevent jittery slider movement
              setTimeout(disableVerticalScroll, 0);
            }
          }}
          onTouchMove={(e) => {
            const touchEndPos = {
              x: e.changedTouches[0].clientX,
              y: e.changedTouches[0].clientY,
            };
            const deltaX = Math.abs(touchEndPos.x - touchStartPos?.current?.x);
            const deltaY = Math.abs(touchEndPos.y - touchStartPos?.current?.y);
            if (
              !verticalScrollDisabled.current &&
              deltaX > preventScrollThresholdPx &&
              deltaX / deltaY > preventScrollThresholdRatio
            ) {
              // Uses setTimeout to push code into a different execution flow (run asynchronously) to help
              // prevent jittery slider movement
              setTimeout(disableVerticalScroll, 0);
            }
          }}
          onTouchEnd={() => {
            setTimeout(enableVerticalScroll, 500);
          }}
        >
          <Carousel
            swipeable={!areButtonsUnnecessary}
            responsive={breakpoints}
            infinite={infinite}
            customButtonGroup={
              <ButtonGroup
                infinite={infinite}
                numSlides={childrenLength}
                onChange={onChange}
                onStateChange={
                  onStateChange /* Used as a way to access the carouselState outside useRef */
                }
              />
            }
            renderButtonGroupOutside={true}
            rewindWithAnimation={true}
            partialVisible={shouldShowPartialNext}
            arrows={false}
          >
            {children}
          </Carousel>
        </div>
      </Col>

      {extra && (
        <Col
          xs={24}
          sm={0}
          className={
            'slider-extra text-center text-[14px] font-light ' + extraClassName
          }
        >
          {extra}
        </Col>
      )}
    </Row>
  );
}
