import { Row, Col, Button } from 'antd';

import youtubeIcon from '../assets/media/yt_logo_rgb_full.png';
import swinbookerIcon from '../assets/media/swimbooker_logo.png';
import pin from '../assets/media/so-pin.svg';

import cursor from '../assets/media/so-cursor.svg';
import call from '../assets/media/so-calling.svg';
import camera from '../assets/media/so-camera.svg';
import writing from '../assets/media/so-writing.svg';
import map from '../assets/media/so-map.svg';
import addressMap from '../assets/media/address-map.svg';
import what3Word from '../assets/media/w3w_Symbol_RGB_Red.svg';
import FisheryIconNormal from '../assets/media/Blue.png';

import GoogleMapReact from 'google-map-react';

import { ReviewsHeader } from '../../../common/components/ReviewsVisualisation';
import { ReviewsSlider } from '../../../common/components/Slider';
import { PlaceholderImage } from '../../../common/components/PlaceholderImage';

import { FisheryDetails } from '../../../store/apiSlices/fisheryDetailsSlice';

const ItemClassname =
  'mb-2 !rounded-2xl bg-white !border-b-0 shadow-[0px_3px_6px_rgba(0,0,0,0.15)]';
const bottomButtonsStyle =
  ' font-semibold !rounded-xl text-xl !text-white text-center w-full p-2.5 mb-2.5 h-fit ';

const Marker = () => (
  <div className=' flex items-center '>
    <img className='w-10' src={FisheryIconNormal} alt='marker' />
  </div>
);

const Items = (fisheryData?: FisheryDetails) => [
  {
    key: 'rules',
    label: (
      <div className=' mx-2 flex gap-4 text-xl font-semibold'>
        <img src={pin} alt='pin' className='!text-3xl' />
        Fishery Rules
      </div>
    ),
    children: (
      <div>
        <p>{fisheryData?.rules}</p>
      </div>
    ),
    className: ItemClassname,
  },
  {
    key: 'sitemap',
    label: (
      <div className=' mx-2 flex gap-4 text-xl font-semibold'>
        <img src={map} alt='map' className='fill-blue !text-3xl' />
        Sitemap
      </div>
    ),
    children: (
      <PlaceholderImage
        className=' max-w-full rounded-xl object-cover'
        src={fisheryData?.images?.sitemap}
        alt={'Site Map for ' + fisheryData?.venue_name}
      />
    ),

    className: ItemClassname,
  },
  {
    key: 'media',
    label: (
      <div className=' mx-2 flex gap-4 text-xl font-semibold'>
        <img src={camera} alt='camera' className='!text-3xl' />
        SB Media
      </div>
    ),
    children: (
      <>
        <div>
          <Row justify='space-between' className='flex-nowrap pb-4'>
            <Col className='mr-4'>
              <div>
                <div className='text-[22px] font-semibold'>
                  swim
                  <span className='text-blue'>view</span>&trade;
                </div>
                <div className='text-base'>
                  An anglers perspective from all swims at the fishery!
                </div>
              </div>
            </Col>
            <Col className='flex flex-col items-center justify-center gap-y-1.5'>
              <img
                src={swinbookerIcon}
                alt='swimbooker'
                className='h-6 w-auto'
              />
              <Button
                href={`http://www.swimbooker.com/fishery/${fisheryData?.public_id}/swimview`}
                className='flex cursor-pointer items-center bg-blue px-10 py-1.5 text-lg font-semibold !text-white'
              >
                Visit
              </Button>
            </Col>
          </Row>
        </div>
        {fisheryData?.media?.virtual_experience && (
          <div className='border-t border-solid border-[#d9d9d9]'>
            <Row justify='space-between' className='flex-nowrap py-4'>
              <Col className='mr-4'>
                <div>
                  <div className='text-[22px] font-semibold text-[#468149]'>
                    Virtual Experience
                  </div>
                  <div className='text-base'>
                    A 360 Tour from hotspots around the fishery.
                  </div>
                </div>
              </Col>
              <Col className='flex flex-col items-center justify-center gap-y-1.5'>
                <img
                  src={swinbookerIcon}
                  alt='swimbooker'
                  className='h-6 w-auto'
                />
                <Button
                  href={fisheryData?.media?.virtual_experience}
                  className='flex cursor-pointer items-center bg-blue px-10 py-1.5 text-lg font-semibold !text-white'
                >
                  Visit
                </Button>
              </Col>
            </Row>
          </div>
        )}
        {fisheryData?.media?.sb_diaries && (
          <div className='border-t border-solid border-[#d9d9d9]'>
            <Row justify='space-between' className='flex-nowrap py-4'>
              <Col className='mr-4'>
                <div>
                  <div className='text-[22px] font-semibold '>SB Diaries</div>
                  <div className='text-base'>
                    Our very own team showing you around and providing all the
                    info you need while fishing!
                  </div>
                </div>
              </Col>
              <Col className='flex flex-col items-center justify-center gap-y-1.5'>
                <img src={youtubeIcon} alt='youtube' className='h-4 w-auto' />
                <Button
                  href={fisheryData?.media?.sb_diaries}
                  className='flex cursor-pointer items-center bg-blue px-10 py-1.5 text-lg font-semibold !text-white'
                >
                  Visit
                </Button>
              </Col>
            </Row>
          </div>
        )}
      </>
    ),

    className: ItemClassname,
  },
  {
    key: 'review',
    label: (
      <div className=' mx-2 flex gap-4 text-xl font-semibold'>
        <img src={writing} alt='writing' className='!text-3xl' />
        Reviews
      </div>
    ),
    children: (
      <>
        <ReviewsHeader
          score={fisheryData?.rating?.score}
          numReviews={
            fisheryData?.comments?.length || fisheryData?.rating?.total_reviews
          }
        />
        {fisheryData?.comments && (
          <ReviewsSlider
            reviewsData={fisheryData?.comments}
            fisheryPublicId={fisheryData?.public_id}
            loading={!fisheryData}
          />
        )}
        <div className='fishery_profile__reviews_buttons mt-2.5'>
          <Button
            href={`${process.env.REACT_APP_CORE_SITE_URL}/fishery/${fisheryData?.public_id}/new-review`}
            className={'bg-blue ' + bottomButtonsStyle}
          >
            Leave a review
          </Button>
        </div>
      </>
    ),

    className: ItemClassname,
  },
  {
    key: 'location',
    label: (
      <div className=' mx-2 flex gap-4 text-xl font-semibold'>
        <img src={cursor} alt='cursor' className='!text-3xl' />
        Location
      </div>
    ),
    children: (
      <>
        {fisheryData?.address && (
          <div className='fishery_profile__contact_wrapper__map mb-4 rounded-xl'>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: process.env.REACT_APP_GOOGLE_API_KEY || '',
              }}
              defaultCenter={{
                lat: fisheryData?.address?.location?.lat,
                lng: fisheryData?.address?.location?.lng,
              }}
              defaultZoom={10}
              options={{
                gestureHandling: 'none',
                fullscreenControl: false,
                zoomControl: false,
              }}
            >
              <Marker />
            </GoogleMapReact>
          </div>
        )}
        <div className='mb-4'>
          <div className='mb-2 flex gap-2 text-base font-semibold'>
            <img src={addressMap} alt='address-map' />
            Address:
          </div>
          <div>
            {fisheryData?.address?.city && (
              <div>{fisheryData?.address?.city},</div>
            )}
            {fisheryData?.address?.line1 && (
              <div>{fisheryData?.address?.line1},</div>
            )}
            {fisheryData?.address?.line2 && (
              <div>{fisheryData?.address?.line2},</div>
            )}
            {fisheryData?.address?.postcode && (
              <div>{fisheryData?.address?.postcode},</div>
            )}
          </div>
        </div>
        {fisheryData?.what3words?.words && (
          <div className='border-t border-solid border-[#d9d9d9] pt-4'>
            <div className='mb-2 flex gap-2 text-base font-semibold'>
              <img src={what3Word} alt='what3word' />
              What3Words:
            </div>
            <div>{fisheryData?.what3words?.words}</div>
          </div>
        )}
      </>
    ),

    className: ItemClassname,
  },
  // {
  //   key: 'hours',
  //   label: (
  //     <div className=' mx-2 flex gap-4 text-xl font-semibold'>
  //       <img src={clock} alt='clock' className='!text-3xl' />
  //       Opening Hours
  //     </div>
  //   ),
  //   children: null,

  //   className: ItemClassname,
  // },
  {
    key: 'contact',
    label: (
      <div className=' mx-2 flex gap-4 text-xl font-semibold'>
        <img src={call} alt='call' className='!text-3xl' />
        Emergency Contact Details
      </div>
    ),
    children: (
      <>
        <div className='mb-[10px] text-xl font-semibold'>
          {' '}
          Fishery:
          <span className='ml-[5px] font-light'>
            {fisheryData?.contact?.primary_contact}
          </span>
        </div>
        <div className='text-xl font-semibold'>
          {' '}
          Swimbooker:
          <span className='ml-[5px] font-light'>0208 194 9396</span>
        </div>
      </>
    ),

    className: ItemClassname,
  },
];
export default Items;
