import React from 'react';
import BaseSlider from './BaseSlider';
import { ResponsiveType } from 'react-multi-carousel';
import { SliderCardImage } from '../../PlaceholderImage';

export interface Feature {
  src: string;
  title: string;
  description?: string;
}

interface FeatureImageSliderProps {
  className?: string;
  features: Feature[];
}

export default function FeatureImageSlider({
  features,
  className = '',
}: FeatureImageSliderProps): React.JSX.Element {
  const breakpoints: ResponsiveType = {
    desktop: {
      breakpoint: { max: 10000, min: 769 },
      items: 2,
      slidesToSlide: 1,
      partialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 768, min: 577 },
      items: 2,
      slidesToSlide: 1,
      partialVisibilityGutter: 20,
    },
    smallTablet: {
      breakpoint: { max: 576, min: 501 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 180,
    },
    largeMobile: {
      breakpoint: { max: 500, min: 421 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 120,
    },
    mobile: {
      breakpoint: { max: 420, min: 151 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 100,
    },
    smallMobile: {
      breakpoint: { max: 420, min: 301 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 70,
    },
    extraSmallMobile: {
      breakpoint: { max: 300, min: 0 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 50,
    },
  };

  return (
    <BaseSlider
      className={className}
      horizontal
      breakpointsOverride={breakpoints}
      noHoverAnimation
      showPartialNext
      hideButtonsOnMobile
      extra=''
      preventScrollThresholdPx={0}
      preventScrollThresholdRatio={0.0}
    >
      {features.map((feature, index) => (
        <div className='horizontal flex flex-col gap-1' key={index}>
          <SliderCardImage
            src={feature.src}
            alt={feature.title}
            className='mb-2'
          />
          <h3 className='text-base font-semibold leading-[1.2] text-grey-200'>
            {feature.title}
          </h3>
          {feature?.description && (
            <p className='text-xs font-medium leading-[1.3] text-grey-300'>
              {feature.description}
            </p>
          )}
        </div>
      ))}
    </BaseSlider>
  );
}
