import { SVGProps } from 'react';
const SvgLike = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={23}
    height={23}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g clipPath='url(#like_svg__a)' fill='#fff'>
      <path d='M1.677 22.042h2.396c.925 0 1.677-.753 1.677-1.678V9.345c0-.925-.752-1.677-1.677-1.677H1.677C.752 7.667 0 8.419 0 9.344v11.02c0 .925.752 1.678 1.677 1.678ZM12.248.719c-.958 0-1.437.479-1.437 2.875 0 2.277-2.205 4.11-3.623 5.053v11.872c1.534.71 4.605 1.762 9.373 1.762h1.533A3.827 3.827 0 0 0 21.87 19.1l1.073-6.23a3.831 3.831 0 0 0-3.775-4.485h-4.524s.719-1.437.719-3.833c0-2.875-2.156-3.833-3.114-3.833Z' />
    </g>
    <defs>
      <clipPath id='like_svg__a'>
        <path fill='#fff' d='M0 0h23v23H0z' />
      </clipPath>
    </defs>
  </svg>
);
export default SvgLike;
