import { ReactNode } from 'react';

import './assets/css/index.css';

const DesktopLayout = ({ children }: { children: ReactNode }) => (
  <div className='home-outer-container-desktop home-outer-container-mobile grid min-h-screen place-content-center bg-[#f4f4f4]'>
    <div className='home-main-container-desktop home-main-container-mobile relative'>
      {children}
    </div>
  </div>
);

export default DesktopLayout;
