import { SVGProps } from 'react';
const SvgWalletDark = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={21}
    height={21}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M19.568 17.182h-3.341a3.822 3.822 0 0 1-3.818-3.818 3.822 3.822 0 0 1 3.818-3.818h3.34a.477.477 0 0 0 .478-.478V7.637c0-1.001-.776-1.815-1.757-1.894L15.548.955a1.886 1.886 0 0 0-2.592-.698l-9.397 5.47H1.91C.856 5.727 0 6.584 0 7.637V19.09C0 20.144.856 21 1.91 21h16.226c1.053 0 1.91-.856 1.91-1.91v-1.43a.477.477 0 0 0-.478-.478Zm-3.429-13.27 1.04 1.815h-4.16l3.12-1.816ZM5.456 5.726l7.98-4.645a.936.936 0 0 1 1.282.348l.001.002-7.378 4.295H5.456Z'
      fill='#fff'
    />
    <path
      d='M19.568 10.5h-3.341a2.867 2.867 0 0 0-2.864 2.864 2.867 2.867 0 0 0 2.864 2.863h3.34c.79 0 1.433-.642 1.433-1.431v-2.864c0-.79-.643-1.432-1.432-1.432Zm-3.341 3.818a.956.956 0 0 1-.955-.954.956.956 0 0 1 1.91 0 .956.956 0 0 1-.955.954Z'
      fill='#fff'
    />
  </svg>
);
export default SvgWalletDark;
