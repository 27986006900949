import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../utils';

export const proApiSlice = createApi({
  baseQuery: axiosBaseQuery({
    baseURL: `${process.env.REACT_APP_PRO_URL}`,
  }),
  reducerPath: 'proApi',
  tagTypes: [],
  endpoints: () => ({}),
});
