import { default as AvivaLogo } from './AvivaLogo';
import { default as Bag } from './Bag';
import { default as Bandage } from './Bandage';
import { default as BasketDark } from './BasketDark';
import { default as Cashback } from './Cashback';
import { default as Chat } from './Chat';
import { default as CoinsDark } from './CoinsDark';
import { default as Crown } from './Crown';
import { default as Dinner } from './Dinner';
import { default as FeaturesHand } from './FeaturesHand';
import { default as Fish } from './Fish';
import { default as GiftDark } from './GiftDark';
import { default as GiftCard } from './GiftCard';
import { default as Handshake } from './Handshake';
import { default as HookDark } from './HookDark';
import { default as Like } from './Like';
import { default as MoneyTransfer } from './MoneyTransfer';
import { default as Network } from './Network';
import { default as NoTickets } from './NoTickets';
import { default as Package } from './Package';
import { default as Percent } from './Percent';
import { default as Rod } from './Rod';
import { default as ShieldDark } from './ShieldDark';
import { default as Signal } from './Signal';
import { default as Store } from './Store';
import { default as TicketDark } from './TicketDark';
import { default as Wallet } from './Wallet';
import { default as WalletDark } from './WalletDark';

const FeatureIcons = {
  AvivaLogo,
  Bag,
  Bandage,
  Cashback,
  Chat,
  Crown,
  Dinner,
  FeaturesHand,
  Fish,
  GiftCard,
  Handshake,
  Like,
  MoneyTransfer,
  Network,
  NoTickets,
  Package,
  Percent,
  Rod,
  Signal,
  Store,
  Wallet,

  Dark: {
    Basket: BasketDark,
    Hook: HookDark,
    Gift: GiftDark,
    Coins: CoinsDark,
    Shield: ShieldDark,
    Ticket: TicketDark,
    Wallet: WalletDark,
  },
};
export default FeatureIcons;
