import React from 'react';
import { Col, Row, Input, AutoComplete, Radio } from 'antd';
import { useGlobalState } from './ReportsGlobalState';

import { StepTwoProps, SessionNotesFocusType } from '../types';

function Step2({ focus, isCatchReport }: StepTwoProps) {
  const [catchInfo, setCatchInfo] = useGlobalState('catchInfo');
  const [sessionNotesInfo, setSessionNotesInfo] =
    useGlobalState('sessionNotesInfo');
  const [validating] = useGlobalState('validating');
  const SpeciesOption = [
    'Common Carp',
    'Mirror Carp',
    'Grass Carp',
    'Leather Carp',
    'Ghost Carp',
    'Crucian Carp',
    'Koi Carp',
    'Catfish',
    'Bream',
    'Tench',
    'Roach',
    'Rudd',
    'Perch',
    'Dace',
    'Brown Trout',
    'Rainbow Trout',
    'Pumpkinseed',
    'Eel',
    'Pike',
    'Chub',
    'Sturgeon',
    'Barbel',
    'Grayling',
    'Goldfish',
    'Orph',
  ];

  if (!isCatchReport) {
    return (
      <React.Fragment>
        <Input
          placeholder='Note Title'
          status={validating && !sessionNotesInfo.noteTitle ? 'error' : ''}
          size='large'
          value={sessionNotesInfo.noteTitle}
          autoFocus={(focus as SessionNotesFocusType).noteTitle}
          onChange={(e) => {
            setSessionNotesInfo((prevState) => ({
              ...prevState,
              noteTitle: e.target.value,
            }));
          }}
          className='mt-4  w-full rounded-[5px]'
        />

        <Input.TextArea
          placeholder='Note Details (Use this space to record notes of your session e.g fish showing, swim hotspots, etc.)'
          value={sessionNotesInfo.noteDetail}
          autoFocus={(focus as SessionNotesFocusType).noteDetail}
          status={validating && !sessionNotesInfo.noteDetail ? 'error' : ''}
          onChange={(e) => {
            setSessionNotesInfo((prevState) => ({
              ...prevState,
              noteDetail: e.target.value,
            }));
          }}
          className='mt-4 !h-[300px] text-base'
          key='note'
        />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <AutoComplete
        placeholder='Fish Species'
        status={validating && !catchInfo.species ? 'error' : ''}
        value={catchInfo.speciesValue}
        size='large'
        autoFocus={focus.species}
        options={SpeciesOption.map((item) => ({
          value: item,
          label: item,
        }))}
        filterOption={true}
        onSelect={(value) => {
          setCatchInfo((prevState) => ({
            ...prevState,
            species: value,
            speciesValue: value,
          }));
        }}
        className='mt-4  w-full rounded-[5px]'
        onChange={(value) => {
          setCatchInfo((prevState) => ({
            ...prevState,
            species: '',
            speciesValue: value,
          }));
        }}
      />

      <Input
        placeholder='Fish Weight (lbs)'
        type='number'
        status={validating && !catchInfo.fish_weight_lb ? 'error' : ''}
        value={catchInfo.fish_weight_lb}
        onChange={(e) => {
          setCatchInfo((prevState) => ({
            ...prevState,
            fish_weight_lb: e.target.value,
          }));
        }}
        size='large'
        className='mt-4  w-full rounded-[5px]'
      />
      <Input
        type='number'
        max={16}
        placeholder='Fish Weight (oz)'
        status={validating && !catchInfo.fish_weight_oz ? 'error' : ''}
        value={catchInfo.fish_weight_oz}
        onChange={(e) => {
          if (Number(e.target.value) <= 16 && Number(e.target.value) >= 0) {
            setCatchInfo((prevState) => ({
              ...prevState,
              fish_weight_oz: e.target.value,
            }));
          }
        }}
        size='large'
        className='mt-4  w-full rounded-[5px]'
      />
      <Input
        placeholder='Bait Used (optional)'
        size='large'
        value={catchInfo.bait}
        autoFocus={focus.bait}
        onChange={(e) => {
          setCatchInfo((prevState) => ({
            ...prevState,
            bait: e.target.value,
          }));
        }}
        className='mt-4  w-full rounded-[5px]'
      />
      <Input
        placeholder='Rig Used (optional)'
        value={catchInfo.rig}
        autoFocus={focus.rig}
        onChange={(e) => {
          setCatchInfo((prevState) => ({
            ...prevState,
            rig: e.target.value,
          }));
        }}
        size='large'
        className='mt-4  w-full rounded-[5px]'
      />
      <Col span={24}>
        <Row justify='space-between' className='mt-4 w-full'>
          <Col span={11} className='flex items-center font-light '>
            Is this a new PB?
          </Col>
          <Col span={12}>
            <Radio.Group
              value={catchInfo.personalBest}
              onChange={(e) => {
                setCatchInfo((prevState) => ({
                  ...prevState,
                  personalBest: e.target.value,
                }));
              }}
              size='large'
              className='float-right'
              id='PB-button'
            >
              <Radio.Button
                value={true}
                className='gRegular'
                style={{ padding: '0 2rem' }}
              >
                {`  Yes!  `}
              </Radio.Button>
              <Radio.Button
                value={false}
                className='gRegular'
                style={{ padding: '0 2rem' }}
              >
                {`  No  `}
              </Radio.Button>
            </Radio.Group>
          </Col>
        </Row>
      </Col>
      <Input.TextArea
        placeholder='Notes (optional) Here you can keep track of things like: Wind Direction, Temperature, Air Pressure, Moon Phase etc.'
        value={catchInfo.note}
        onChange={(e) => {
          setCatchInfo((prevState) => ({
            ...prevState,
            note: e.target.value,
          }));
        }}
        className='mt-4 !h-[200px] text-base'
        key='note'
      />
    </React.Fragment>
  );
}

export default Step2;
