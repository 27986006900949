import { Button } from 'antd';

import checked from '../../../assets/icons/checked.svg';

interface SuccessProps {
  setIsOpen: React.Dispatch<
    React.SetStateAction<{ isOpen: boolean; isCatchReport: boolean }>
  >;
  isCatchReport?: boolean;
}
function Success({ setIsOpen, isCatchReport = false }: SuccessProps) {
  return (
    <div className='success_container bg-white text-center'>
      <div className='mb-2.5 flex justify-center'>
        <img src={checked} alt='checked' width={100} />
      </div>
      <h1 className=' mb-[30px] text-center text-4xl font-semibold'>
        {isCatchReport
          ? 'Catch Report Submitted Successfully!'
          : 'Session Note Saved Successfully!'}
      </h1>
      <p className='mb-2.5 text-2xl font-semibold'>What happens next?</p>
      <p className=' mb-[30px] text-xl'>
        {isCatchReport
          ? 'Once the fishery verifies the catch report it will automatically be added to the fishery profile.'
          : ' You can review your current session notes in the InSession menu. Once your session is complete, your previous session notes will be stored in your Sessions folder!'}
      </p>
      {isCatchReport ? (
        <Button
          className='mb-2.5  h-fit w-full rounded-[20px] bg-blue p-2.5 px-[28px] text-center  text-xl font-semibold text-white '
          onClick={() => {}}
        >
          Visit Fishery Profile
        </Button>
      ) : null}
      <Button
        className='mb-2.5  h-fit w-full rounded-[20px] bg-[#bababa] p-2.5 px-[28px] text-center  text-xl font-semibold text-white '
        onClick={() => {
          setIsOpen({ isOpen: false, isCatchReport: false });
        }}
      >
        Close
      </Button>
    </div>
  );
}

export default Success;
