import { SVGProps } from 'react';

const StoreSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='25.048'
    height='27.4'
    viewBox='0 0 25.048 27.4'
  >
    <path
      style={{ fill: 'currentColor' }}
      id='so-store'
      d='M27.75,7.624A4.247,4.247,0,0,1,24.51,12.9a4.152,4.152,0,0,1-4.878-3.741l-.058-.685a4.151,4.151,0,0,1-4.133,4.491h-.112a4.151,4.151,0,0,1-4.136-4.495l-.042.514a4.276,4.276,0,0,1-3.877,3.973A4.151,4.151,0,0,1,2.974,7.8L4.166,3.037A1.37,1.37,0,0,1,5.5,2h19.78A1.37,1.37,0,0,1,26.6,3.037ZM23.771,15.7a6.879,6.879,0,0,0,2.574-.506V28.03a1.37,1.37,0,0,1-1.37,1.37H5.8a1.37,1.37,0,0,1-1.37-1.37V15.194a6.732,6.732,0,0,0,6.722-.936A7.029,7.029,0,0,0,15.443,15.7a6.887,6.887,0,0,0,4.18-1.441A6.818,6.818,0,0,0,23.771,15.7Zm-5.646,6.85a2.74,2.74,0,0,0-5.48,0v4.11h5.48Z'
      transform='translate(-2.851 -2)'
      fill='#5ea8e5'
    />
  </svg>
);
export default StoreSVG;
