import React from 'react';
import fishPanel from '../assets/media/feature-slider-panels/fish-catch.png';
import giveawayPanel from '../assets/media/feature-slider-panels/giveaways.webp';
import insurancePanel from '../assets/media/feature-slider-panels/insurance.webp';
import perksPanel from '../assets/media/feature-slider-panels/perks.webp';
import trustpilotPanel from '../assets/media/feature-slider-panels/trustpilot.webp';
import cashbackPanel from '../assets/media/feature-slider-panels/cashback.webp';
import facebookPanel from '../assets/media/feature-slider-panels/facebook.webp';
import '../assets/css/feature-slider.css';
import FeatureImageSlider, {
  Feature,
} from '../../../common/components/Slider/components/FeatureImageSlider';

export default function FeatureSlider(): React.JSX.Element {
  const featurePanels: Feature[] = [
    {
      src: fishPanel,
      title: 'Book Fishing Online',
      description: 'No added booking fees',
    },
    {
      src: giveawayPanel,
      title: 'Live Weekly Giveaways',
      description: 'Over 52 chances to win per year',
    },
    {
      src: insurancePanel,
      title: 'Comprehensive Angling Cover',
      description: 'Protect yourself and your equipment',
    },
    {
      src: perksPanel,
      title: 'Exclusive Perks and Offers',
      description: 'An ever-growing list of member benefits ',
    },
    {
      src: cashbackPanel,
      title: 'Cashback on Fishing & Shop',
      description: 'Earn credit back to spend on more fishing!',
    },
    {
      src: facebookPanel,
      title: 'Private Community',
      description: 'Join a group of likeminded anglers',
    },
    {
      src: trustpilotPanel,
      title: '5* Customer Service',
      description: 'Providing you with incredible support',
    },
  ];
  return (
    <FeatureImageSlider
      className='feature-slider -mb-1 px-6'
      features={featurePanels}
    />
  );
}
