import { SVGProps } from 'react';
const SvgGiftDark = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={21}
    height={19}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M19.95 5.287h-.111c.079-.346.116-.7.111-1.055a3.939 3.939 0 0 0-1.131-3.083A3.902 3.902 0 0 0 15.75.012c-2.272 0-4.168 2.396-5.25 4.144C9.417 2.408 7.522.012 5.25.012A3.887 3.887 0 0 0 2.181 1.15 3.924 3.924 0 0 0 1.05 4.232c-.005.355.032.709.111 1.055H1.05c-.278 0-.546.11-.742.309A1.057 1.057 0 0 0 0 6.34v3.165c0 .28.11.548.308.746.196.198.464.309.742.309h8.4V7.396h2.1v3.165h8.4c.279 0 .546-.111.742-.309.197-.198.308-.466.308-.746V6.341c0-.28-.11-.548-.308-.745a1.048 1.048 0 0 0-.742-.31Zm-14.116 0c-2.684 0-2.684-.422-2.684-1.055 0-1.892 1.2-2.11 2.1-2.11 1.169 0 2.54 1.67 3.473 3.165h-2.89Zm9.332 0h-2.89c.934-1.495 2.31-3.165 3.474-3.165.9 0 2.1.218 2.1 2.11 0 .633 0 1.055-2.684 1.055ZM11.55 12.67h7.35v5.274A1.047 1.047 0 0 1 17.85 19H3.15c-.278 0-.546-.111-.742-.309a1.057 1.057 0 0 1-.308-.746v-5.274h7.35v4.22h2.1v-4.22Z'
      fill='#292929'
    />
  </svg>
);
export default SvgGiftDark;
