import { SVGProps } from 'react';
const SvgRod = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M5.854 13.376c.06-.111-.084-.222-.173-.133l-.004.003-5.194 5.195A1.727 1.727 0 0 0 .5 20.854c.766.71 1.797.614 2.412.015.315-.291-1.138 1.138 3.58-3.579a.129.129 0 0 0-.004-.186 3.09 3.09 0 0 1-.633-3.728ZM8.581 15.133a.344.344 0 1 0 0-.687.344.344 0 0 0 0 .687Z'
      fill='#fff'
    />
    <path
      d='M7.55 14.774a1.024 1.024 0 0 1 1.03-1.015c.737 0 1.221.746.96 1.406a.129.129 0 0 0 .027.138l.835.85c.057.058.154.05.198-.019 1.013-1.557-.083-3.706-2.02-3.706a2.402 2.402 0 0 0-2.403 2.404c0 1.941 2.167 3.04 3.724 2.008a.128.128 0 0 0 .02-.197l-.844-.858a.129.129 0 0 0-.138-.03 1.032 1.032 0 0 1-1.388-.981ZM11.6 16.478l-.442.441-.373-.38a.13.13 0 0 0-.192.009 2.42 2.42 0 0 1-.285.287.13.13 0 0 0-.01.19l.374.38-.435.436a.353.353 0 0 0-.018.488.343.343 0 0 0 .493.008l1.374-1.374a.344.344 0 0 0-.486-.485Z'
      fill='#fff'
    />
    <path
      d='M21.286 17.252V1.39c.32-.854-.568-1.68-1.4-1.291-.241.11.531-.621-11.514 11.424a.13.13 0 0 0 .098.22 3.09 3.09 0 0 1 1.888.56c.05.035.12.028.164-.015l2.523-2.523v.26a2.404 2.404 0 0 1-2.404 2.403.042.042 0 0 0-.028.074c.175.153.33.323.466.509.029.04.078.061.126.052a3.095 3.095 0 0 0 2.526-3.038v-.687h.687a3.09 3.09 0 0 0 3.09-3.09V5.56h.687c.97 0 1.837-.45 2.404-1.151v12.842c-.913.324-.914 1.618 0 1.942v1.59a.525.525 0 0 1-.466.527.516.516 0 0 1-.564-.513v-.171c.303 0 .46-.37.242-.587l-.343-.343a.344.344 0 0 0-.586.243v.833c0 .654.512 1.207 1.165 1.226.68.02 1.239-.526 1.239-1.2v-1.605c.913-.324.913-1.618 0-1.942ZM16.822 6.248a2.406 2.406 0 0 1-2.404 2.403h-.26l2.664-2.663v.26Zm1.373-1.374h-.26L20.6 2.211v.26a2.406 2.406 0 0 1-2.404 2.403Zm2.747 13.692a.344.344 0 1 1 0-.687.344.344 0 0 1 0 .687Z'
      fill='#fff'
    />
  </svg>
);
export default SvgRod;
