import React from 'react';
import { SessionNotesList } from '../features/SessionNotes/Index';
import DesktopLayout from '../common/components/DesktopLayout';

export default function SessionNotesResults(): React.JSX.Element {
  return (
    <DesktopLayout>
      <SessionNotesList />
    </DesktopLayout>
  );
}
