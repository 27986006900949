import React, { useEffect, useState } from 'react';

import AirPressure from '../assets/images/c.svg';
import Wind from '../assets/images/h.svg';
import Temperature from '../assets/images/j.svg';
import SunsetIcon from '../assets/images/o.svg';

import { Divider } from '../../../common/components';

import dayjs from 'dayjs';

import axios from 'axios';

interface WeatherDetails {
  temperature: number;
  pressure: number;
  wind: string;
  sunsetTime: string;
  description: string;
}

const getDirection = (degree: number) => {
  const directions = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW', 'N'];
  const index = Math.round(degree / 45) % directions.length;
  return directions[index];
};

const WeatherSection = ({
  lat,
  lng,
}: {
  lat?: number;
  lng?: number;
}): React.JSX.Element => {
  const [, setCoordinates] = useState({ lat: 0, long: 0 });
  const [weatherDetails, setWeatherDetails] = useState<WeatherDetails | null>(
    null
  );

  const fetchWeatherData = async (lat?: number, lon?: number) => {
    const endpoint = 'https://api.openweathermap.org/data/2.5/weather';

    if (lat && lon) {
      const params = {
        appid: process.env.REACT_APP_OPEN_WEATHER_API_KEY,
        lat,
        lon,
      };

      const forecastResponse = await axios.get(endpoint, { params });

      const pressure = forecastResponse?.data?.main?.pressure;
      const windDirection = getDirection(forecastResponse?.data?.wind?.deg);
      const windSpeed = forecastResponse?.data?.wind?.speed;
      const sunsetTime = dayjs
        .unix(forecastResponse?.data?.sys?.sunset)
        .format('HH:mm');
      const temperature = +(
        forecastResponse?.data?.main?.temp - 273.15
      ).toFixed(0);

      setWeatherDetails({
        pressure,
        wind: `${windDirection} ${windSpeed}km/h`,
        sunsetTime,
        temperature,
        description: forecastResponse?.data?.weather[0]?.description,
      });
    }
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      function (position) {
        setCoordinates({
          lat: position.coords.latitude,
          long: position.coords.longitude,
        });
        fetchWeatherData(position.coords.latitude, position.coords.longitude);
      },
      (error) => {
        if (error.code === 1) {
          fetchWeatherData(lat, lng);
        }
      }
    );
  }, [lat, lng]);

  return (
    <div className='home-page-section-container relative'>
      <div className='flex flex-col items-center justify-center text-center'>
        <div className='text-base font-semibold'>
          Live InSession™ Weather Report
        </div>
        <div>
          <span className='mr-2 text-base font-semibold'>Current: </span>
          <span className='text-base'>{weatherDetails?.description}</span>
        </div>
      </div>
      <div className='mt-4 flex max-[500px]:flex-col max-[500px]:gap-4'>
        <div className='flex flex-1'>
          <div className='flex flex-1 flex-col items-center  text-center'>
            <div className='min-h-[27px]'>
              <img src={Temperature} alt='Temperature' />
            </div>
            <div className='text-base font-semibold'>
              {weatherDetails?.temperature}
            </div>
            <div className='text-xs'>Temperature</div>
          </div>

          <Divider />

          <div className='flex flex-1 flex-col items-center  text-center'>
            <div className='min-h-[27px]'>
              <img src={Wind} alt='wind' />{' '}
            </div>
            <div className='text-base font-semibold'>
              {weatherDetails?.wind}
            </div>
            <div className='text-xs'>Wind Direction</div>
          </div>
        </div>
        <div className='max-[500px]:hidden'>
          <div className='divider min-[500px]:h-full' />
        </div>
        <div className='flex flex-1'>
          <div className='flex flex-1 flex-col items-center  text-center'>
            <div className='min-h-[27px]'>
              {' '}
              <img src={AirPressure} alt='Air Pressure' />{' '}
            </div>
            <div className='text-base font-semibold'>
              {weatherDetails?.pressure}
            </div>
            <div className='text-xs'>Air Pressure</div>
          </div>
          <Divider />
          <div className='flex flex-1 flex-col items-center  text-center'>
            <div className='min-h-[27px]'>
              {' '}
              <img src={SunsetIcon} alt='sunset' />{' '}
            </div>
            <div className='text-base font-semibold'>
              {weatherDetails?.sunsetTime}
            </div>
            <div className='text-xs'>Sunset Time</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeatherSection;
