import { SVGProps } from 'react';
const SvgShieldDark = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={21}
    height={25}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M20.974 6.423c-.03-.657-.03-1.283-.03-1.91a.88.88 0 0 0-.895-.895c-3.73 0-6.565-1.074-8.922-3.372a.92.92 0 0 0-1.253 0C7.517 2.544 4.682 3.618.952 3.618a.88.88 0 0 0-.895.895c0 .627 0 1.253-.03 1.91-.12 6.266-.298 14.859 10.175 18.47l.299.06.298-.06c10.443-3.611 10.294-12.175 10.175-18.47Zm-11.19 8.683a.963.963 0 0 1-.626.238h-.03a.84.84 0 0 1-.627-.298l-2.775-3.073 1.343-1.194 2.178 2.417L14.081 8.6l1.223 1.313-5.52 5.192Z'
      fill='#292929'
    />
  </svg>
);
export default SvgShieldDark;
