import React, { useState, useEffect } from 'react';

import '../features/home/assets/css/home-page.css';
import { QuickLink } from '../features/fisheryQuickLinks';

import {
  AddCatchReportsAndSessionNotes,
  SessionOptions,
  BackgroundImage,
  FoodSection,
  SubHeader,
  PurchaseSection,
  SideBar,
  BetaModal,
} from '../features/home';

import { ActivityFeed } from '../features/home/components/ActivityFeed';

import { DesktopLayout } from '../common/components';
import { WeatherSection } from '../features/weather';
import { Reports } from '../features/Reports';

import {
  useGetCurrentFisheryId,
  useGetCurrentSessionQuery,
} from '../features/users/usersSlice';
import { useFisheryDetailsQuery } from '../store/apiSlices/fisheryDetailsSlice';

import Loader from '../common/components/Loader';

export default function Home(): React.JSX.Element {
  const [reportDrawerConfig, setReportDrawerConfig] = useState({
    isOpen: false,
    isCatchReport: false,
  });

  const [quickLinksOpen, setQuickLinksOpen] = useState<boolean>(false);

  const [feedbackDialog, setFeedbackDialog] = useState<boolean>(false);
  const { data: fisheryId } = useGetCurrentFisheryId();

  const { data: fisheryDetails } = useFisheryDetailsQuery(fisheryId, {
    skip: !fisheryId,
  });
  const { data: userSessionDetails, isLoading: isSessionLoading } =
    useGetCurrentSessionQuery();

  useEffect(() => {
    const showFeedbackDialog = localStorage.getItem('showFeedbackDialog');

    if (!showFeedbackDialog) {
      setFeedbackDialog(true);
      localStorage.setItem('showFeedbackDialog', 'No');
    }
  }, []);

  if (isSessionLoading) {
    return <Loader />;
  }

  return (
    <DesktopLayout>
      <div className='p-4 pb-36 md:p-0'>
        <BackgroundImage slide={fisheryDetails?.images?.primary || ''} />
        <BetaModal open={feedbackDialog} setOpen={setFeedbackDialog} />
        <SideBar
          quickLinksOpen={quickLinksOpen}
          setQuickLinksOpen={setQuickLinksOpen}
          setReportDrawerConfigs={setReportDrawerConfig}
        />
        <SubHeader
          fisheryName={userSessionDetails?.fishery_name}
          sessionEndsAt={userSessionDetails?.end_ts || ''}
        />
        <Reports
          isOpen={reportDrawerConfig.isOpen}
          isCatchReport={reportDrawerConfig?.isCatchReport}
          setIsOpen={setReportDrawerConfig}
          fisheryDetails={fisheryDetails}
          sessionId={userSessionDetails?.id as string}
        />
        {quickLinksOpen ? (
          <QuickLink fisheryDetails={fisheryDetails} />
        ) : (
          <>
            <SessionOptions
              setQuickLinksOpen={setQuickLinksOpen}
              fisheryPhone={fisheryDetails?.contact?.primary_contact || ''}
            />
            <AddCatchReportsAndSessionNotes
              setReportDrawerConfigs={setReportDrawerConfig}
            />
            <FoodSection />
            <WeatherSection
              lat={fisheryDetails?.address.location.lat}
              lng={fisheryDetails?.address.location.lng}
            />
            <PurchaseSection />
            <ActivityFeed />
          </>
        )}
      </div>
    </DesktopLayout>
  );
}
