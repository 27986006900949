import { useCountdown } from '../../../utils/countdown';

import dayjs from 'dayjs';

const SessionTimeRemaining = ({
  sessionEndDate,
}: {
  sessionEndDate: string;
}) => {
  const remainingTimeInSeconds = dayjs(sessionEndDate).diff(dayjs(), 'seconds');
  const [days, hours, minutes] = useCountdown(remainingTimeInSeconds, 60000);
  const convertedHours = days * 24 + hours;

  return (
    <>
      {convertedHours} hrs {minutes} mins
    </>
  );
};

export default SessionTimeRemaining;
