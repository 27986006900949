import { SVGProps } from 'react';
const SvgChat = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g clipPath='url(#chat_svg__a)'>
      <path
        d='M12 .6C5.383.6 0 5.445 0 11.4c0 2.082.658 4.098 1.907 5.842-.236 2.614-.87 4.556-1.79 5.475a.4.4 0 0 0 .339.679c.162-.023 3.925-.564 6.647-2.136A13.02 13.02 0 0 0 12 22.2c6.617 0 12-4.845 12-10.8C24 5.445 18.617.6 12 .6ZM6.4 13c-.882 0-1.6-.718-1.6-1.6 0-.882.718-1.6 1.6-1.6.882 0 1.6.718 1.6 1.6 0 .882-.718 1.6-1.6 1.6Zm5.6 0c-.882 0-1.6-.718-1.6-1.6 0-.882.718-1.6 1.6-1.6.882 0 1.6.718 1.6 1.6 0 .882-.718 1.6-1.6 1.6Zm5.6 0c-.882 0-1.6-.718-1.6-1.6 0-.882.718-1.6 1.6-1.6.882 0 1.6.718 1.6 1.6 0 .882-.718 1.6-1.6 1.6Z'
        fill='#fff'
      />
    </g>
    <defs>
      <clipPath id='chat_svg__a'>
        <path fill='#fff' d='M0 0h24v24H0z' />
      </clipPath>
    </defs>
  </svg>
);
export default SvgChat;
