import { proApiSlice } from './proApiSlice';

export interface SessionNotesRequest {
  session_id: string;
  title: string;
  detail: string;
  swim: string;
  image_upload: string;
  note_date: string;
}

export interface SessionNotes {
  id: string;
  title: string;
  session_id: string;
  swim: string;
  note_date: string;
  user_id: string;
  detail: string;
  image_upload: string;
  uploaded_at: string;
  note_id: string;
  lake: string;
}

export const extendedApiSlice = proApiSlice
  .enhanceEndpoints({ addTagTypes: ['SessionNotes'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      createSessionNote: builder.mutation<void, SessionNotesRequest>({
        query: (request) => ({
          url: 'session_notes/create',
          method: 'POST',
          data: request,
          useDefaultErrorHandler: false,
        }),
        transformErrorResponse: (
          response: { status: string | number },
          meta,
          arg
        ) => response,
      }),
      sessionNotes: builder.query<SessionNotes[], string>({
        query: (session_id) => ({
          url: 'session_notes/all/notes',
          method: 'GET',
          params: { session_id },
          useDefaultErrorHandler: true,
        }),
        providesTags: ['SessionNotes'],
        transformResponse: (response: {
          status: string;
          result: SessionNotes[];
        }) => response?.result ?? response,
      }),
      updateSessionNote: builder.mutation<void, SessionNotes>({
        query: (request) => ({
          url: 'session_notes/update',
          method: 'PUT',
          data: request,
          useDefaultErrorHandler: false,
        }),
        invalidatesTags: ['SessionNotes'],
        transformErrorResponse: (
          response: { status: string | number },
          meta,
          arg
        ) => response,
      }),
    }),
  });

export const {
  useCreateSessionNoteMutation,
  useSessionNotesQuery,
  useUpdateSessionNoteMutation,
} = extendedApiSlice;
