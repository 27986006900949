import { useEffect, useRef } from 'react';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import fishPlus from '../../../assets/icons/fishPlus.svg';
import fireworksAnimation from '../assets/media/animations/animation_llyxj60q.json';
import { SuccessAnimationProps } from './SuccessAnimation';

function SuccessAnimationFireworksFish({
  active = true,
}: SuccessAnimationProps) {
  const fireworksRef = useRef<LottieRefCurrentProps>(null);
  const fireworksDelay = 300;

  useEffect(() => {
    if (!active) {
      return;
    }
    async function playAnimationWithDelay() {
      // Wait up to 1s for animation to load in
      let count = 0;
      while (count < 100 && !fireworksRef?.current?.animationLoaded) {
        await new Promise((resolve) => setTimeout(resolve, 10));
        count += 1;
      }
      if (count === 100) {
        // Animations not loaded in time
        return;
      }
      setTimeout(() => fireworksRef?.current?.play(), fireworksDelay);
    }

    void playAnimationWithDelay();
  }, [active, fireworksDelay]);

  return (
    <div className='relative -mt-16 mb-9 h-[14rem]'>
      <Lottie
        lottieRef={fireworksRef}
        animationData={fireworksAnimation}
        className='absolute'
        autoplay={false}
        loop={true}
      />
      <div className='absolute bottom-0 flex w-full justify-center'>
        <img src={fishPlus} alt='swimbooker+ Logo' className='w-20' />
      </div>
    </div>
  );
}

export default SuccessAnimationFireworksFish;
